import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

const DeleteCardDialog = ({ open, card, onClose, onDelete }) => {
  const [cardId, setCardId] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (card) {
      setCardId(card.id);
    }
  }, [card]);

  const handleDelete = async () => {
    setIsRunning(true);
    await onDelete(cardId);
    setIsRunning(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Delete Card</DialogTitle>
      <DialogContent>
        <Typography variant="body1">Do you want to delete the card?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isRunning}>
          Cancel
        </Button>
        <Button onClick={handleDelete} disabled={isRunning} color="error">
          Delete
          {isRunning && (
            <CircularProgress
              size={20}
              sx={{
                ml: 1,
              }}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCardDialog;
