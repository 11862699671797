import React, { useEffect, useState } from "react";
import { Card, CardHeader, Typography, Box, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AutomationEditCardContent from "../automation/automation-edit-card-content";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useHistory } from "react-router-dom";

const AutomationChatCard = ({ payload, onSave, onDelete, onChangeSummary }) => {
  const theme = useTheme();
  const history = useHistory();
  const [status, setStatus] = useState("");
  const [automationType, setAutomationType] = useState("");

  const getInitialStatus = () => {
    setAutomationType(payload.data.automationType);
    if (payload.data.autoRun) {
      return "temp";
    } else if (payload.data.automationType === "RECURRING") {
      return "recurring";
    } else if (payload.data.automationType === "TASK_FLOW") {
      return "taskflow";
    } else {
      return "na";
    }
  };

  const getStatusColor = () => {
    switch (status) {
      case "taskflow":
        return theme.palette.background.l3;
      case "recurring":
        return theme.palette.background.l2;
      case "temp":
        return theme.palette.background.l6;
      case "na":
        return theme.palette.background.l4;
      default:
        return theme.palette.background.l0;
    }
  };

  const getStatusText = () => {
    switch (status) {
      case "taskflow":
        return "TaskFlow Automation";
      case "recurring":
        return "Recurring Automation";
      case "temp":
        return "Immediate Command";
      case "na":
        return "Unavailable";
      default:
        return "";
    }
  };

  useEffect(() => {
    setStatus(getInitialStatus());
  }, [payload]);

  const handleSave = (aid) => {
    onSave(payload.data.sessionId, aid, automationType);
  };

  const handleDelete = (aid) => {
    onDelete(aid);
  };

  const getParams = () => {
    const p = {
      automationId: payload.data.automationId,
      draft: payload.content,
      autoRun: payload.data.autoRun === "true",
    };
    return p;
  };

  const handleOpenAutomation = () => {
    if (payload.data.automationId) {
      history.push(`/automation/overview?id=${payload.data.automationId}`);
    }
  };

  const TitleComponent = () => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography variant="h6">{getStatusText()}</Typography>
      {payload.data.automationId && (
        <IconButton onClick={handleOpenAutomation} size="small" sx={{ ml: 1 }}>
          <OpenInNewIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );

  return (
    <Card
      elevation={0}
      sx={{
        maxWidth: "100%",
        backgroundColor: "background.card",
        fontFamily: "Roboto, sans-serif",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "8px",
      }}
    >
      <CardHeader
        title={<TitleComponent />}
        sx={{
          backgroundColor: getStatusColor(),
          color: theme.palette.text.black,
        }}
      />
      {status && status !== "na" && (
        <AutomationEditCardContent
          params={getParams()}
          editable={status === "taskflow" || status === "recurring"}
          autoRun={true}
          onChangeSummary={onChangeSummary}
          onSave={handleSave}
          onDelete={handleDelete}
        />
      )}
    </Card>
  );
};

export default AutomationChatCard;
