import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import BoolInput from "../../input/bool-input";

const Toggle1Widget = ({ widget, onValueUpdate }) => {
  const [v0, setV0] = useState(null);

  useEffect(() => {
    setV0(widget);
    return () => {};
  }, [widget]);

  const handleValueUpdate = (data) => {
    const updatedWidget = { ...v0, value: data.toString() };
    onValueUpdate(updatedWidget);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      {v0 && <BoolInput valueSpec={v0} onChange={handleValueUpdate} />}
    </Box>
  );
};

export default Toggle1Widget;
