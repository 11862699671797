import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Box from "@mui/material/Box";
import {
  MobileDateTimePicker,
  StaticDateTimePicker,
} from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Typography from "@mui/material/Typography";

dayjs.extend(utc);
dayjs.extend(timezone);

const TimestampInput = ({ valueSpec, onChange, embedded = false }) => {
  const [value, setValue] = useState(null);
  const isReadOnly = valueSpec.readOnly;

  useEffect(() => {
    try {
      const timestamp = parseInt(valueSpec.value);
      if (isNaN(timestamp)) {
        setValue(null);
        return;
      }

      const date = dayjs.tz(timestamp, dayjs.tz.guess());
      if (!date.isValid()) {
        setValue(null);
        return;
      }

      setValue(date);
    } catch (error) {
      console.error("Error parsing timestamp:", error);
      setValue(null);
    }
  }, [valueSpec]);

  const handleTimestampChange = (newValue) => {
    if (isReadOnly) return;
    setValue(newValue);
    onChange(newValue.valueOf().toString());
  };

  const handleValueInstantChange = (newValue) => {
    if (isReadOnly) return;
    if (embedded) {
      return handleTimestampChange(newValue);
    }
  };

  const readOnlyStyles = {
    "& .MuiInputBase-root": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      cursor: "default",
    },
    "& .MuiInputBase-input": {
      color: "rgba(0, 0, 0, 0.38)",
      cursor: "default",
      WebkitTextFillColor: "rgba(0, 0, 0, 0.38)",
      "&::selection": {
        backgroundColor: "transparent",
      },
    },
    "& .MuiIconButton-root": {
      visibility: "hidden",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.15)",
    },
  };

  const commonProps = {
    value: value,
    onChange: handleValueInstantChange,
    readOnly: isReadOnly,
    slotProps: {
      textField: {
        placeholder: "YYYY/MM/DD HH:mm",
        size: "small",
        fullWidth: true,
      },
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        alignItems: "stretch",
        justifyContent: "center",
      }}
    >
      {valueSpec.name && (
        <Typography
          variant="h6"
          sx={{
            color: isReadOnly ? "rgba(0, 0, 0, 0.38)" : "inherit",
          }}
        >
          {valueSpec.name}
        </Typography>
      )}
      <Box
        sx={{
          flex: "1 1 auto",
          display: "flex",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {embedded ? (
            <StaticDateTimePicker
              {...commonProps}
              displayStaticWrapperAs="desktop"
              slots={{
                actionBar: null,
              }}
              slotProps={{
                ...commonProps.slotProps,
                toolbar: {
                  hidden: false,
                },
                textField: {
                  size: "small",
                  fullWidth: true,
                  sx: {
                    "& .MuiInputBase-root": {
                      height: "32px",
                      ...(isReadOnly && readOnlyStyles["& .MuiInputBase-root"]),
                    },
                    "& .MuiInputBase-input": {
                      padding: "0 8px",
                      ...(isReadOnly &&
                        readOnlyStyles["& .MuiInputBase-input"]),
                    },
                    "& .MuiInputBase-input::placeholder": {
                      opacity: 0.7,
                      color: "text.secondary",
                    },
                    ...(isReadOnly && readOnlyStyles),
                  },
                },
                // Disable calendar and clock when readonly
                calendar: {
                  disabled: isReadOnly,
                },
                clock: {
                  disabled: isReadOnly,
                },
              }}
              sx={{
                width: "100%",
                opacity: isReadOnly ? 0.7 : 1,
                pointerEvents: isReadOnly ? "none" : "auto",
                "& .MuiPickersLayout-root": {
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                },
                "& .MuiPickersLayout-contentWrapper": {
                  margin: 0,
                },
                "& .MuiDialogActions-root": {
                  display: "none",
                },
                "& .MuiDateCalendar-root, & .MuiClock-root": {
                  margin: "0 auto",
                },
                "& .MuiTabs-root": {
                  minHeight: "40px",
                },
                "& .MuiPickersLayout-actionBar": {
                  display: "none",
                },
              }}
            />
          ) : (
            <MobileDateTimePicker
              {...commonProps}
              onAccept={handleTimestampChange}
              disabled={isReadOnly}
              slotProps={{
                ...commonProps.slotProps,
                textField: {
                  size: "small",
                  fullWidth: true,
                  sx: {
                    flex: 1,
                    "& .MuiInputBase-root": {
                      height: "100%",
                      minHeight: "26px",
                      ...(isReadOnly && readOnlyStyles["& .MuiInputBase-root"]),
                    },
                    "& .MuiInputBase-input": {
                      height: "100%",
                      padding: "0 8px",
                      display: "flex",
                      alignItems: "center",
                      ...(isReadOnly &&
                        readOnlyStyles["& .MuiInputBase-input"]),
                    },
                    "& .MuiInputBase-input::placeholder": {
                      opacity: 0.7,
                      color: "text.secondary",
                    },
                    ...(isReadOnly && readOnlyStyles),
                  },
                },
                dialog: {
                  sx: {
                    "& .MuiDialogActions-root": {
                      display: isReadOnly ? "none" : "flex",
                    },
                  },
                },
              }}
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                "& .MuiTextField-root": {
                  flex: 1,
                  display: "flex",
                },
                "& .MuiPickersLayout-root": {
                  minHeight: "auto",
                },
              }}
            />
          )}
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default TimestampInput;
