import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { MobileTimePicker, StaticTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Typography from "@mui/material/Typography";

const TimeInput = ({ valueSpec, onChange, embedded = false }) => {
  const [value, setValue] = useState(null);
  const isReadOnly = valueSpec.readOnly;

  useEffect(() => {
    const hasSeconds = valueSpec.value?.split(":").length === 3;
    const format = hasSeconds ? "HH:mm:ss" : "HH:mm";
    const parsedValue = dayjs(valueSpec.value, format);
    setValue(parsedValue.isValid() ? parsedValue : null);
  }, [valueSpec]);

  const handleValueInstantChange = (data) => {
    if (isReadOnly) return;
    if (embedded) {
      return handleValueChange(data);
    }
  };

  const handleValueChange = (data) => {
    if (isReadOnly) return;
    const h = data?.$H ?? 0;
    const m = data?.$m ?? 0;
    const s = data?.$s ?? 0;
    const time = `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${
      s < 10 ? "0" + s : s
    }`;
    setValue(data);
    onChange(time);
  };

  // Common props for both picker variants
  const commonProps = {
    value: value,
    onChange: handleValueInstantChange,
    readOnly: isReadOnly,
  };

  const readOnlyStyles = {
    "& .MuiInputBase-root": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      cursor: "default",
    },
    "& .MuiInputBase-input": {
      color: "rgba(0, 0, 0, 0.38)",
      cursor: "default",
      WebkitTextFillColor: "rgba(0, 0, 0, 0.38)",
      "&::selection": {
        backgroundColor: "transparent",
      },
    },
    "& .MuiIconButton-root": {
      visibility: "hidden",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.15)",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        alignItems: "stretch",
        justifyContent: "center",
      }}
    >
      {valueSpec.name && (
        <Typography
          variant="h6"
          noWrap
          sx={{
            flexShrink: 0,
            mb: 1,
            color: isReadOnly ? "rgba(0, 0, 0, 0.38)" : "inherit",
          }}
        >
          {valueSpec.name}
        </Typography>
      )}
      <Box
        sx={{
          flex: "1 1 auto",
          display: "flex",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {embedded ? (
            <StaticTimePicker
              {...commonProps}
              displayStaticWrapperAs="desktop"
              slots={{
                digitallClock: true,
                actionBar: null,
              }}
              slotProps={{
                toolbar: {
                  hidden: false,
                  toolbarFormat: "HH:mm:ss",
                },
                textField: {
                  size: "small",
                  fullWidth: true,
                  sx: {
                    "& .MuiInputBase-root": {
                      height: "32px",
                      ...(isReadOnly && readOnlyStyles["& .MuiInputBase-root"]),
                    },
                    "& .MuiInputBase-input": {
                      padding: "0 8px",
                      ...(isReadOnly &&
                        readOnlyStyles["& .MuiInputBase-input"]),
                    },
                    ...(isReadOnly && readOnlyStyles),
                  },
                },
                // Disable clock pointers when readonly
                clock: {
                  disabled: isReadOnly,
                },
              }}
              sx={{
                width: "100%",
                opacity: isReadOnly ? 0.7 : 1,
                pointerEvents: isReadOnly ? "none" : "auto",
                "& .MuiPickersLayout-root": {
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                },
                "& .MuiPickersLayout-contentWrapper": {
                  margin: 0,
                },
                "& .MuiDialogActions-root": {
                  display: "none",
                },
                "& .MuiClock-root": {
                  margin: "0 auto",
                },
              }}
            />
          ) : (
            <MobileTimePicker
              {...commonProps}
              onAccept={handleValueChange}
              disabled={isReadOnly}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  sx: {
                    flex: 1,
                    "& .MuiInputBase-root": {
                      height: "100%",
                      minHeight: "26px",
                      ...(isReadOnly && readOnlyStyles["& .MuiInputBase-root"]),
                    },
                    "& .MuiInputBase-input": {
                      height: "100%",
                      padding: "0 8px",
                      display: "flex",
                      alignItems: "center",
                      ...(isReadOnly &&
                        readOnlyStyles["& .MuiInputBase-input"]),
                    },
                    ...(isReadOnly && readOnlyStyles),
                  },
                },
                dialog: {
                  sx: {
                    "& .MuiDialogActions-root": {
                      display: isReadOnly ? "none" : "flex",
                    },
                  },
                },
              }}
            />
          )}
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default TimeInput;
