import { React, useState } from "react";
import { useHistory } from "react-router-dom";
import IntegrationListView from "../integration/integration-list-view";
import HistoryIcon from "@mui/icons-material/History";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import BarChartIcon from "@mui/icons-material/BarChart";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ExtensionIcon from "@mui/icons-material/Extension";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import { Moon, Sun } from "lucide-react";
import { useCustomTheme } from "../theme";
import { LightMode, DarkMode } from "@mui/icons-material";

import {
  Box,
  Card,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
// import { useTheme } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import { platformState } from "../global-state";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";

const Menu = ({ showFilter, setShowFilter }) => {
  const history = useHistory();
  const [platform, setPlatform] = useRecoilState(platformState);

  const { mode, toggleTheme } = useCustomTheme();

  return (
    <Box
      sx={{
        padding: 2,
        height: "auto",
        maxWidth: "100%",
        boxSizing: "border-box",
      }}
    >
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {/* Scope Section */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              p: 2,
              fontFamily: "Roboto,sans-serif",
              borderRadius: "12px",
              border: "1px solid #EAEEF0",
              boxShadow: "none",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={() => {
              history.push("/space");
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <MapsHomeWorkIcon sx={{ mr: 1 }} />
                <Typography>Space</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>&gt;</Typography>
              </Box>
            </Box>
          </Card>
        </Grid>

        {/*integration*/}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              p: 2,
              fontFamily: "Roboto,sans-serif",
              borderRadius: "12px",
              border: "1px solid #EAEEF0",
              boxShadow: "none",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={() => {
              history.push("/integerations");
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ExtensionIcon sx={{ mr: 1 }} />
                <Typography>Integrations </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>&gt;</Typography>
              </Box>
            </Box>
          </Card>
        </Grid>

        {/* Phone Section */}
        {platform.source === "app" && (
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 2,
                fontFamily: "Roboto,sans-serif",
                borderRadius: "12px",
                border: "1px solid #EAEEF0",
                boxShadow: "none",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={() => {
                history.push("/phone/setting");
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <PhonelinkSetupIcon sx={{ mr: 1 }} />
                  <Typography>Phone Setting</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: "bold" }}>&gt;</Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        )}

        {/* Hub Section */}
        {platform.source === "app" && (
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 2,
                fontFamily: "Roboto,sans-serif",
                borderRadius: "12px",
                border: "1px solid #EAEEF0",
                boxShadow: "none",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={() => {
                history.push("/hub/setting");
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <DeviceHubIcon sx={{ mr: 1 }} />
                  <Typography>Hub Setting</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: "bold" }}>&gt;</Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        )}

        {/* History Section */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              p: 2,
              fontFamily: "Roboto,sans-serif",
              borderRadius: "12px",
              border: "1px solid #EAEEF0",
              boxShadow: "none",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={() => {
              history.push("/automation/history");
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <HistoryIcon sx={{ mr: 1 }} />
                <Typography>History</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>&gt;</Typography>
              </Box>
            </Box>
          </Card>
        </Grid>

        {/* Schedule Section */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              p: 2,
              fontFamily: "Roboto,sans-serif",
              borderRadius: "12px",
              border: "1px solid #EAEEF0",
              boxShadow: "none",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={() => {
              history.push("/schedules");
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ScheduleIcon sx={{ mr: 1 }} />
                <Typography>Schedule</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>&gt;</Typography>
              </Box>
            </Box>
          </Card>
        </Grid>

        {/* Device Section */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              p: 2,
              fontFamily: "Roboto,sans-serif",
              borderRadius: "12px",
              border: "1px solid #EAEEF0",
              boxShadow: "none",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={() => {
              history.push("/integration?id=basic.device");
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LightbulbOutlinedIcon sx={{ mr: 1 }} />
                <Typography>Device</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>&gt;</Typography>
              </Box>
            </Box>
          </Card>
        </Grid>

        {/* Automation Statistics Section */}
        {process.env.NODE_ENV !== "production" && (
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 2,
                fontFamily: "Roboto,sans-serif",
                borderRadius: "12px",
                border: "1px solid #EAEEF0",
                boxShadow: "none",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={() => {
                history.push("/automation/statistics");
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <BarChartIcon sx={{ mr: 1 }} />
                  <Typography>Automation Statistics </Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: "bold" }}>&gt;</Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              p: 2,
              fontFamily: "Roboto,sans-serif",
              borderRadius: "12px",
              border: "1px solid #EAEEF0",
              boxShadow: "none",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {mode === "dark" ? <Moon /> : <Sun />}
                  <Box sx={{ ml: 1 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "text.primary" }}
                    >
                      {mode === "dark" ? "Dark Theme" : "Light Theme"}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <FormControl size="small" sx={{ minWidth: 120 }}>
                <Select
                  value={mode}
                  onChange={(e) => {
                    toggleTheme();
                  }}
                  displayEmpty
                  variant="outlined"
                  sx={{
                    height: 40,
                    "& .MuiSelect-select": {
                      py: 1,
                      px: 2,
                    },
                  }}
                >
                  <MenuItem value="light">
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Typography>Light</Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem value="dark">
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Typography>Dark</Typography>
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Menu;
