import { Box, Slider, Portal } from "@mui/material";
import React, { useEffect, useState } from "react";

const Brightness1Widget = ({
  widget,
  onValueUpdate,
  drag = false,
  edit = false,
}) => {
  const [v0, setV0] = useState({});
  const [brightness, setBrightness] = useState(0);
  const [w1, setW1] = useState({});
  const [minMax, setMinMax] = useState({ min: 0, max: 100 });
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const sliderRef = React.useRef(null);

  useEffect(() => {
    setV0(widget);
    if (widget?.items?.$w1) {
      setMinMax({
        min: widget.items.$w1.min ?? 0,
        max: widget.items.$w1.max ?? 100,
      });
      setBrightness(Number(widget.items.$w1.value));
    }
    return () => {};
  }, [widget]);

  useEffect(() => {
    if (isDragging && sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const left = rect.left + (brightness / 100) * rect.width;
      setPosition({
        top: rect.top - 40,
        left: left,
      });
    }
  }, [brightness, isDragging]);

  const handleValueUpdate = (data) => {
    const updatedWidget = {
      ...v0.items.$w1,
      value: data,
    };
    onValueUpdate(updatedWidget);
  };

  const handleBrightnessChange = (event, newValue) => {
    if (drag || edit) return;
    event.stopPropagation();
    const adjustedValue = Math.min(Math.max(newValue, minMax.min), minMax.max);

    setBrightness(adjustedValue);
    handleValueUpdate(adjustedValue);
  };

  const handleMouseEvents = (event) => {
    if (drag || edit) return;
    event.stopPropagation();
  };

  const handleDragStart = (event) => {
    if (drag || edit) return;
    setIsDragging(true);
    handleMouseEvents(event);
  };

  const handleDragEnd = (event) => {
    if (drag || edit) return;
    setIsDragging(false);
    handleMouseEvents(event);
  };

  return (
    <Box
      ref={sliderRef}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        position: "relative",
      }}
      onMouseDown={handleMouseEvents}
      onMouseUp={handleMouseEvents}
      onTouchStart={handleMouseEvents}
      onTouchEnd={handleMouseEvents}
      onClick={handleMouseEvents}
    >
      {w1 && (
        <Slider
          value={brightness}
          onChange={handleBrightnessChange}
          aria-labelledby="brightness-slider"
          min={0}
          max={100}
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onTouchStart={handleDragStart}
          onTouchEnd={handleDragEnd}
          onMouseLeave={handleDragEnd}
          sx={{
            width: "100%",
            height: "100%",
            padding: "0 !important",
            borderRadius: 2,
            backgroundColor: "#e0e0e0",
            position: "relative",
            "--thumb-offset": `${brightness < 10 ? -brightness * 1.2 : -12}px`,
            border: "none",
            "& .MuiSlider-track": {
              height: "100%",
              borderRadius: 2,
              width: brightness === 0 ? 0 : `${brightness}%`,
              backgroundColor: "icon.y1",
              border: "none",
              clipPath:
                brightness === 0
                  ? "inset(0 0 0 100%)"
                  : brightness <= 10
                  ? `inset(0 ${100 - brightness}% 0 0)`
                  : `inset(0 0 0 ${
                      10 - Math.min((brightness / 100) * 90, 10)
                    }px)`,
              transition: "width 0.2s, clip-path 0.2s",
            },
            "& .MuiSlider-thumb": {
              display: brightness <= 10 ? "none" : "",
              width: 4,
              height: 20,
              backgroundColor: "#ffffff",
              boxShadow: "0 0 4px rgba(0, 0, 0, 0.3)",
              transform: `translateY(-50%) translateX(${
                brightness < 10 ? -brightness * 1.2 : -12
              }px)`,
              borderRadius: 1,
              "&:hover": {
                boxShadow: "0 0 8px rgba(0, 0, 0, 0.5)",
              },
            },
            "& .MuiSlider-rail": {
              opacity: 0,
            },
          }}
        />
      )}

      {isDragging && (
        <Portal>
          <div
            style={{
              position: "fixed",
              top: position.top,
              left: position.left,
              transform: "translateX(-50%)",
              backgroundColor: "#fff",
              color: "#000",
              padding: "4px 8px",
              borderRadius: "4px",
              fontSize: "12px",
              fontWeight: 500,
              minWidth: "40px",
              height: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow:
                "0 2px 8px rgba(0,0,0,0.15), 0 0 0 1px rgba(0,0,0,0.1)",
              pointerEvents: "none",
              userSelect: "none",
              zIndex: 99999,
              WebkitTransform: "translateX(-50%)",
              WebkitBackfaceVisibility: "hidden",
              backfaceVisibility: "hidden",
              WebkitFontSmoothing: "antialiased",
              MozOsxFontSmoothing: "grayscale",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "#fff",
                borderRadius: "4px",
                opacity: 1,
              }}
            />
            <span
              style={{
                position: "relative",
                zIndex: 1,
                color: "#000",
                fontWeight: 500,
              }}
            >
              {Math.round(brightness)}%
            </span>
          </div>
        </Portal>
      )}
    </Box>
  );
};

export default Brightness1Widget;
