import { Box } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";

const Button1Widget = ({ widget, onValueUpdate }) => {
  const [v0, setV0] = useState(null);
  const [isLongPress, setIsLongPress] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false); // 添加处理状态
  const longPressTimer = useRef(null);
  const longPressDelay = 500;

  useEffect(() => {
    setV0(widget);
    return () => {
      if (longPressTimer.current) {
        clearTimeout(longPressTimer.current);
      }
    };
  }, [widget]);

  const handleValueUpdate = async (clickType) => {
    if (isProcessing) return;

    try {
      setIsProcessing(true);

      const updateData = {
        id: v0.id,
        value: clickType === "long" ? "long click" : "click",
      };

      await onValueUpdate(updateData);
    } catch (error) {
      console.error("Failed to update value:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const startLongPress = (e) => {
    if (isProcessing) return;

    e.stopPropagation();
    if (e.type === "touchstart") {
      e.preventDefault();
    }

    longPressTimer.current = setTimeout(() => {
      setIsLongPress(true);
      handleValueUpdate("long");
    }, longPressDelay);
  };

  const endLongPress = (e) => {
    e.stopPropagation();
    if (e.type === "touchend") {
      e.preventDefault();
    }

    if (longPressTimer.current) {
      clearTimeout(longPressTimer.current);
    }

    if (!isLongPress && !isProcessing) {
      handleValueUpdate("click");
    }
    setIsLongPress(false);
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    return () => {
      setIsProcessing(false);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "stretch",
        height: "100%",
        width: "100%",
      }}
      onClick={handleClick}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onTouchStart={(e) => e.stopPropagation()}
      onTouchEnd={(e) => e.stopPropagation()}
    >
      {v0 && (
        <Button
          variant="contained"
          onMouseDown={startLongPress}
          onMouseUp={endLongPress}
          onMouseLeave={endLongPress}
          onTouchStart={startLongPress}
          onTouchEnd={endLongPress}
          onContextMenu={handleContextMenu}
          onClick={handleClick}
          disabled={isProcessing}
          sx={{
            height: "100%",
            width: "100%",
            minWidth: "unset",
            "& .MuiButton-label": {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
            "&:active": {
              transform: isProcessing ? "none" : "scale(0.98)",
            },
            userSelect: "none",
            WebkitTapHighlightColor: "transparent",
            position: "relative",
            zIndex: 1,
            opacity: isProcessing ? 0.7 : 1,
            transition: "opacity 0.2s",
            cursor: isProcessing ? "not-allowed" : "pointer",
          }}
        >
          {v0.label}
        </Button>
      )}
    </Box>
  );
};

export default Button1Widget;
