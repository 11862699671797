import { Box, Typography } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import React, { useEffect, useState } from "react";
import { DirectionsRun } from "@mui/icons-material";

import { IconButton } from "@mui/material";

const Motion1Widget = ({
  widget,
  onValueUpdate,
  drag = false,
  edit = false,
}) => {
  const [isMotion, setIsMotion] = useState(false);

  const [v0, setV0] = useState();

  useEffect(() => {
    setV0(widget);
    if (widget?.items?.$w1) {
      if (widget.items.$w1.value === "motion") {
        setIsMotion(true);
      } else {
        setIsMotion(false);
      }
    }
    return () => {};
  }, [widget]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          height: "100%",
          aspectRatio: "1/1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          sx={{
            width: "100%",
            height: "100%",
            padding: "4px",
            backgroundColor: isMotion
              ? "rgba(255, 215, 0, 0.1)"
              : "rgba(176, 176, 176, 0.1)",
            "&:hover": {
              backgroundColor: isMotion
                ? "rgba(255, 215, 0, 0.2)"
                : "rgba(176, 176, 176, 0.2)",
            },
          }}
        >
          <DirectionsRun
            style={{
              width: "100%",
              height: "100%",
              color: isMotion ? "#FFD700" : "#B0B0B0",
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Motion1Widget;
