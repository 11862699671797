import React, { useEffect, useState, useCallback } from "react";
import { Box } from "@mui/material";
import ColorRgbDialog1 from "../twin-widget-dialog/color-rgb-dialog-1";

const ColorRgb1Widget = ({
  widget,
  onValueUpdate,
  drag = false,
  edit = false,
}) => {
  const [colorRgb, setColorRgb] = useState("#ffffff");
  const [widgetData, setWidgetData] = useState(null);

  useEffect(() => {
    console.log("widget", widget);
    if (!widget) return;
    setWidgetData(widget);

    if (widget.items?.$w1) {
      setColorRgb(widget.items.$w1.value || "#ffffff");
    }
  }, [widget]);

  const handleValueUpdate = useCallback(
    (data) => {
      if (drag || edit || !widgetData) return;

      const updatedWidget = {
        ...widgetData.items.$w1,
        value: data,
      };

      onValueUpdate([updatedWidget]);
    },
    [drag, edit, widgetData, onValueUpdate]
  );

  const handleRgbClose = useCallback(
    (newColor) => {
      if (!newColor) return;
      setColorRgb(newColor);
      handleValueUpdate(newColor);
    },
    [handleValueUpdate]
  );

  const ColorButton = useCallback(
    ({ onClick }) => (
      <Box
        onClick={onClick}
        sx={{
          height: "100%",
          aspectRatio: "1/1",
          borderRadius: "50%",
          background: colorRgb || "#ffffff",
          cursor: drag || edit ? "default" : "pointer",
          transition: "all 0.3s ease",
          border: "2px solid rgba(255,255,255,0.1)",
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
          "&:hover": {
            transform: drag || edit ? "none" : "scale(1.05)",
            boxShadow: drag || edit ? "none" : "0 0 12px rgba(0,0,0,0.2)",
          },
          "&:active": {
            transform: drag || edit ? "none" : "scale(0.98)",
          },
        }}
      />
    ),
    [colorRgb, drag, edit]
  );

  if (!widgetData) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>Loading...</div>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "100%",
        minHeight: "fit-content",
        overflow: "visible",
        gap: "8px",
        padding: "4px",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <ColorRgbDialog1 color={colorRgb} onClose={handleRgbClose} />
    </Box>
  );
};

export default ColorRgb1Widget;
