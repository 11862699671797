import React, { useEffect, useState, useRef } from "react";
import * as echarts from "echarts";
import {
  Box,
  IconButton,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";

const generateMonthOptions = () => {
  const options = [];
  const currentDate = new Date();
  const startDate = new Date(2023, 0);

  for (
    let year = startDate.getFullYear();
    year <= currentDate.getFullYear();
    year++
  ) {
    const startMonth =
      year === startDate.getFullYear() ? startDate.getMonth() : 0;
    const endMonth =
      year === currentDate.getFullYear() ? currentDate.getMonth() : 11;

    for (let month = startMonth; month <= endMonth; month++) {
      const monthStr = (month + 1).toString().padStart(2, "0");
      const value = `${year}-${monthStr}`;

      const label = `${year}-${month + 1}`;
      options.push({ value, label });
    }
  }

  return options.sort((a, b) => b.value.localeCompare(a.value));
};

const monthOptions = generateMonthOptions();

const mockData = {
  billingRecordsData: Array.from({ length: 30 }, (_, i) => ({
    date: new Date(2025, 0, i + 1).toISOString().split("T")[0],
    onDemandBalance: Math.floor(Math.random() * 1000),
    refreshBalance: Math.floor(Math.random() * 500),
  })),
};

const BillingRecords = () => {
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[0].value);
  const [filteredData, setFilteredData] = useState(mockData.billingRecordsData);
  const history = useHistory();

  const historyChartRef = useRef(null);
  const pieChartRef = useRef(null);

  const totalOnDemandBalance = filteredData.reduce(
    (sum, item) => sum + item.onDemandBalance,
    0
  );
  const totalRefreshBalance = filteredData.reduce(
    (sum, item) => sum + item.refreshBalance,
    0
  );
  const totalBalance = totalOnDemandBalance + totalRefreshBalance;

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    // 这里添加调用后端API的逻辑
    // fetchDataForMonth(event.target.value);
  };

  useEffect(() => {
    if (!historyChartRef.current) return;
    const myChart = echarts.init(historyChartRef.current);

    const option = {
      title: {
        text: "Daily Balance Usage",
        left: "center",
        top: "5%",
        textStyle: {
          fontSize: 16,
          fontWeight: "bold",
          color: "text.primary",
        },
      },
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          const date = new Date(params[0].axisValue);
          const day = date.getDate();
          let result = `${day}<br/>`;
          params.forEach((param) => {
            result += `${param.seriesName}: ${param.value}<br/>`;
          });
          return result;
        },
      },
      legend: {
        data: ["On-Demand Balance", "Refresh Balance"],
        top: "12%",
      },
      grid: {
        top: "20%",
        left: "10%",
        right: "10%",
        bottom: "10%",
      },
      xAxis: {
        type: "category",
        data: filteredData.map((item) => item.date),
        axisLabel: {
          formatter: (value) => {
            return new Date(value).getDate();
          },
          interval: "auto",
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
        name: "Balance Usage",
      },
      series: [
        {
          name: "On-Demand Balance",
          type: "line",
          data: filteredData.map((item) => item.onDemandBalance),
          animation: true,
          areaStyle: {
            opacity: 0.2,
          },
        },
        {
          name: "Refresh Balance",
          type: "line",
          data: filteredData.map((item) => item.refreshBalance),
          animation: true,
          areaStyle: {
            opacity: 0.2,
          },
        },
      ],
      dataZoom: [
        {
          type: "slider",
          show: true,
          xAxisIndex: 0,
          start: 0,
          end: 100,
        },
        {
          type: "inside",
          xAxisIndex: 0,
          start: 0,
          end: 100,
        },
      ],
    };

    myChart.setOption(option);

    const handleResize = () => {
      myChart.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      myChart.dispose();
      window.removeEventListener("resize", handleResize);
    };
  }, [filteredData]);

  useEffect(() => {
    if (!pieChartRef.current) return;
    const pieChart = echarts.init(pieChartRef.current);

    const onDemandPercentage = (
      (totalOnDemandBalance / totalBalance) *
      100
    ).toFixed(2);
    const refreshPercentage = (
      (totalRefreshBalance / totalBalance) *
      100
    ).toFixed(2);

    const pieOption = {
      title: {
        text: "Balance Distribution",
        left: "center",
        top: "5%",
        textStyle: {
          fontSize: 16,
          fontWeight: "bold",
          color: "text.primary",
        },
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: "left",
        top: "middle",
        data: ["On-Demand Balance", "Refresh Balance"],
      },
      series: [
        {
          name: "Balance Type",
          type: "pie",
          radius: ["0%", "45%"],
          center: ["60%", "50%"],
          data: [
            {
              value: totalOnDemandBalance,
              name: "On-Demand Balance",
              itemStyle: { color: "#2196f3" },
            },
            {
              value: totalRefreshBalance,
              name: "Refresh Balance",
              itemStyle: { color: "#ff9800" },
            },
          ],
          label: {
            show: true,
            position: "outside",
            formatter: "{d}%",
            fontSize: 12,
            fontWeight: "bold",
            distanceToLabelLine: 5,
            padding: [0, 0, 0, 0],
            overflow: "none",
          },
          labelLine: {
            show: true,
            length: 5,
            length2: 5,
            maxSurfaceAngle: 80,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
      grid: {
        containLabel: true,
      },
    };

    pieChart.setOption(pieOption);

    const handleResize = () => {
      pieChart.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      pieChart.dispose();
      window.removeEventListener("resize", handleResize);
    };
  }, [totalOnDemandBalance, totalRefreshBalance]);

  return (
    <Box
      display="flex"
      justifyContent="start"
      alignItems="start"
      flexDirection="column"
      height="100%"
      p={2}
      width="100%"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            color="inherit"
            edge="start"
            sx={{ mr: 1 }}
            onClick={() => {
              history.replace("/setting#billing");
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">Billing Records</Typography>
        </Box>

        <FormControl sx={{ width: 130 }}>
          {" "}
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            size="small"
            sx={{ height: 40 }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            {monthOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ mt: 1, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            mb: 2,
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              borderRadius: "8px",
              padding: "12px",
              backgroundColor: "background.card",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              flex: {
                xs: "1 1 calc(50% - 16px)",
                sm: "1 1 calc(33.333% - 16px)",
              },
              maxWidth: "300px",
              minWidth: "150px",
              textAlign: "center",
            }}
          >
            <Box sx={{ fontSize: "16px", fontWeight: "bold" }}>
              Total Balance :
            </Box>
            <Box
              sx={{ fontSize: "24px", fontWeight: "bold", color: "#4caf50" }}
            >
              {totalBalance}
            </Box>
          </Box>
          <Box
            sx={{
              borderRadius: "8px",
              padding: "12px",
              backgroundColor: "background.card",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              flex: {
                xs: "1 1 calc(50% - 16px)",
                sm: "1 1 calc(33.333% - 16px)",
              },
              maxWidth: "300px",
              minWidth: "150px",
              textAlign: "center",
            }}
          >
            <Box sx={{ fontSize: "16px", fontWeight: "bold" }}>
              On-Demand Balance :
            </Box>
            <Box
              sx={{ fontSize: "24px", fontWeight: "bold", color: "#2196f3" }}
            >
              {totalOnDemandBalance}
            </Box>
          </Box>
          <Box
            sx={{
              borderRadius: "8px",
              padding: "12px",
              backgroundColor: "background.card",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              flex: {
                xs: "1 1 calc(50% - 16px)",
                sm: "1 1 calc(33.333% - 16px)",
              },
              maxWidth: "300px",
              minWidth: "150px",
              textAlign: "center",
            }}
          >
            <Box sx={{ fontSize: "16px", fontWeight: "bold" }}>
              Refresh Balance :
            </Box>
            <Box
              sx={{ fontSize: "24px", fontWeight: "bold", color: "#ff9800" }}
            >
              {totalRefreshBalance}
            </Box>
          </Box>
        </Box>

        <Box sx={{ width: "100%", height: { xs: "300px", sm: "400px" } }}>
          <Box
            ref={historyChartRef}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
        <Box
          sx={{ width: "100%", height: { xs: "300px", sm: "400px" }, mt: 2 }}
        >
          <Box ref={pieChartRef} style={{ width: "100%", height: "100%" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default BillingRecords;
