import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";

const mockData = {
  automationData: Array.from({ length: 24 }, (_, i) => ({
    automationId: 1,
    automationName: "Automation A",
    timeBucket: `2025-01-08 ${String(i).padStart(2, "0")}:00:00`,
    hour: i + 1,
    tags: generateTags(),
    createdCount: Math.floor(Math.random() * 50),
    successCount: Math.floor(Math.random() * 40),
    failCount: Math.floor(Math.random() * 10),
  })),
};

function generateTags() {
  const allTags = ["Tag1", "Tag2", "Tag3", "Tag4", "Tag5"];
  const numTags = Math.floor(Math.random() * allTags.length);
  return Array.from({ length: numTags }, () => {
    const randomIndex = Math.floor(Math.random() * allTags.length);
    return allTags[randomIndex];
  });
}

const AutomationStatistics = () => {
  const [viewMode, setViewMode] = useState("time");
  const [filteredData, setFilteredData] = useState(mockData.automationData);

  const totalCreatedCount = filteredData.reduce(
    (sum, item) => sum + item.createdCount,
    0
  );
  const totalSuccessCount = filteredData.reduce(
    (sum, item) => sum + item.successCount,
    0
  );
  const totalFailCount = filteredData.reduce(
    (sum, item) => sum + item.failCount,
    0
  );

  useEffect(() => {
    const chartDom = document.getElementById("history-chart");
    const myChart = echarts.init(chartDom);

    const option = {
      title: {
        text:
          viewMode === "time"
            ? "Automation Records by Time"
            : "Automation Records by Tags",
        left: "center",
        top: "5%",
        textStyle: {
          fontSize: 16,
          fontWeight: "bold",
        },
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["Created Count", "Success Count", "Fail Count"],
        top: "12%",
      },
      grid: {
        top: "20%",
        left: "10%",
        right: "10%",
        bottom: "10%",
      },
      xAxis: {
        type: "category",
        data:
          viewMode === "time"
            ? filteredData.map((item) => item.hour)
            : filteredData.map((item) => item.tags.join(", ") || "No Tags"),
        axisLabel: {
          interval: 0,
          formatter: viewMode === "time" ? (value) => `${value}h` : undefined,
        },
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Created Count",
          type: viewMode === "time" ? "line" : "bar",
          data: filteredData.map((item) => item.createdCount),
          animation: true,
        },
        {
          name: "Success Count",
          type: viewMode === "time" ? "line" : "bar",
          data: filteredData.map((item) => item.successCount),
          animation: true,
        },
        {
          name: "Fail Count",
          type: viewMode === "time" ? "line" : "bar",
          data: filteredData.map((item) => item.failCount),
          animation: true,
        },
      ],
      dataZoom: [
        {
          type: "slider",
          show: true,
          xAxisIndex: 0,
          start: 0,
          end: 50,
        },
        {
          type: "inside",
          xAxisIndex: 0,
          start: 0,
          end: 50,
        },
      ],
    };

    myChart.setOption(option);

    const handleResize = () => {
      myChart.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      myChart.dispose();
      window.removeEventListener("resize", handleResize);
    };
  }, [filteredData, viewMode]);

  useEffect(() => {
    if (viewMode === "time") {
      setFilteredData(mockData.automationData);
    } else if (viewMode === "tags") {
      const tagData = [];
      mockData.automationData.forEach((item) => {
        item.tags.forEach((tag) => {
          const existingTag = tagData.find((t) => t.tag === tag);
          if (existingTag) {
            existingTag.createdCount += item.createdCount;
            existingTag.successCount += item.successCount;
            existingTag.failCount += item.failCount;
          } else {
            tagData.push({
              tag,
              createdCount: item.createdCount,
              successCount: item.successCount,
              failCount: item.failCount,
            });
          }
        });
      });
      setFilteredData(tagData.map((t) => ({ tags: [t.tag], ...t })));
    }
  }, [viewMode]);

  useEffect(() => {
    const pieChartDom = document.getElementById("pie-chart");
    const pieChart = echarts.init(pieChartDom);

    const pieOption = {
      title: {
        text: "Success vs Fail Ratio",
        left: "center",
        top: "5%",
        textStyle: {
          fontSize: 16,
          fontWeight: "bold",
        },
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      series: [
        {
          name: "Ratio",
          type: "pie",
          radius: "50%",
          data: [
            { value: totalSuccessCount, name: "Success" },
            { value: totalFailCount, name: "Fail" },
          ],
          animation: true,
          label: {
            show: true,
            formatter: "{b}: {d}%",
            fontSize: 14,
            fontWeight: "bold",
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    pieChart.setOption(pieOption);

    const handleResize = () => {
      pieChart.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      pieChart.dispose();
      window.removeEventListener("resize", handleResize);
    };
  }, [totalSuccessCount, totalFailCount]);

  return (
    <Box sx={{ flexGrow: 1, height: "100%", m: 2, pb: "80px" }}>
      <ToggleButtonGroup
        value={viewMode}
        exclusive
        onChange={(e, newValue) => setViewMode(newValue)}
        sx={{ mb: 2 }}
      >
        <ToggleButton value="time">By Time</ToggleButton>
        <ToggleButton value="tags">By Tags</ToggleButton>
      </ToggleButtonGroup>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          mb: 2,
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            borderRadius: "8px",
            padding: "12px",
            backgroundColor: "background.white",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            flex: {
              xs: "1 1 calc(50% - 16px)",
              sm: "1 1 calc(33.333% - 16px)",
            },
            maxWidth: "300px",
            minWidth: "150px",
            textAlign: "center",
          }}
        >
          <Box sx={{ fontSize: "16px", fontWeight: "bold" }}>
            Total Created Count :
          </Box>
          <Box sx={{ fontSize: "24px", fontWeight: "bold", color: "#2196f3" }}>
            {totalCreatedCount}
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: "8px",
            padding: "12px",
            backgroundColor: "background.white",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            flex: {
              xs: "1 1 calc(50% - 16px)",
              sm: "1 1 calc(33.333% - 16px)",
            },
            maxWidth: "300px",
            minWidth: "150px",
            textAlign: "center",
          }}
        >
          <Box sx={{ fontSize: "16px", fontWeight: "bold" }}>
            Total Success Count :
          </Box>
          <Box sx={{ fontSize: "24px", fontWeight: "bold", color: "#4caf50" }}>
            {totalSuccessCount}
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: "8px",
            padding: "12px",
            backgroundColor: "background.white",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            flex: {
              xs: "1 1 calc(50% - 16px)",
              sm: "1 1 calc(33.333% - 16px)",
            },
            maxWidth: "300px",
            minWidth: "150px",
            textAlign: "center",
          }}
        >
          <Box sx={{ fontSize: "16px", fontWeight: "bold" }}>
            Total Fail Count :
          </Box>
          <Box sx={{ fontSize: "24px", fontWeight: "bold", color: "#ff9800" }}>
            {totalFailCount}
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "100%", height: { xs: "300px", sm: "400px" } }}>
        <Box id="history-chart" style={{ width: "100%", height: "100%" }} />
      </Box>
      <Box sx={{ width: "100%", height: { xs: "300px", sm: "400px" }, mt: 2 }}>
        <Box id="pie-chart" style={{ width: "100%", height: "100%" }} />
      </Box>
    </Box>
  );
};

export default AutomationStatistics;
