import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchableSelectInput from "../../input/searchable-select-input";

const MultiSelect1Widget = ({ widget, onValueUpdate }) => {
  const [v0, setV0] = useState(null);

  useEffect(() => {
    setV0(widget);
    return () => {};
  }, [widget]);

  const handleValueUpdate = (data) => {
    const updatedWidget = { ...v0, value: data };
    onValueUpdate(updatedWidget);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "stretch",
        height: "100%",
        width: "100%",
        padding: "2px",
      }}
    >
      {v0 && (
        <SearchableSelectInput
          valueSpec={v0}
          onChange={handleValueUpdate}
          onError={() => {}}
          multiSelect={true}
        />
      )}
    </Box>
  );
};

export default MultiSelect1Widget;
