import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import MSwitch from "../../common/switch";
import {
  disableAutomation,
  enableAutomation,
  runningAutomation,
} from "../../automation/automation-service";
import useApiCall from "../../common/api-call";
import { useRecoilState } from "recoil";
import { userInfoState } from "../../global-state";
import { useToast } from "../../common/toast";

const AutomationTitle1Widget = ({ widget, onStateUpdate }) => {
  const { apiCall } = useApiCall();
  const [userInfo] = useRecoilState(userInfoState);
  const { successToast, errorToast } = useToast();

  const [title, setTitle] = useState(widget.title);
  const [subtitle, setSubtitle] = useState(widget.subtitle);
  const [titleError, setTitleError] = useState(false);
  const [tempTitle, setTempTitle] = useState(widget.title);
  const [tempSubtitle, setTempSubtitle] = useState(widget.subtitle);
  const [saving, setSaving] = useState(false);

  const [automationType, setAutomationType] = useState("");
  const [automationId, setAutomationId] = useState("");
  const [running, setRunning] = useState(false);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setTitle(widget.title);
    setSubtitle(widget.subtitle);
    setAutomationType(widget.automationType);
    setAutomationId(widget.automationId);
    setRunning(widget.running);
    setEnabled(widget.enabled);
  }, [widget]);

  const handleEnableDisable = async (event, enableDisable) => {
    if (enableDisable) {
      const success = await enableAutomation(
        apiCall,
        userInfo.activeScope.id,
        automationId
      );
      if (success) {
        successToast("Enabled.");
        setEnabled(true);
        onStateUpdate &&
          onStateUpdate({
            ...widget,
            enabled: true,
          });
      } else {
        errorToast("Error to enable the automation.");
      }
    } else {
      const success = await disableAutomation(
        apiCall,
        userInfo.activeScope.id,
        automationId
      );
      if (success) {
        successToast("Disabled.");
        setEnabled(false);
        onStateUpdate &&
          onStateUpdate({
            ...widget,
            enabled: false,
          });
      } else {
        errorToast("Error to disable the automation.");
      }
    }
  };

  const handleStartTaskFlow = async () => {
    const success = await runningAutomation(
      apiCall,
      userInfo.activeScope.id,
      automationId
    );
    if (success) {
      successToast("Started");
    } else {
      errorToast("Error!");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        p: 1,
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          gap: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            flex: "1 1 auto",
            overflow: "hidden",
            minWidth: 0,
            mr: 4,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: 1.2,
              mb: 0.5,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "12px",
              lineHeight: 1.2,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: "text.secondary",
            }}
          >
            {subtitle}
          </Typography>
        </Box>

        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "32px",
            height: "32px",
            minWidth: "32px",
          }}
        >
          {automationType === "RECURRING" ? (
            <MSwitch
              checked={enabled}
              onChange={handleEnableDisable}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
            />
          ) : running ? (
            <CircularProgress size={20} />
          ) : (
            <IconButton
              size="small"
              onClick={(e) => {
                handleStartTaskFlow();
                e.stopPropagation();
              }}
              sx={{
                p: 0.5,
                "&.Mui-disabled": {
                  opacity: 0.5,
                },
              }}
            >
              <PlayCircleFilledWhiteIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AutomationTitle1Widget;
