import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import ColorRgbDialog1 from "../twin-widget-dialog/color-rgb-dialog-1";
import ColorTemperatureDialog1 from "../twin-widget-dialog/color-temperature-dialog-1";

const Color1Widget = ({
  widget,
  onValueUpdate,
  drag = false,
  edit = false,
}) => {
  const [colorRgb, setColorRgb] = useState("ffffff");
  const [colorTemp, setColorTemp] = useState(2700);
  const [tempDialogOpen, setTempDialogOpen] = useState(false);
  const [current, setCurrent] = useState("rgb");
  const [widgetData, setWidgetData] = useState(null);

  const [tempMinMax, setTempMinMax] = useState({ min: 2700, max: 6500 });

  useEffect(() => {
    if (!widget) return;

    setWidgetData(widget);

    if (widget.items?.$w1) {
      const colorValue = widget.items.$w1.value.toLowerCase() || "ffffff";
      setColorRgb(colorValue.replace(/^#/, ""));
    }

    if (widget.items?.$w2) {
      setTempMinMax({
        min: widget.items.$w2.min ?? 2700,
        max: widget.items.$w2.max ?? 6500,
      });
      setColorTemp(widget.items.$w2.value || 2700);
    }

    if (widget.items?.$w3) {
      setCurrent(widget.items.$w3.value || "rgb");
    }
  }, [widget]);

  const handleValueUpdate = (data, type) => {
    if (drag || edit || !widgetData) return;

    const updatedWidgets = {};

    if (type === "$w1") {
      updatedWidgets.$w1 = { ...widgetData.items.$w1 };
      updatedWidgets.$w3 = { ...widgetData.items.$w3 };
      updatedWidgets.$w1.value = data.replace(/^#/, "");
      updatedWidgets.$w3.value = "rgb";
      setColorTemp(null);
      setCurrent("rgb");
    } else if (type === "$w2") {
      updatedWidgets.$w2 = { ...widgetData.items.$w2 };
      updatedWidgets.$w3 = { ...widgetData.items.$w3 };
      updatedWidgets.$w2.value = data;
      updatedWidgets.$w3.value = "temperature";
      setColorRgb(null);
      setCurrent("temperature");
    }

    onValueUpdate(Object.values(updatedWidgets));
  };

  const handleRgbClose = (newColor) => {
    if (!newColor) return;
    setColorRgb(newColor);
    handleValueUpdate(newColor, "$w1");
  };

  const handleTempClose = (newTemp) => {
    if (!newTemp) return;
    setColorTemp(newTemp);
    handleValueUpdate(newTemp, "$w2");
  };

  const handleTempChange = (event, newValue) => {
    if (drag || edit) return;
    event.stopPropagation();

    const adjustedValue = Math.min(
      Math.max(newValue, tempMinMax.min),
      tempMinMax.max
    );

    setColorTemp(adjustedValue);
    handleValueUpdate(adjustedValue, "$w2");
  };

  const kelvinToRGB = (kelvin) => {
    let r, g, b;
    if (kelvin <= 2700) {
      r = 255;
      g = 160;
      b = 109;
    } else if (kelvin <= 4000) {
      r = 255;
      g = 197;
      b = 143;
    } else {
      r = 201;
      g = 226;
      b = 255;
    }
    return `rgb(${r}, ${g}, ${b})`;
  };

  const ColorButton = ({ onClick }) => (
    <Box
      onClick={onClick}
      sx={{
        height: "100%",
        aspectRatio: "1/1",
        borderRadius: "50%",
        background:
          current === "temperature"
            ? `linear-gradient(to bottom, ${kelvinToRGB(
                tempMinMax.min
              )}, ${kelvinToRGB(tempMinMax.max)})`
            : `linear-gradient(to bottom, rgb(219, 213, 213), rgb(69, 68, 68))`,
        cursor: drag || edit ? "default" : "pointer",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: drag || edit ? "none" : "scale(1.05)",
          boxShadow: drag || edit ? "none" : "0 0px 8px rgba(0,0,0,0.15)",
        },
      }}
    />
  );

  if (!widgetData) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>Loading...</div>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "100%",
        minHeight: "fit-content",
        overflow: "hidden",
        gap: "8px",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <ColorRgbDialog1
        color={colorRgb}
        onClose={handleRgbClose}
        current={current}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          aspectRatio: "1/1",
        }}
      >
        <ColorButton
          onClick={(e) => {
            e.stopPropagation();
            if (drag || edit) return;
            setTempDialogOpen(true);
          }}
        />
      </Box>

      {tempDialogOpen && (
        <ColorTemperatureDialog1
          open={tempDialogOpen}
          setOpen={setTempDialogOpen}
          colorTemp={colorTemp}
          onClose={handleTempClose}
          onColorTempChange={handleTempChange}
          minTemp={tempMinMax.min}
          maxTemp={tempMinMax.max}
        />
      )}
    </Box>
  );
};

export default Color1Widget;
