import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ErrorIcon from "@mui/icons-material/Error";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Box from "@mui/material/Box";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { useHistory } from "react-router-dom";
import SearchableSelectInput from "../input/searchable-select-input";
import NumberInput from "../input/number-input";
import TimeInput from "../input/time-input";
import DateInput from "../input/date-input";
import AddIcon from "@mui/icons-material/Add";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import LoadingSpinner from "../common/loading";
import FixedSelectInput from "../input/fixed-select-input";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { HowToRegOutlined, DirectionsRun } from "@mui/icons-material";
import { toEntityTitle } from "../common/string-helpers";

import {
  Smartphone,
  Tablet,
  Monitor,
  Tv,
  Watch,
  Globe,
  Laptop,
  WandSparkles,
  Timer,
  Clock,
  FileDigit,
  FileType2,
  Calendar,
  CalendarClock,
  Sparkles,
  Command,
  Lightbulb,
  SmartphoneNfc,
  CirclePower,
  Palette,
  Bolt,
  HousePlug,
  ToggleLeft,
  MessageCircleWarning,
  AlignHorizontalJustifyCenter,
  MapPin,
  Sunrise,
  CirclePlay,
  Speaker,
  Thermometer,
  Fan,
  Blinds,
  BatteryMedium,
  FileQuestion,
} from "lucide-react";
import {
  connectIntegration,
  deleteAutomation,
  disableAutomation,
  enableAutomation,
  getAutomation,
  initAutomationEditing,
  saveAutomationName,
  runningAutomation,
  copyAutomation,
  enrollAutomation,
  getAutomationCardInfo,
} from "./automation-service";
import Typography from "@mui/material/Typography";
import Markdown from "../common/markdown";
import NumberUnitInput from "../input/number-unit-input";
import TextInput from "../input/text-input";
import DeleteAutomationDialog from "./delete-automation-dialog";
import { useToast } from "../common/toast";
import ConnectDialog from "../integration/connect-dialog";
import { useTheme } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import CodeEditor from "../common/code-editor";
import { useWebSocket } from "../ws/websocket";
import TextField from "@mui/material/TextField";
import EditOffIcon from "@mui/icons-material/EditOff";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useApiCall from "../common/api-call";
import dayjs from "dayjs";
import { customFormat } from "../common/custom-format";
import { useEventEmitter } from "../ws/event-context";
import MSwitch from "../common/switch";
import { CircleIcon } from "lucide-react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import Chip from "@mui/material/Chip";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CanvasSelectDialog from "./canvas-select-dialog";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TimestampInput from "../input/timestamp-input";

const getSelector = (spec) => {
  if (spec.hasEnums) {
    return "FIXED_SELECT";
  } else if (spec.type === "String") {
    return "TEXT";
  } else if (spec.type === "Number") {
    return "NUMBER";
  } else if (
    spec.type === "Duration" ||
    spec.type === "Temperature" ||
    spec.type === "TemperatureDiff"
  ) {
    return "NUMBER_UNIT";
  } else if (spec.type === "Time") {
    return "TIME";
  } else if (spec.type === "Date") {
    return "DATE";
  } else if (spec.type === "Timestamp") {
    return "TIMESTAMP";
  } else if (
    spec.type === "SpecialTime" ||
    spec.type === "SpecialDate" ||
    spec.type === "RelativeDate"
  ) {
    return "FIXED_SELECT";
  } else {
    return "DYNAMIC_SELECT";
  }
};

const varValueCheck = (varName, varSpec) => {
  if (!varSpec || varSpec.empty) {
    return false;
  }
  return varSpec.valid;
};

const DataSelector = ({
  varName,
  spec,
  widgetized,
  widgetizable,
  widgetizeLabel,
  widgetizeVar,
  putWidgetizeLabel,
  setLocalWidgetizeVar,
  onSave,
  onCancel,
  onFetch,
  onConnect,
}) => {
  const selectMap = {
    FIXED_SELECT: (f, idx) => (
      <Box
        sx={{
          height: "100%",
        }}
      >
        <FixedSelectInput
          valueSpec={f}
          onChange={(value) => handleValueChange(f, idx, value)}
          onError={(error) => handleErrorChange(idx, error)}
          menuDirection="top"
        ></FixedSelectInput>
      </Box>
    ),
    DYNAMIC_SELECT: (f, idx) => (
      <SearchableSelectInput
        valueSpec={f}
        onChange={(value) => handleValueChange(f, idx, value)}
        onError={(error) => handleErrorChange(idx, error)}
        fetchValueSpec={dynamicValueSpecFetch}
        listPosition="top"
      ></SearchableSelectInput>
    ),
    NUMBER: (f, idx) => (
      <NumberInput
        valueSpec={f}
        onChange={(value) => handleValueChange(f, idx, value)}
        onError={(error) => handleErrorChange(idx, error)}
      ></NumberInput>
    ),
    TIME: (f, idx) => (
      <TimeInput
        valueSpec={f}
        onChange={(value) => handleValueChange(f, idx, value)}
        embedded={true}
      ></TimeInput>
    ),
    DATE: (f, idx) => (
      <DateInput
        valueSpec={f}
        onChange={(value) => handleValueChange(f, idx, value)}
      ></DateInput>
    ),
    TIMESTAMP: (f, idx) => (
      <TimestampInput
        valueSpec={f}
        onChange={(value) => handleValueChange(f, idx, value)}
      ></TimestampInput>
    ),
    TEXT: (f, idx) => (
      <Box
        sx={{
          height: "100%",
        }}
      >
        <TextInput
          valueSpec={f}
          onChange={(value) => handleValueChange(f, idx, value)}
        ></TextInput>
      </Box>
    ),
    NUMBER_UNIT: (f, idx) => (
      <NumberUnitInput
        valueSpec={f}
        onChange={(value, unit) =>
          handleValueChange(f, idx, value.toString() + unit)
        }
        onError={(error) => handleErrorChange(idx, error)}
      ></NumberUnitInput>
    ),
  };

  const [selector, setSelector] = useState(null);
  const [entityType, setEntityType] = useState(false);
  const [selectable, setSelectable] = useState(true);
  const [valueSpecs, setValueSpecs] = useState([]);
  const [values, setValues] = useState([]);
  const [errors, setErrors] = useState([]);

  const [w, setW] = useState(widgetized);
  const [wl, setWl] = useState(widgetizeLabel);

  useEffect(() => {
    setSelector(getSelector(spec));
    const isEntityType = spec.entity || false;
    setEntityType(isEntityType);
    const vs = [];
    const vals = [];
    const errs = [];
    for (const item of spec.items) {
      const value = item.value;
      const valueSpec = item.valueSpec;
      const v = populateValueSpec(value, valueSpec, isEntityType);
      vals.push(item.value);
      vs.push(v);
      errs.push(false);
    }
    setValues(vals);
    setErrors(errs);
    setValueSpecs(vs);
    setSelectable(spec.type !== "basic.ui.Widget" || !widgetized);
  }, []);

  const handleValueChange = (valueSpec, idx, newValue) => {
    values[idx] = newValue;
    valueSpecs[idx].value = newValue;
  };

  const handleErrorChange = (idx, err) => {
    errors[idx] = err;
  };

  const dynamicValueSpecFetch = async (inputValue) => {
    const varData = {
      var: varName,
      input: inputValue,
    };
    return await onFetch(varData);
  };

  const handleAdd = async () => {
    const vs = await dynamicValueSpecFetch("");
    const value = vs.value;
    const v = populateValueSpec(value, vs.valueSpec, entityType);
    setValues([...values, value]);
    setErrors([...errors, false]);
    setValueSpecs([...valueSpecs, v]);
  };

  const populateValueSpec = (value, valueSpec, isEntityType) => {
    const v = { ...valueSpec, value, titleBreak: isEntityType };
    return v;
  };

  const handleDelete = (idx) => {
    const tValues = [...values];
    tValues.splice(idx, 1);
    const tValueSpecs = [...valueSpecs];
    tValueSpecs.splice(idx, 1);
    const tErrors = [...errors];
    tErrors.splice(idx, 1);
    setValues(tValues);
    setValueSpecs(tValueSpecs);
    setErrors(tErrors);
  };

  const handleSave = async () => {
    widgetizeVar(varName, w);
    if (spec.type === "basic.ui.Widget") {
      setLocalWidgetizeVar(varName, w);
      if (w) {
        if (wl) {
          putWidgetizeLabel(varName, wl);
        } else {
          return;
        }
      }
    }
    if (!selectable) {
      onCancel();
      return;
    }

    if (values.length === 0 || errors.indexOf(true) !== -1) {
      return;
    }
    const varData = {
      var: varName,
      inputs: [...values],
    };
    await onSave(varData);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleConnect = async () => {
    const parts = spec.type.split(".");
    const integration = parts[0] + "." + parts[1];
    await onConnect(integration);
  };

  return (
    <Box>
      <Grid container spacing={2} direction="column">
        {spec.connect && (
          <Grid item xs={1}>
            <Button size="medium" variant="outlined" onClick={handleConnect}>
              Link New Account
            </Button>
          </Grid>
        )}
        {widgetizable && (
          <>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={w}
                    onChange={(e) => {
                      setW(e.target.checked);
                      if (spec.type === "basic.ui.Widget") {
                        setSelectable(!e.target.checked);
                      }
                    }}
                  />
                }
                label={
                  spec.type === "basic.ui.Widget"
                    ? "Auto Create Widget in Card"
                    : "Use Widget to Override Value"
                }
              />
            </Grid>
            {w && spec.type === "basic.ui.Widget" && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Label"
                  variant="outlined"
                  size="medium"
                  required
                  error={!wl || wl.trim() === ""}
                  helperText={
                    !wl || wl.trim() === "" ? "Label is required" : ""
                  }
                  value={wl}
                  onChange={(e) => {
                    setWl(e.target.value);
                  }}
                />
              </Grid>
            )}
          </>
        )}
        {selectMap[selector] !== undefined && selectable && (
          <>
            {valueSpecs.map((valueSpec, idx) => (
              <Grid item xs={1} key={`param-${varName}-${idx}`}>
                <Grid container columns={{ xs: 12 }}>
                  {spec.repeated && (
                    <Grid item xs="auto">
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(idx)}
                        disabled={valueSpecs.length <= 1}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                  <Grid item xs>
                    {selectMap[selector](valueSpec, idx)}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </>
        )}
        {spec.repeated && (
          <Grid item xs={1}>
            <Button size="medium" fullWidth onClick={handleAdd}>
              <AddIcon />
            </Button>
          </Grid>
        )}
        <Grid item xs={1}>
          <Grid
            container
            columns={{ xs: 12 }}
            rowSpacing={2}
            justifyContent="space-between"
          >
            <Grid item xs={6}>
              <Button
                size="medium"
                variant="outlined"
                fullWidth
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                size="medium"
                variant="contained"
                fullWidth
                onClick={handleSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const AutomationEditCardContent = ({
  params,
  onChangeSummary,
  onSave,
  onDelete,
  editable,
}) => {
  const [edit, setEdit] = useState(false);
  const [editOnly, setEditOnly] = useState(false);
  const [titleEditMode, setTitleEditMode] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedVar, setSelectedVar] = useState("");
  const [loading, setLoading] = useState(true);
  const [script, setScript] = useState("");
  const [varSaving, setVarSaving] = useState(false);
  const [userInfo] = useRecoilState(userInfoState);
  const history = useHistory();
  const { sendAndWaitWs } = useWebSocket();
  const [spinButton, setSpinButton] = useState(null);
  const { apiCall } = useApiCall();

  const [sessionId, setSessionId] = useState(null);
  const [temp, setTemp] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [running, setRunning] = useState(false);
  const [runnable, setRunnable] = useState(false);
  const [requiredInstalls, setRequiredInstalls] = useState([]);
  const [automationName, setAutomationName] = useState(null);
  const [automationDescription, setAutomationDescription] = useState(null);
  const [connectIntegrationName, setConnectIntegrationName] = useState("");
  const [connectInfo, setConnectInfo] = useState(null);
  const [connectConfirm, setConnectConfirm] = useState(false);
  const [vars, setVars] = useState({});
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const theme = useTheme();
  const { successToast, errorToast } = useToast();
  const [type, setType] = useState(null);
  const [timestamp, setTimestamp] = useState(0);
  const [nameError, setNameError] = useState("");
  const [currentTime, setCurrentTime] = useState(dayjs());
  const { ws, sendAndForgetWs } = useWebSocket();
  const { subscribe } = useEventEmitter();
  const [oldDescr, setOldDescr] = useState("");
  const lastStatusTimestampRef = useRef(0);
  const [chips, setChips] = useState([]);

  // canvas related
  const [canvasDialogOpen, setCanvasDialogOpen] = useState(false);
  const [selectedCanvases, setSelectedCanvases] = useState([]);
  const [currentCanvases, setCurrentCanvases] = useState([]);
  const [canvases, setCanvases] = useState([]);
  const [widgetizeVars, setWidgetizeVars] = useState([]);
  const [localWidgets, setLocalWidgets] = useState([]);
  const [widgetizeLabels, setWidgetizeLabels] = useState({});

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000); // Update every second

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  useEffect(() => {
    if (params && sendAndWaitWs) {
      if (params.draft || params.premadeId) {
        setEditOnly(true);
        initEdit(
          params.automationId,
          params.premadeId,
          params.premadeVersion,
          params.draft
        );
        loadWidgetizeInfo();
      } else {
        initRead(params.automationId);
      }
    }
    return () => {};
  }, [params]);

  useEffect(() => {
    if (automationName === "") {
      setNameError("Name cannot be empty.");
    } else if (
      automationName &&
      automationName.trim().toLowerCase() === "this"
    ) {
      setNameError("Name cannot be 'this'.");
      setTitleEditMode(true);
    } else {
      setNameError("");
    }
  }, [automationName]);

  useEffect(() => {
    const { unsubscribe } = subscribe((target, data) => {
      if (target === "/automation/history/update") {
        const payload = JSON.parse(data.payload);
        if (payload && payload.automationId === params.automationId) {
          setTimestamp(payload.timestamp);
        }
      } else if (target === "/automation/runningStatus/update") {
        const payload = JSON.parse(data.payload);
        if (
          payload &&
          payload.automationId === params.automationId &&
          payload.timestamp > lastStatusTimestampRef.current
        ) {
          lastStatusTimestampRef.current = payload.timestamp;
          setRunning(payload.running);
        }
      }
    });
    return () => {
      unsubscribe();
    };
  }, [params]);

  const enterEdit = async () => {
    if (edit) {
      return;
    }
    setEdit(true);
    initEdit(
      params.automationId,
      params.premadeId,
      params.premadeVersion,
      params.draft
    );
    loadWidgetizeInfo();
  };

  const initRead = async (automationId) => {
    setLoading(true);
    const resp = await getAutomation(
      apiCall,
      userInfo.activeScope.id,
      automationId
    );
    console.log(resp);
    if (resp) {
      setAutomationName(resp["name"]);
      setAutomationDescription(resp["description"]);
      setOldDescr(resp["description"]);
      setRunnable(resp["runnable"]);
      setEnabled(resp["enabled"]);
      setTemp(resp["temp"]);
      setRunning(resp["running"]);
      setScript(resp["script"]);
      setType(resp["type"]);
      if (resp["automationHistory"]) {
        setTimestamp(resp["automationHistory"]["timestamp"]);
      }
      setLoading(false);
      setEdit(false);
      if (resp.card) {
        setChips([{ name: "Dashboard Card", color: "dashboard" }]);
      }
      if (resp.widgetizeInfo) {
        const wv = resp.widgetizeInfo.widgetizeVars ?? [];
        const lv = resp.widgetizeInfo.localWidgets ?? [];
        setWidgetizeVars([...wv, ...lv]);
        setLocalWidgets(lv);
        setWidgetizeLabels(resp.widgetizeInfo.widgetizeLabels ?? {});
      }
      onChangeSummary(resp["assistantDescription"]);
    } else {
      errorToast("Error loading automation.");
    }
  };

  const loadWidgetizeInfo = async () => {
    const resp = await getAutomationCardInfo(
      apiCall,
      userInfo.activeScope.id,
      params.automationId
    );
    if (resp) {
      console.log(resp);
      setCanvases(resp.allCanvas);
      setCurrentCanvases(resp.currentCanvas.map((c) => c.id));
    } else {
      errorToast("Error loading data!");
    }
  };

  const handleWidgetize = (varName, include) => {
    if (include) {
      setWidgetizeVars((prev) =>
        prev.includes(varName) ? prev : [...prev, varName]
      );
    } else {
      setWidgetizeVars((prev) => prev.filter((v) => v !== varName));
    }
  };

  const handleLocalWidgetize = (varName, include) => {
    if (include) {
      setLocalWidgets((prev) =>
        prev.includes(varName) ? prev : [...prev, varName]
      );
    } else {
      setLocalWidgets((prev) => prev.filter((v) => v !== varName));
    }
  };

  const handleWidgetizeLabel = (varName, label) => {
    setWidgetizeLabels((prev) => {
      return { ...prev, [varName]: label };
    });
  };

  const initEdit = async (automationId, premadeId, premadeVersion, draft) => {
    setLoading(true);
    const resp = await initAutomationEditing(
      sendAndWaitWs,
      automationId,
      premadeId,
      premadeVersion,
      draft
    );

    if (resp) {
      if (resp.automationOverview) {
        const automationOverview = resp.automationOverview;
        setSessionId(resp["sessionId"]);
        setAutomationName(automationOverview["name"]);
        setOldDescr(automationOverview["description"]);
        setAutomationDescription(automationOverview["description"]);
        setVars(automationOverview["vars"]);
        setRunnable(automationOverview["runnable"]);
        setEnabled(automationOverview["enabled"]);
        setRunning(automationOverview["running"]);
        setLoading(false);
        setEdit(true);
        if (draft) {
          setScript(draft);
        }
        onChangeSummary(automationOverview["assistantDescription"]);
      } else if (resp.requiredInstalls) {
        setRequiredInstalls(resp.requiredInstalls);
        setLoading(false);
      }
    } else {
      errorToast("Error loading automation.");
    }
  };

  const handleVarValueSave = async (varData) => {
    try {
      setVarSaving(true);
      const response = await sendAndWaitWs(
        "/automation/overview/update-config",
        JSON.stringify({ ...varData, sessionId: sessionId })
      );
      if (response.error) {
        throw new Error(response.error);
      }
      const jsonData = JSON.parse(response.payload);
      setAutomationDescription(jsonData["description"]);
      setVars(jsonData["vars"]);
      onChangeSummary(jsonData["assistantDescription"]);
      setShowDrawer(false);
      setTimeout(() => {
        setVarSaving(false);
      }, 500);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleVarValueFetch = async (varData) => {
    try {
      const response = await sendAndWaitWs(
        "/automation/overview/provide-config",
        JSON.stringify({ ...varData, sessionId: sessionId })
      );
      if (response.error) {
        throw new Error(response.error);
      }
      const jsonData = JSON.parse(response.payload);
      return jsonData;
    } catch (error) {
      errorToast("Error!");
    }
  };

  const handleDrawerClose = () => {
    setShowDrawer(false);
  };

  const handleAutomationNameSave = async () => {
    try {
      const response = await saveAutomationName(
        apiCall,
        userInfo.activeScope.id,
        params.automationId,
        automationName
      );
      if (response.error) {
        throw new Error(response.error);
      }
      successToast("Name Saved");
    } catch (error) {
      errorToast("Error!");
      console.error("Error saving name:", error);
    }
  };

  const handleDescriptionCancel = () => {
    setAutomationDescription(oldDescr);
    setEdit(!edit);
  };

  const isWidgetable = (v) => {
    const primitive = vars[v].type.indexOf(".") === -1;
    const widget = vars[selectedVar].type === "basic.ui.Widget";
    return isAddDashboard() && (primitive || widget);
  };

  const isAddDashboard = () => {
    return currentCanvases.length > 0 || selectedCanvases.length > 0;
  };

  const handleAutomationSave = async () => {
    try {
      setLoading(true);
      let response = null;
      if (params.draft) {
        // draft
        response = await sendAndWaitWs(
          "/automation/overview/save",
          JSON.stringify({
            sessionId,
            preferredName: automationName,
            automationId: params.automationId,
            debug: userInfo.setting.debugMode || false,
            addCard: isAddDashboard(),
            widgetizeVars,
            widgetizeLabels,
            canvasIds: selectedCanvases,
          })
        );
      } else if (params.automationId) {
        response = await sendAndWaitWs(
          "/automation/overview/save",
          JSON.stringify({
            sessionId,
            preferredName: automationName,
            automationId: params.automationId,
            debug: userInfo.setting.debugMode || false,
            addCard: isAddDashboard(),
            widgetizeVars,
            widgetizeLabels,
            canvasIds: selectedCanvases,
          })
        );
      } else if (params.premadeId) {
        response = await sendAndWaitWs(
          "/automation/overview/save",
          JSON.stringify({
            sessionId,
            premadeId: params.premadeId,
            premadeVersion: params.premadeVersion,
            preferredName: automationName,
            debug: userInfo.setting.debugMode || false,
            addCard: isAddDashboard(),
            widgetizeVars,
            widgetizeLabels,
            canvasIds: selectedCanvases,
          })
        );
      } else {
        return null;
      }

      if (response.error) {
        throw new Error(response.error);
      }
      const jsonData = JSON.parse(response.payload);
      setRunnable(jsonData["runnable"]);
      successToast("Saved");
      onSave(jsonData.automationId);
    } catch (error) {
      errorToast("Save error!");
      console.error("Error saving data:", error);
      setLoading(false);
    }
  };

  const handleEnableDisable = async (event, enableDisable) => {
    if (enableDisable) {
      const success = await enableAutomation(
        apiCall,
        userInfo.activeScope.id,
        params.automationId
      );
      if (success) {
        successToast("Enabled.");
        setEnabled(true);
      } else {
        errorToast("Error to enable the automation.");
      }
    } else {
      const success = await disableAutomation(
        apiCall,
        userInfo.activeScope.id,
        params.automationId
      );
      if (success) {
        successToast("Disabled.");
        setEnabled(false);
        // window.location.reload();
      } else {
        errorToast("Error to disable the automation.");
      }
    }
  };

  const handleStartTaskFlow = async (running) => {
    if (running) {
      const success = await runningAutomation(
        apiCall,
        userInfo.activeScope.id,
        params.automationId
      );
      if (success) {
        successToast("Started");
      } else {
        errorToast("Error!");
      }
    }
  };

  const handleCopy = async () => {
    setSpinButton("copy");
    const resp = await copyAutomation(
      apiCall,
      userInfo.activeScope.id,
      params.automationId,
      `Copy - ${automationName}`
    );
    if (resp) {
      onSave(resp.automationId);
    } else {
      errorToast("Error!");
    }
    setSpinButton(null);
  };

  const handleDelete = async () => {
    const success = await deleteAutomation(
      apiCall,
      userInfo.activeScope.id,
      params.automationId,
      true
    );
    if (success) {
      onDelete(params.automationId, type);
    } else {
      errorToast("Error. Please retry.");
    }
    setDeleteConfirm(false);
  };

  const handleConnect = async (integration) => {
    const resp = await connectIntegration(
      apiCall,
      userInfo.activeScope.id,
      integration
    );
    console.log(resp);
    if (resp) {
      setConnectInfo(resp);
      setConnectIntegrationName(integration);
      setConnectConfirm(true);
    }
  };

  const handleMarkdownLink = ({ children, href }) => {
    const handleClick = (event) => {
      event.preventDefault();
      const varPart = event.currentTarget.href.substring(
        event.currentTarget.href.lastIndexOf("#") + 1
      );
      const varName = "$" + varPart.split("-")[0];
      setSelectedVar(varName);
      setShowDrawer(true);
    };

    const varPart = href.substring(href.lastIndexOf("#") + 1);
    const parts = varPart.split("-");
    let varName = "$" + parts[0];

    // Parse tag and flag
    let varTag = "";
    let varFlag = 0;

    // Check if name part contains a flag
    if (parts[0].includes("+")) {
      const [name, flag] = parts[0].split("+");
      varName = "$" + name;
      varFlag = Number(flag);
    } else if (parts.length > 1) {
      // Check if last part contains a flag
      const lastPart = parts[parts.length - 1];
      if (lastPart.includes("+")) {
        const [tag, flag] = lastPart.split("+");
        varTag = tag;
        varFlag = Number(flag);
      } else {
        varTag = lastPart;
      }
    }

    let color = theme.palette.text.l1;
    if (widgetizeVars.indexOf(varName) > -1) {
      color = theme.palette.text.l2;
    }
    const validStyle = {
      color: color,
      fontWeight: "bold",
      textDecoration: "underline",
    };
    const invalidStyle = {
      color: theme.palette.text.invalid,
      fontWeight: "bold",
      textDecoration: "underline",
      textDecorationStyle: "wavy",
      textDecorationColor: theme.palette.text.l4,
    };

    const localW = localWidgets.indexOf(varName) > -1;
    const valid = varValueCheck(varName, vars[varName]) || localW;
    const text = localW
      ? `Auto Widget (${widgetizeLabels[varName] || ""})`
      : toEntityTitle(children, (varFlag & 2) > 0);

    return (
      <>
        <a
          href={href}
          onClick={handleClick}
          style={valid ? validStyle : invalidStyle}
        >
          {getDisplayContent(text, varTag)}
        </a>
      </>
    );
  };

  const getDisplayContent = (children, varTag) => {
    const getIcon = (tag) => {
      const iconProps = {
        size: 16,
        style: {
          verticalAlign: "middle",
          marginLeft: "2px",
          marginRight: "2px",
        },
      };

      const mProps = {
        fontSize: 16,
        verticalAlign: "middle",
        ml: 0.25,
        mr: 0.25,
      };

      switch (tag) {
        case "Duration":
          return <Timer {...iconProps} />;
        case "SpecialTime":
          return <Sunrise {...iconProps} />;
        case "Time":
          return <Clock {...iconProps} />;
        case "Date":
        case "SpecialDate":
        case "RelativeDate":
          return <Calendar {...iconProps} />;
        case "Timestamp":
          return <CalendarClock {...iconProps} />;
        case "Number":
          return <FileDigit {...iconProps} />;
        case "Temperature":
        case "TemperatureDiff":
          return <Thermometer {...iconProps} />;
        case "String":
          return <FileType2 {...iconProps} />;
        case "Bool":
          return <ToggleLeft {...iconProps} />;

        case "phone":
          return <Smartphone {...iconProps} />;
        case "automation":
          return <Sparkles {...iconProps} />;
        case "widget":
          return <Command {...iconProps} />;
        case "device":
          return <HousePlug {...iconProps} />;

        case "battery":
          return <BatteryMedium {...iconProps} />;

        case "light":
        case "brightness":
          return <Lightbulb {...iconProps} />;

        case "fan":
          return <Fan {...iconProps} />;
        case "window":
          return <Blinds {...iconProps} />;
        case "speaker":
          return <Speaker {...iconProps} />;
        case "media":
          return <CirclePlay {...iconProps} />;
        case "motionSensor":
        case "presenceSensor":
        case "lightSensor":
          return <SmartphoneNfc {...iconProps} />;
        case "contactSensor":
          return <AlignHorizontalJustifyCenter {...iconProps} />;
        case "temperatureSensor":
        case "humiditySensor":
          return <Thermometer {...iconProps} />;
        case "colorRGB":
        case "colorTemperature":
          return <Palette {...iconProps} />;
        case "on/off":
          return <CirclePower {...iconProps} />;
        case "pressButton":
          return <ToggleLeft {...iconProps} />;
        case "presenceState":
          return <HowToRegOutlined sx={mProps} />;
        case "motionState":
          return <DirectionsRun sx={mProps} />;

        case "wakeWord":
          return <MessageCircleWarning {...iconProps} />;

        case "location":
          return <MapPin {...iconProps} />;
        case "sunMoon":
          return <Sunrise {...iconProps} />;
        default:
          if (tag) console.log(tag);
          return null;
      }
    };

    const icon = getIcon(varTag);
    const displayContent =
      varTag && icon ? (
        <>
          <span style={{ marginLeft: "4px" }}>{icon}</span> {children}
        </>
      ) : (
        children
      );

    return displayContent;
  };

  const handleMarkdownNonEditLink = ({ children, href }) => {
    const handleClick = (event) => {
      event.preventDefault();
    };
    const varPart = href.substring(href.lastIndexOf("#") + 1);
    const parts = varPart.split("-");
    let varName = "$" + parts[0];

    // Parse tag and flag
    let varTag = "";
    let varFlag = 0;

    // Check if name part contains a flag
    if (parts[0].includes("+")) {
      const [name, flag] = parts[0].split("+");
      varName = "$" + name;
      varFlag = Number(flag);
    } else if (parts.length > 1) {
      // Check if last part contains a flag
      const lastPart = parts[parts.length - 1];
      if (lastPart.includes("+")) {
        const [tag, flag] = lastPart.split("+");
        varTag = tag;
        varFlag = Number(flag);
      } else {
        varTag = lastPart;
      }
    }

    let color = theme.palette.text.disabled;
    if (widgetizeVars.indexOf(varName) > -1) {
      color = theme.palette.text.l2;
    }
    const dStyle = {
      color: color,
      fontWeight: "bold",
      textDecoration: "underline",
    };

    let text = toEntityTitle(children, (varFlag & 2) > 0);
    if (localWidgets.indexOf(varName) > -1) {
      text = `Auto Widget (${widgetizeLabels[varName] || ""})`;
    }
    return (
      <a href={href} onClick={handleClick} style={dStyle}>
        {getDisplayContent(text, varTag)}
      </a>
    );
  };

  const handleJumpClick = (buttonType, path) => {
    console.log(path);
    setSpinButton(buttonType);
    history.push(path);
    setSpinButton(null);
  };

  const handleEnrollTemp = async () => {
    setSpinButton("enrollTemp");
    const resp = await enrollAutomation(
      apiCall,
      userInfo.activeScope.id,
      params.automationId,
      automationName
    );
    if (resp) {
      onSave(resp.automationId);
    } else {
      errorToast("Error!");
    }
    setSpinButton(null);
  };

  const handleTitleEditToggle = () => {
    if (nameError === "") {
      setTitleEditMode(!titleEditMode);
    }
    if (titleEditMode) {
      if (params.automationId) {
        // can update name only if the automation already exists
        handleAutomationNameSave();
      }
    }
    setTitleEditMode(!titleEditMode);
  };

  return (
    <>
      {loading ? (
        <CardContent>
          <LoadingSpinner />
        </CardContent>
      ) : (
        <>
          {requiredInstalls && requiredInstalls.length > 0 ? (
            <CardContent
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                paddingTop: "16px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingBottom: "16px !important",
                backgroundColor: theme.palette.background.card,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 2,
                  color: "text.primary",
                  fontWeight: 500,
                }}
              >
                Following required integrations are not installed:
              </Typography>

              <List sx={{ width: "100%", p: 0 }}>
                {requiredInstalls.map((integration, index) => (
                  <ListItem
                    key={integration.integrationId}
                    sx={{
                      px: 0,
                      py: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      borderBottom:
                        index < requiredInstalls.length - 1
                          ? `1px solid ${theme.palette.divider}`
                          : "none",
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "auto" }}>
                      <CircleIcon
                        sx={{
                          fontSize: 8,
                          color: theme.palette.text.secondary,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={integration.integrationName}
                      sx={{
                        m: 0,
                        "& .MuiListItemText-primary": {
                          color: theme.palette.text.secondary,
                          fontSize: "0.95rem",
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      href={`/integration?id=${integration.integrationId}`}
                      size="small"
                      sx={{
                        minWidth: "90px",
                        textTransform: "none",
                        boxShadow: "none",
                        ml: "auto",
                        "&:hover": {
                          boxShadow: "none",
                        },
                      }}
                    >
                      View
                    </Button>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          ) : (
            <CardContent
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingTop: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingBottom: "8px !important",
                backgroundColor: theme.palette.background.card,
              }}
            >
              <Box display="flex" alignItems="center" mb={2} mt={2}>
                {!params.automationId || titleEditMode ? (
                  <TextField
                    value={automationName}
                    onChange={(e) => setAutomationName(e.target.value)}
                    fullWidth
                    variant="outlined"
                    size="large"
                    sx={{ marginRight: 1 }}
                    error={nameError !== ""}
                    helperText={nameError}
                  />
                ) : (
                  <Typography variant="h5">{automationName}</Typography>
                )}
                {params.automationId && !temp && (
                  <IconButton
                    onClick={handleTitleEditToggle}
                    sx={{ color: "text.primary" }}
                    disabled={
                      nameError !== "" ||
                      !automationName ||
                      automationName.trim() === ""
                    }
                  >
                    {titleEditMode ? <EditOffIcon /> : <EditIcon />}
                  </IconButton>
                )}
              </Box>
              <Box display="flex" alignItems="center">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  {edit && (
                    <>
                      <Grid item>
                        <Button
                          variant="contained"
                          onClick={handleAutomationSave}
                          disabled={!edit || spinButton !== null}
                        >
                          Save
                        </Button>
                      </Grid>
                      {!editOnly && (
                        <Grid item>
                          <Button
                            variant="contained"
                            onClick={handleDescriptionCancel}
                          >
                            Cancel
                          </Button>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Chip
                          label={"Add Dashboard Card"}
                          variant="outlined"
                          sx={{
                            borderRadius: 1,
                            cursor: "pointer",
                            bgcolor: (theme) =>
                              isAddDashboard()
                                ? theme.palette.background.l3
                                : theme.palette.background.l1,
                          }}
                          onClick={() => setCanvasDialogOpen(true)}
                        />
                      </Grid>
                    </>
                  )}

                  {temp && (
                    <>
                      <Grid
                        item
                        container
                        alignItems="center"
                        wrap="nowrap"
                        spacing={1}
                      >
                        <Grid
                          item
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {runnable ? (
                            running ? (
                              <CircularProgress size={20} />
                            ) : (
                              <CheckCircleOutlineIcon
                                sx={{ fontSize: 20 }}
                                color="success"
                              />
                            )
                          ) : (
                            <ErrorIcon sx={{ fontSize: 20 }} color="error" />
                          )}
                        </Grid>
                        <Grid
                          item
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {runnable ? (
                            running ? (
                              <Chip
                                label="Running"
                                size="small"
                                color="primary"
                                variant="outlined"
                                sx={{
                                  height: "24px",
                                  "& .MuiChip-label": {
                                    px: 1,
                                    lineHeight: "24px",
                                  },
                                }}
                              />
                            ) : (
                              <Chip
                                label="Done"
                                size="small"
                                color="success"
                                variant="outlined"
                                sx={{
                                  height: "24px",
                                  "& .MuiChip-label": {
                                    px: 1,
                                    lineHeight: "24px",
                                  },
                                }}
                              />
                            )
                          ) : (
                            <Chip
                              label="Error"
                              size="small"
                              color="error"
                              variant="outlined"
                              sx={{
                                height: "24px",
                                "& .MuiChip-label": {
                                  px: 1,
                                  lineHeight: "24px",
                                },
                              }}
                            />
                          )}
                        </Grid>
                        {params.automationId && (
                          <Grid item>
                            <IconButton
                              aria-label="delete"
                              disabled={spinButton !== null}
                              onClick={() => setDeleteConfirm(true)}
                              sx={{ color: theme.palette.error.main }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={handleEnrollTemp}
                          disabled={spinButton !== null}
                        >
                          Save as TaskFlow
                          {spinButton === "enrollTemp" && (
                            <CircularProgress
                              size={20}
                              style={{ marginLeft: 10 }}
                            />
                          )}
                        </Button>
                      </Grid>
                    </>
                  )}

                  {!edit && !temp && (
                    <>
                      <Grid item>
                        {runnable && params.automationId ? (
                          type === "RECURRING" ? (
                            <MSwitch
                              checked={enabled}
                              disabled={
                                !runnable || edit || spinButton !== null
                              }
                              onChange={handleEnableDisable}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          ) : running ? (
                            <CircularProgress size={24} />
                          ) : (
                            <IconButton
                              disabled={!runnable || edit}
                              onClick={(e) => {
                                handleStartTaskFlow(true);
                                e.stopPropagation();
                              }}
                              sx={{
                                "&.Mui-disabled": {
                                  opacity: 0.5,
                                },
                              }}
                            >
                              <PlayCircleFilledWhiteIcon
                                color={runnable ? "primary" : "disabled"}
                              />
                            </IconButton>
                          )
                        ) : params.automationId ? (
                          <ErrorIcon style={{ color: "red" }} />
                        ) : (
                          <></>
                        )}
                      </Grid>
                      {timestamp !== 0 && params.automationId && (
                        <Grid item>
                          <IconButton
                            size="small"
                            aria-label="automation history"
                            onClick={() =>
                              handleJumpClick(
                                "history",
                                "/automation/history?id=" + params.automationId
                              )
                            }
                            disabled={spinButton !== null}
                            sx={{ color: theme.palette.primary.main }}
                          >
                            <HistoryIcon />
                          </IconButton>
                        </Grid>
                      )}

                      {params.automationId && (
                        <Grid item>
                          <IconButton
                            size="small"
                            aria-label="automation history"
                            onClick={() => {
                              history.push(
                                `/automation/history/statistics?id=${params.automationId}`
                              );
                            }}
                            disabled={spinButton !== null}
                            sx={{ color: theme.palette.primary.main }}
                          >
                            <AssessmentIcon />
                          </IconButton>
                        </Grid>
                      )}
                      {params.automationId && (
                        <Grid item>
                          <IconButton
                            aria-label="delete"
                            disabled={spinButton !== null}
                            onClick={() => setDeleteConfirm(true)}
                            sx={{ color: theme.palette.error.main }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      )}
                      {params.automationId && (
                        <Grid item>
                          {spinButton === "copy" ? (
                            <CircularProgress size={24} />
                          ) : (
                            <IconButton
                              aria-label="copy"
                              disabled={spinButton !== null}
                              onClick={handleCopy}
                              sx={{ color: theme.palette.primary.main }}
                            >
                              <FileCopyIcon />
                            </IconButton>
                          )}
                        </Grid>
                      )}
                      {params.automationId && userInfo.setting.scriptMode && (
                        <Grid item>
                          <Button
                            size="small"
                            variant="contained"
                            // onClick={() => openCodeView(params.automationId)}
                            onClick={() =>
                              handleJumpClick(
                                "script",
                                "/automation/script?id=" + params.automationId
                              )
                            }
                            disabled={spinButton !== null}
                          >
                            Script
                            {spinButton === "script" && (
                              <CircularProgress
                                size={20}
                                style={{ marginLeft: 10 }}
                              />
                            )}
                          </Button>
                        </Grid>
                      )}
                      {params.automationId && userInfo.setting.debugMode && (
                        <Grid item>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() =>
                              handleJumpClick(
                                "debug",
                                "/automation/debug?id=" + params.automationId
                              )
                            }
                            disabled={spinButton !== null}
                          >
                            Debug
                            {spinButton === "debug" && (
                              <CircularProgress
                                size={20}
                                style={{ marginLeft: 10 }}
                              />
                            )}
                          </Button>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 1, mt: 2 }}
              >
                {chips.map((chip, index) => (
                  <Chip
                    key={index}
                    label={chip.name}
                    size="small"
                    sx={{
                      marginBottom: 1,
                      backgroundColor:
                        chip.color === "dashboard"
                          ? theme.palette.background.l3
                          : theme.palette.background.l1,
                      color: theme.palette.text.primary,
                      height: "24px",
                    }}
                  />
                ))}
              </Box>
              {timestamp !== 0 && !edit && (
                <Box>
                  <Typography
                    variant="caption"
                    component="div"
                    sx={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      marginTop: "5px",
                      color: theme.palette.text.gray,
                    }}
                  >
                    {customFormat(dayjs(timestamp), currentTime)}
                  </Typography>
                </Box>
              )}
              <Box
                mt={2}
                mb={2}
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.background.default}`,
                  "&:hover": {
                    backgroundColor:
                      edit || !editable
                        ? theme.palette.background.paper
                        : theme.palette.action.hover,
                  },
                  cursor: edit || !editable ? "default" : "pointer",
                  padding: "8px",
                  borderRadius: "8px",
                  display: "flex", // Use flex to position items horizontally
                  alignItems: "center", // Align items vertically in the center
                }}
                onClick={() => !edit && editable && enterEdit()} // Only trigger edit mode if it's clickable
              >
                <Markdown
                  components={{
                    a:
                      edit && editable
                        ? handleMarkdownLink
                        : handleMarkdownNonEditLink,
                  }}
                >
                  {automationDescription}
                </Markdown>

                {!edit && editable && (
                  <EditIcon
                    sx={{ ml: 1, color: theme.palette.text.secondary }}
                  /> // Adjust margin and color as needed
                )}
              </Box>
              {userInfo.setting.scriptMode && (
                <Accordion sx={{ backgroundColor: "background.white" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Script</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeEditor
                      value={script}
                      editable={false}
                      height="400px"
                    />
                  </AccordionDetails>
                </Accordion>
              )}
            </CardContent>
          )}
        </>
      )}
      <Drawer anchor="bottom" open={showDrawer} onClose={handleDrawerClose}>
        <Box p={2}>
          {varSaving || !selectedVar ? (
            <LoadingSpinner />
          ) : (
            <DataSelector
              varName={selectedVar}
              spec={vars[selectedVar]}
              onSave={handleVarValueSave}
              onCancel={handleDrawerClose}
              onFetch={handleVarValueFetch}
              onConnect={handleConnect}
              widgetized={widgetizeVars.indexOf(selectedVar) !== -1}
              widgetizable={isWidgetable(selectedVar)}
              widgetizeLabel={widgetizeLabels[selectedVar] || ""}
              widgetizeVar={handleWidgetize}
              setLocalWidgetizeVar={handleLocalWidgetize}
              putWidgetizeLabel={handleWidgetizeLabel}
            ></DataSelector>
          )}
        </Box>
      </Drawer>
      <DeleteAutomationDialog
        open={deleteConfirm}
        onClose={() => setDeleteConfirm(false)}
        onDelete={handleDelete}
      ></DeleteAutomationDialog>
      <ConnectDialog
        open={connectConfirm}
        integration={connectIntegrationName}
        connectInfo={connectInfo}
        onClose={() => setConnectConfirm(false)}
        onSuccess={() => successToast("Linked!")}
        onFailure={() => errorToast("Link Error!")}
      ></ConnectDialog>
      <CanvasSelectDialog
        open={canvasDialogOpen}
        onClose={() => setCanvasDialogOpen(false)}
        canvases={canvases}
        selectedCanvases={selectedCanvases}
        onCanvasSelectionChange={setSelectedCanvases}
        currentCanvasIds={currentCanvases}
      ></CanvasSelectDialog>
    </>
  );
};

export default AutomationEditCardContent;
