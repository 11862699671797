import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const CARD_COLORS = [
  { value: "white", label: "White", color: "#ffffff" },
  { value: "red", label: "Red", color: "#fff1f0" },
  { value: "pink", label: "Pink", color: "#fff0f6" },
  { value: "purple", label: "Purple", color: "#f9f0ff" },
  { value: "blue", label: "Blue", color: "#f0f5ff" },
  { value: "green", label: "Green", color: "#f6ffed" },
  { value: "orange", label: "Orange", color: "#fff7e6" },
];

export default function CardContentEditDialog({ open, onClose, onSave, card }) {
  const [tempTitle, setTempTitle] = useState("");
  const [tempSubtitle, setTempSubtitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [selectedColor, setSelectedColor] = useState("default");
  const [titleWidget, setTitleWidget] = useState(null);
  const [saving, setSaving] = useState(false);

  const handleCardData = (card) => {
    if (!card) {
      console.warn("Card data is null or undefined");
      return;
    }
    try {
      const tw = card.widgets?.find((w) => w?.skin === "default.title");

      if (tw?.title) {
        setTempTitle(tw.title);
        setTempSubtitle(tw?.subtitle || "");
        setTitleWidget(tw);
      } else {
        setTempTitle("");
        setTempSubtitle("");
      }

      if (card.setting?.backgroundColor) {
        setSelectedColor(card.setting.backgroundColor);
      } else {
        setSelectedColor("default");
      }
    } catch (error) {
      console.error("Error processing card data:", error);
      setTempTitle("");
      setTempSubtitle("");
      setSelectedColor("default");
    }
  };

  useEffect(() => {
    try {
      if (card) {
        handleCardData(card);
      }
    } catch (error) {
      console.error("Error in useEffect:", error);
    }
  }, [card, open]);

  const handleTitleChange = (e) => {
    setTempTitle(e.target.value);
    setTitleError(!e.target.value.trim());
  };

  const handleSave = async () => {
    if (!tempTitle.trim()) {
      setTitleError(true);
      return;
    }

    setSaving(true);
    try {
      if (onSave) {
        const updates = [];

        if (tempTitle) {
          updates.push({
            ...titleWidget,
            title: tempTitle,
          });
        }

        console.log("mm");
        console.log(updates);
        if (updates.length > 0 || selectedColor) {
          await onSave({
            widget: updates,
            setting: {
              ...(card?.setting || {}),
              backgroundColor: selectedColor,
            },
          });
        }
      }
      onClose();
    } catch (error) {
      console.error("Failed to save:", error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { minWidth: { xs: "90%", sm: "400px" } },
      }}
    >
      <DialogTitle sx={{ pb: 2 }}>Edit Card Content</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Title"
          fullWidth
          value={tempTitle}
          onChange={handleTitleChange}
          error={titleError}
          helperText={titleError ? "Title cannot be empty" : ""}
          sx={{ mb: 2 }}
        />

        <FormControl fullWidth margin="dense">
          <InputLabel id="card-color-label">Card Color</InputLabel>
          <Select
            labelId="card-color-label"
            value={selectedColor}
            label="Card Color"
            onChange={(e) => setSelectedColor(e.target.value)}
          >
            {CARD_COLORS.map((color) => (
              <MenuItem
                key={color.value}
                value={color.color}
                sx={{
                  color: "#222",
                  backgroundColor: color.color,
                  "&:hover": {
                    backgroundColor: color.color,
                    opacity: 0.9,
                  },
                }}
              >
                {color.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ p: 2, pt: 1 }}>
        <Button onClick={onClose} sx={{ mr: 1 }}>
          Cancel
        </Button>
        <Box sx={{ position: "relative" }}>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              minWidth: "80px",
            }}
          >
            Save
            {saving && (
              <CircularProgress
                size={20}
                sx={{
                  ml: 1,
                }}
              />
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
