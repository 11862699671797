import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

const UpsertWidgetDialog = ({
  open,
  titleWidget,
  currentWidget,
  existingNames,
  onClose,
  onSave,
  isUpserting,
}) => {
  const [dupNames, setDupNames] = useState([]);
  const [name, setName] = useState("");
  const [widgetType, setWidgetType] = useState("");
  const [unit, setUnit] = useState("");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [options, setOptions] = useState([]);
  const [optionInput, setOptionInput] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [isInteger, setIsInteger] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [unitError, setUnitError] = useState(false);
  const [widgetTypeError, setWidgetTypeError] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (titleWidget) {
      setName(titleWidget.name);
    }
    if (currentWidget) {
      setReadOnly(currentWidget.disabled || false);
      setWidgetType(currentWidget.data.type);
      setUnit(currentWidget.data.unit || "");
      if (currentWidget.data.min !== null) {
        setMin(currentWidget.data.min);
      }
      if (currentWidget.data.max !== null) {
        setMax(currentWidget.data.max);
      }
      setOptions(currentWidget.data.options ? currentWidget.data.options : []);
      setButtonName(currentWidget.data.label || "");
      setIsInteger(currentWidget.data.integer || false);
    }
    return () => {};
  }, [currentWidget]);

  useEffect(() => {
    const names = [...existingNames];
    if (titleWidget) {
      const index = names.indexOf(titleWidget.name);
      if (index > -1) {
        names.splice(index, 1);
      }
    }
    setDupNames(names);
    return () => {};
  }, [titleWidget, existingNames]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleButtonNameChange = (event) => {
    setButtonName(event.target.value);
  };

  const handleWidgetTypeChange = (event) => {
    setWidgetType(event.target.value);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const handleMinChange = (event) => {
    setMin(event.target.value);
  };

  const handleMaxChange = (event) => {
    setMax(event.target.value);
  };

  const handleOptionInputChange = (event) => {
    setOptionInput(event.target.value);
  };

  const handleOptionInputKeyDown = (event) => {
    if (event.key === "Enter" && optionInput.trim() !== "") {
      addOption();
    }
  };

  const handleDeleteOption = (optionToDelete) => () => {
    setOptions(options.filter((option) => option !== optionToDelete));
  };

  const addOption = () => {
    if (optionInput.trim() !== "") {
      setOptions([...options, optionInput.trim()]);
      setOptionInput("");
    }
  };

  const handleReadOnlyChange = (event) => {
    setReadOnly(event.target.checked);
  };

  const handleIsIntegerChange = (event) => {
    setIsInteger(event.target.checked);
  };

  const handleSave = () => {
    if (name.trim() === "") {
      setNameError(true);
      return;
    }
    if (dupNames.indexOf(name.trim()) !== -1) {
      setNameError(true);
      return;
    }
    if (widgetType.trim() === "") {
      setWidgetTypeError(true);
      return;
    }

    const tWidget = {
      type: "title",
      disabled: false,
      title: name,
      subtitle: "Widget",
    };

    // button widget
    if (widgetType === "Button") {
      const dWidget = {
        type: "button",
        disabled: false,
        label: buttonName,
      };
      if (currentWidget) {
        dWidget.id = currentWidget.id;
      }
      onSave([tWidget, dWidget]);
      onClose();
      return;
    }

    // data widget
    const dWidget = {
      type: "data",
      dataType: widgetType,
      disabled: readOnly,
      repeated: false,
      name,
    };
    if (widgetType === "Number") {
      dWidget.min = min;
      dWidget.max = max;
      if (isInteger) {
        dWidget.step = 1;
      }
    } else if (
      widgetType === "Duration" ||
      widgetType === "Temperature" ||
      widgetType === "TemperatureDiff"
    ) {
      dWidget.min = min;
      dWidget.max = max;
      dWidget.unit = unit;
    } else if (widgetType === "Select" || widgetType === "MultiSelect") {
      dWidget.dataType = "String";
      dWidget.options = options;
    }

    if (currentWidget) {
      dWidget.id = currentWidget.id;
    }
    onSave([tWidget, dWidget]);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {currentWidget ? "Update Widget Setting" : "New Widget"}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="Name"
          label="Name"
          type="text"
          fullWidth
          value={name}
          onChange={handleNameChange}
          error={nameError}
          helperText={nameError && "Name cannot be empty, or duplicate"}
        />
        {!currentWidget && (
          <FormControl fullWidth margin="dense">
            <InputLabel id="widget-type-label">Widget Type</InputLabel>
            <Select
              labelId="widget-type-label"
              id="widgetType"
              value={widgetType}
              onChange={handleWidgetTypeChange}
              error={widgetTypeError}
              label="Widget Type"
            >
              <MenuItem value="Bool">Toggle</MenuItem>
              <MenuItem value="Number">Number</MenuItem>
              <MenuItem value="Button">Button</MenuItem>
              <MenuItem value="Select">Select</MenuItem>
              {/*<MenuItem value="MultiSelect">Multi-Select</MenuItem>*/}
              <MenuItem value="String">Text</MenuItem>
              <MenuItem value="Date">Date</MenuItem>
              <MenuItem value="Time">Time</MenuItem>
              <MenuItem value="Timestamp">Date Time</MenuItem>
              <MenuItem value="Duration">Time Duration</MenuItem>
              <MenuItem value="Temperature">Temperature</MenuItem>
              <MenuItem value="TemperatureDiff">
                Temperature Difference
              </MenuItem>
              {/*<MenuItem value="MARKDOWN">Markdown</MenuItem>*/}
              {/*<MenuItem value="COLOR_HEX">Color Picker</MenuItem>*/}
              {/*<MenuItem value="COLOR_HUE">Color Hue</MenuItem>*/}
              {/*<MenuItem value="COLOR_TEMPERATURE">Color Temperature</MenuItem>*/}
            </Select>
          </FormControl>
        )}
        {/*<FormControlLabel*/}
        {/*  control={*/}
        {/*    <Checkbox*/}
        {/*      checked={readOnly}*/}
        {/*      onChange={handleReadOnlyChange}*/}
        {/*      name="readOnly"*/}
        {/*      color="primary"*/}
        {/*    />*/}
        {/*  }*/}
        {/*  label="Read-only"*/}
        {/*/>*/}
        {(widgetType === "Select" || widgetType === "MultiSelect") && (
          <FormControl fullWidth margin="dense">
            <InputLabel shrink id="options-label">
              Options
            </InputLabel>
            <Paper
              elevation={0}
              component="ul"
              style={{
                display: "flex",
                flexWrap: "wrap",
                listStyle: "none",
                padding: "8px",
                marginTop: "8px",
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              {options.map((option, index) => (
                <li key={index} style={{ margin: "4px" }}>
                  <Chip
                    label={option}
                    onDelete={handleDeleteOption(option)}
                    style={{
                      color: theme.palette.text.l1,
                      backgroundColor: theme.palette.background.l1,
                    }}
                  />
                </li>
              ))}
            </Paper>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                id="optionInput"
                label="Add Option"
                type="text"
                value={optionInput}
                onChange={handleOptionInputChange}
                onKeyDown={handleOptionInputKeyDown}
                fullWidth
                margin="dense"
              />
              <IconButton
                onClick={addOption}
                color="primary"
                aria-label="add option"
              >
                <AddIcon />
              </IconButton>
            </div>
          </FormControl>
        )}
        {widgetType === "Button" && (
          <TextField
            margin="dense"
            id="buttonName"
            label="Button Label"
            type="text"
            fullWidth
            value={buttonName}
            onChange={handleButtonNameChange}
          />
        )}
        {(widgetType === "Number" ||
          widgetType === "Duration" ||
          widgetType === "Temperature" ||
          widgetType === "TemperatureDiff") && (
          <>
            <TextField
              margin="dense"
              id="min"
              label="Min"
              type="number"
              fullWidth
              value={min}
              onChange={handleMinChange}
            />
            <TextField
              margin="dense"
              id="max"
              label="Max"
              type="number"
              fullWidth
              value={max}
              onChange={handleMaxChange}
            />
            {widgetType === "Number" && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isInteger}
                    onChange={handleIsIntegerChange}
                    name="integer"
                    color="primary"
                  />
                }
                label="Integer"
              />
            )}
            {(widgetType === "Duration" ||
              widgetType === "Temperature" ||
              widgetType === "TemperatureDiff") && (
              <FormControl fullWidth margin="dense">
                <InputLabel id="unit-label">Unit</InputLabel>
                <Select
                  labelId="unit-label"
                  id="unit"
                  value={unit}
                  onChange={handleUnitChange}
                  error={unitError}
                  label="Unit"
                >
                  {widgetType === "Duration" && (
                    <MenuItem value="min">min</MenuItem>
                  )}
                  {widgetType === "Duration" && (
                    <MenuItem value="sec">sec</MenuItem>
                  )}
                  {widgetType === "Duration" && (
                    <MenuItem value="hour">hour</MenuItem>
                  )}
                  {widgetType === "Duration" && (
                    <MenuItem value="day">day</MenuItem>
                  )}
                  {widgetType === "Duration" && (
                    <MenuItem value="ms">ms</MenuItem>
                  )}
                  {(widgetType === "Temperature" ||
                    widgetType === "TemperatureDiff") && (
                    <MenuItem value="C">C</MenuItem>
                  )}
                  {(widgetType === "Temperature" ||
                    widgetType === "TemperatureDiff") && (
                    <MenuItem value="F">F</MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isUpserting}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          disabled={isUpserting || !name || !widgetType}
        >
          {currentWidget ? "Update" : "Add"}
          {isUpserting && (
            <CircularProgress
              size={20}
              sx={{
                ml: 1,
              }}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpsertWidgetDialog;
