export const searchableSelectCheck = (value, valueSpec) => {
  if (!valueSpec || !valueSpec.enums) {
    return false;
  }
  if (!value) {
    return false;
  }
  // If value is an object, use its value property
  const actualValue = typeof value === "object" ? value.value : value;

  // Check if the value exists in enums, handling both string and object cases
  const exists = valueSpec.enums.some(
    (enumValue) =>
      (typeof enumValue === "object" ? enumValue.value : enumValue) ===
      actualValue
  );

  if (!exists) {
    return false;
  }

  if (valueSpec.valueCheck !== undefined) {
    if (!valueSpec.valueCheck && valueSpec.value === actualValue) {
      return false;
    }
  }
  return true;
};
