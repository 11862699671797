import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const CanvasSelectDialog = ({
  open,
  onClose,
  canvases,
  selectedCanvases,
  onCanvasSelectionChange,
  currentCanvasIds,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Canvases</DialogTitle>
      <DialogContent>
        <FormGroup>
          {canvases.map((canvas) => {
            const isCurrentCanvas = currentCanvasIds.includes(canvas.id);
            return (
              <FormControlLabel
                key={canvas.id}
                control={
                  <Checkbox
                    checked={
                      selectedCanvases.includes(canvas.id) || isCurrentCanvas
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        onCanvasSelectionChange([
                          ...selectedCanvases,
                          canvas.id,
                        ]);
                      } else {
                        // Only allow unselecting if it's not one of the current canvases
                        if (!isCurrentCanvas) {
                          onCanvasSelectionChange(
                            selectedCanvases.filter((id) => id !== canvas.id)
                          );
                        }
                      }
                    }}
                    disabled={isCurrentCanvas} // Disable checkbox for current canvases
                  />
                }
                label={canvas.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CanvasSelectDialog;
