import React, { createContext, useContext, useState } from "react";

const DragDropContext = createContext();

export function DragDropProvider({ children }) {
  const [draggedCard, setDraggedCard] = useState(null);

  return (
    <DragDropContext.Provider value={{ draggedCard, setDraggedCard }}>
      {children}
    </DragDropContext.Provider>
  );
}

export function useDragDrop() {
  return useContext(DragDropContext);
}
