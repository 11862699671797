import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import { Helmet } from "react-helmet";

// Create a wrapper component
const CacheControlWrapper = ({ children }) => (
  <>
    <Helmet>
      <meta
        httpEquiv="Cache-Control"
        content="no-cache, no-store, must-revalidate"
      />
      <meta httpEquiv="Pragma" content="no-cache" />
      <meta httpEquiv="Expires" content="0" />
    </Helmet>
    {children}
  </>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CacheControlWrapper>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </CacheControlWrapper>
  </React.StrictMode>
);

reportWebVitals();
