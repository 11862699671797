export const upsertWidget = async (apiCall, scopeId, canvasId, items) => {
  const request = { scopeId, canvasId, items };
  console.log(request);
  const response = await apiCall("/ui/widget/save", "POST", request);
  return response && response.success;
};

export const updateCanvasCards = async (apiCall, scopeId, canvasId, cards) => {
  const request = { scopeId, canvasId, cards };
  const response = await apiCall("/ui/card/upsert", "POST", request);
  return response && response.success;
};

export const updateCardLayout = async (apiCall, scopeId, canvasId, cards) => {
  const request = { scopeId, canvasId, cards };
  const response = await apiCall("/ui/card/layout/update", "POST", request);
  return response && response.success;
};

export const updateWidgetLayout = async (
  apiCall,
  scopeId,
  cardId,
  widgets,
  setting
) => {
  const request = { scopeId, cardId, widgets, setting };
  const response = await apiCall("/ui/card/setting/update", "POST", request);
  return response && response.success;
};

export const deleteCard = async (apiCall, scopeId, canvasId, cardId) => {
  const request = { scopeId, canvasId, cardId };
  const response = await apiCall("/ui/card/delete", "POST", request);
  return response;
};

export const copyCard = async (apiCall, scopeId, canvasId, cardId) => {
  const request = { scopeId, canvasId, cardId };
  const response = await apiCall("/ui/card/copy", "POST", request);
  return response;
};

export const updateWidgetData = async (sendAndWaitWs, items) => {
  try {
    const resp = await sendAndWaitWs(
      "/ui/widget/update",
      JSON.stringify({
        items,
      })
    );
    if (resp.error) {
      console.log(resp.error);
      return null;
    }
    return resp;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const triggerWidgetEvent = async (sendAndWaitWs, widgetId, value) => {
  try {
    const resp = await sendAndWaitWs(
      "/ui/widget/button-trigger",
      JSON.stringify({
        widgetId,
        value,
      })
    );
    if (resp.error) {
      console.log(resp.error);
      return null;
    }
    return resp;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const listCanvas = async (apiCall, scopeId) => {
  return await apiCall(`/ui/canvas/list?scope=${scopeId}`, "GET");
};

export const upsertCanvas = async (apiCall, scopeId, canvas) => {
  const response = await apiCall(`/ui/canvas/upsert`, "POST", {
    scopeId,
    canvasId: canvas.id,
    name: canvas.name,
  });
  return response && response.success;
};

export const deleteCanvas = async (apiCall, scopeId, canvasId) => {
  const response = await apiCall(`/ui/canvas/delete`, "POST", {
    scopeId,
    canvasId,
  });
  return response && response.success;
};

export const reorderCanvas = async (apiCall, scopeId, newIndexes) => {
  const response = await apiCall(`/ui/canvas/reorder`, "POST", {
    scopeId,
    newIndexes,
  });

  return response && response.success;
};

export const copyCardToCanvas = async (
  apiCall,
  scopeId,
  toCanvasId,
  cardId,
  fromCanvasId
) => {
  try {
    const request = { scopeId, toCanvasId, cardId, fromCanvasId };
    const resp = await apiCall(`/ui/card/move`, "POST", request);
    return resp;
  } catch (error) {
    console.error("Failed to copy card to canvas", error);
    return { success: false };
  }
};
