import { Box, Typography } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import React, { useEffect, useState } from "react";
import { CirclePower } from "lucide-react";

const OnOff1Widget = ({
  widget,
  onValueUpdate,
  drag = false,
  edit = false,
}) => {
  const [isOn, setIsOn] = useState(false);

  const [v0, setV0] = useState();

  useEffect(() => {
    setV0(widget);
    if (widget?.items?.$w1) {
      if (widget.items.$w1.value === "on") {
        setIsOn(true);
      } else {
        setIsOn(false);
      }
    }
    return () => {};
  }, [widget]);

  const handleValueUpdate = (data) => {
    const updatedWidget = {
      ...v0.items.$w1,
      value: data ? "on" : "off",
    };
    onValueUpdate(updatedWidget);
  };

  const handleToggle = () => {
    if (drag || edit) {
      return;
    }
    const newValue = !isOn;
    setIsOn(newValue);
    handleValueUpdate(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleToggle();
      }}
    >
      <Box
        sx={{
          height: "100%",
          aspectRatio: "1/1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CirclePower
          size="100%"
          color={isOn ? "#FFD700" : "#B0B0B0"}
          fill={isOn ? "#FFD700" : "#B0B0B0"}
          fillOpacity={0.2}
          strokeWidth={2}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Box>
    </Box>
  );
};

export default OnOff1Widget;
