import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import DeleteIcon from "@mui/icons-material/Delete";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import CloseIcon from "@mui/icons-material/Close";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import LoadingSpinner from "../common/loading";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { useTheme } from "@mui/material/styles";
import { useToast } from "../common/toast";
import useApiCall from "../common/api-call";
import {
  listAutomations,
  runningAutomation,
  batchDelete,
  deleteAutomation,
} from "../automation/automation-service";
import dayjs from "dayjs";
import { customFormat } from "../common/custom-format";
import AutomationFilter from "../automation/automation-filter";
import CircularProgress from "@mui/material/CircularProgress";
import { useWebSocket } from "../ws/websocket";
import { useEventEmitter } from "../ws/event-context";

const TaskFlowListView = ({ showFilter = false, setShowFilter }) => {
  const history = useHistory();
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const { successToast, errorToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [automations, setAutomations] = useState([]);
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [sortBy, setSortBy] = useState("alphabet");
  const [filterText, setFilterText] = useState("");
  const { ws, sendAndWaitWs, sendAndForgetWs } = useWebSocket();
  const { subscribe } = useEventEmitter();
  const [selectedTags, setSelectedTags] = useState(["all"]);
  const [tags, setTags] = useState(["all"]);
  const lastStatusTimestampRef = useRef(0);
  const [isMultiSelectMode, setIsMultiSelectMode] = useState(false);
  const [selectedTaskFlows, setSelectedTaskFlows] = useState([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [isRunning, setIsRunning] = useState("");
  const [longPressedId, setLongPressedId] = useState(null);
  const [singleDeleteConfirmOpen, setSingleDeleteConfirmOpen] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    handleListTaskFlows();
  }, []);

  useEffect(() => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      handleListTaskFlows();
    }
  }, [ws, ws?.readyState]);

  useEffect(() => {
    const { unsubscribe } = subscribe((target, data) => {
      if (target === "/automation/history/update") {
        const payload = JSON.parse(data.payload);
        if (payload) {
          updateAutomation(payload);
        }
      } else if (target === "/automation/runningStatus/update") {
        const payload = JSON.parse(data.payload);

        if (payload && payload.timestamp > lastStatusTimestampRef.current) {
          lastStatusTimestampRef.current = payload.timestamp;
          updateAutomation(payload);
        }
      }
    });
    return () => {
      unsubscribe();
    };
  }, [automations]);

  useEffect(() => {
    const savedFilterParams = localStorage.getItem("filterParams-taskflow");
    if (savedFilterParams) {
      setShowFilter(true);
      const parsedParams = JSON.parse(savedFilterParams);
      if (parsedParams.filter) {
        setFilterText(parsedParams.filter);
      }
      if (parsedParams.sort) {
        setSortBy(parsedParams.sort);
      }

      if (parsedParams.tags) {
        const tagsArray = parsedParams.tags.split(",");
        setSelectedTags(
          tagsArray.length > 0 && tagsArray[0] !== "" ? tagsArray : ["all"]
        );
      }
      updateURL(parsedParams);
    } else {
      setFilterText("");
      setSortBy("alphabet");
      setSelectedTags(["all"]);
      updateURL("");
    }
  }, [showFilter]);

  const updateURL = (updates) => {
    if (!updates) {
      window.history.replaceState(null, "", "/taskflow/list");
      return;
    }
    const currentParams = new URLSearchParams(location.search);

    Object.entries(updates).forEach(([key, value]) => {
      if (
        value &&
        value !== "" &&
        !(Array.isArray(value) && value.length === 0)
      ) {
        currentParams.set(key, value);
      } else {
        currentParams.delete(key);
      }
    });

    const newURL = `${location.pathname}?${currentParams.toString()}`;
    window.history.replaceState(null, "", newURL);
  };

  const updateAutomation = (payload) => {
    console.log(payload);
    setAutomations((prevAutomations) => {
      const newAutomations = prevAutomations.map((automation) => {
        if (automation.id === payload.automationId) {
          return {
            ...automation,
            timestamp: payload.timestamp,
            running: payload.running || false,
          };
        }
        return automation;
      });
      return newAutomations;
    });
  };

  const sortedAutomations =
    automations
      .filter((a) => a.name.toLowerCase().includes(filterText.toLowerCase()))
      .sort((a, b) => {
        if (sortBy === "time") {
          const aTimestamp = a.timestamp
            ? dayjs(a.timestamp).valueOf()
            : Number.NEGATIVE_INFINITY;
          const bTimestamp = b.timestamp
            ? dayjs(b.timestamp).valueOf()
            : Number.NEGATIVE_INFINITY;
          return bTimestamp - aTimestamp;
        } else if (sortBy === "alphabet") {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();

          const aIsLetter = /^[a-zA-Z]/.test(aName);
          const bIsLetter = /^[a-zA-Z]/.test(bName);
          if (aIsLetter && !bIsLetter) return -1;
          if (!aIsLetter && bIsLetter) return 1;
          return aName.localeCompare(bName);
        } else if (sortBy === "opp-time") {
          const aTimestamp = a.timestamp
            ? dayjs(a.timestamp).valueOf()
            : Number.POSITIVE_INFINITY;
          const bTimestamp = b.timestamp
            ? dayjs(b.timestamp).valueOf()
            : Number.POSITIVE_INFINITY;
          return aTimestamp - bTimestamp;
        } else if (sortBy === "opp-alphabet") {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();

          const aIsLetter = /^[a-zA-Z]/.test(aName);
          const bIsLetter = /^[a-zA-Z]/.test(bName);
          if (aIsLetter && !bIsLetter) return 1;
          if (!aIsLetter && bIsLetter) return -1;
          return bName.localeCompare(aName);
        } else if (sortBy === "count") {
          const countA = a.totalCount ?? -1;
          const countB = b.totalCount ?? -1;

          return countB - countA;
        }
      }) || [];

  const handleListTaskFlows = async () => {
    setLoading(true);
    try {
      const resp = await listAutomations(
        apiCall,
        userInfo.activeScope.id,
        "standard",
        "task_flow"
      );
      if (resp && Array.isArray(resp.automations)) {
        const taskFlowAutomations = resp["automations"].filter(
          (automation) => automation.type === "TASK_FLOW"
        );

        const allTags = taskFlowAutomations.reduce(
          (acc, automation) => {
            if (Array.isArray(automation.tags) && automation.tags.length > 0) {
              automation.tags.forEach((tag) => {
                if (!acc.includes(tag)) {
                  acc.push(tag);
                }
              });
            }
            return acc;
          },
          ["all"]
        );

        setTags(allTags);
        setAutomations(taskFlowAutomations);
        setLoading(false);
      } else {
        setAutomations([]);
      }
    } catch (error) {
      console.error("Failed to fetch automations:", error);
      setAutomations([]);
    } finally {
      setLoading(false);
    }
  };

  const openOverview = (data) => {
    history.push(`/automation/overview?id=${data.id}`);
  };

  const handleStartTaskFlow = async (event, runningStatus, automationId) => {
    if (runningStatus) {
      const success = await runningAutomation(
        apiCall,
        userInfo.activeScope.id,
        automationId
      );
      if (success) {
        // pass
      } else {
        errorToast("Error starting the task flow.");
      }
    }
  };

  const handleTagsChange = (tags) => {
    setSelectedTags(tags);
  };

  let pressTimer;
  const handleMouseDown = (automationId) => {
    pressTimer = setTimeout(() => handleLongPress(automationId), 800);
  };

  const handleMouseUp = () => {
    clearTimeout(pressTimer);
  };

  const handleCheckboxChange = (e, automationId) => {
    if (e.target.checked) {
      setSelectedTaskFlows((prev) => [...prev, automationId]);
    } else {
      setSelectedTaskFlows((prev) => prev.filter((id) => id !== automationId));
    }
  };

  const handleSelectAll = () => {
    const totalAutomations = automations.length;
    const selectedCount = selectedTaskFlows.length;

    if (selectedCount === 0) {
      setSelectedTaskFlows(automations.map((automation) => automation.id));
    } else if (selectedCount === totalAutomations) {
      setSelectedTaskFlows([]);
    } else {
      const newSelectedTaskFlows = [...selectedTaskFlows];
      newSelectedTaskFlows.pop();
      setSelectedTaskFlows(newSelectedTaskFlows);
    }
  };

  const handleConfirmDelete = () => {
    setDeleteConfirmOpen(true);
  };

  const handleDeleteSelected = async () => {
    setIsRunning("DELETE");
    try {
      const resp = await batchDelete(
        apiCall,
        userInfo.activeScope.id,
        selectedTaskFlows,
        false
      );
      if (resp) {
        successToast("Success!");
        await handleListTaskFlows();
      }
    } catch (error) {
      errorToast("Error!");
    } finally {
      setIsMultiSelectMode(false);
      setSelectedTaskFlows([]);
      setIsRunning("");
      setDeleteConfirmOpen(false);
    }
  };

  const handleSingleDelete = async () => {
    setIsRunning("DELETE");
    try {
      if (deleteTargetId) {
        const resp = await deleteAutomation(
          apiCall,
          userInfo.activeScope.id,
          deleteTargetId,
          true
        );

        if (resp) {
          successToast("Successfully deleted!");
          await handleListTaskFlows();
        }
      } else errorToast("Failed to delete!");
    } catch (error) {
      errorToast("Failed to delete!");
    } finally {
      setIsRunning("");
      setLongPressedId(null);
      setSingleDeleteConfirmOpen(false);
    }
  };

  const handleCancelMultiSelect = () => {
    setIsMultiSelectMode(false);
    setSelectedTaskFlows([]);
  };

  const handleLongPress = (automationId) => {
    setLongPressedId(automationId);
  };

  const handlePressStart = (automationId) => {
    pressTimer = setTimeout(() => handleLongPress(automationId), 800);
  };

  const handlePressEnd = () => {
    clearTimeout(pressTimer);
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Box sx={{ flexGrow: 1, m: 2, pb: 12 }}>
          {showFilter && (
            <AutomationFilter
              sortBy={sortBy}
              setSortBy={setSortBy}
              setFilterText={setFilterText}
              onTagsChange={handleTagsChange}
              tags={tags}
              type="taskflow"
            />
          )}

          {isMultiSelectMode && (
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", p: 2, gap: 1 }}
            >
              <Checkbox
                checked={
                  selectedTaskFlows.length === automations.length &&
                  automations.length > 0
                }
                indeterminate={
                  selectedTaskFlows.length > 0 &&
                  selectedTaskFlows.length < automations.length
                }
                onChange={handleSelectAll}
              />
              <IconButton
                color="error"
                onClick={handleConfirmDelete}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
              <IconButton onClick={handleCancelMultiSelect}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}

          {automations.length !== 0 ? (
            <Grid
              container
              spacing={{ xs: 1, md: 2 }}
              columns={{ xs: 2, sm: 4, md: 12 }}
            >
              {sortedAutomations
                .filter((automation) => {
                  if (selectedTags.includes("all")) return true;
                  return (
                    automation.tags.filter((x) => selectedTags.includes(x))
                      .length === selectedTags.length
                  );
                })
                .map((automation, index) => (
                  <Grid item xs={2} sm={2} md={4} key={index}>
                    <Card
                      elevation={0}
                      onMouseDown={() => handleMouseDown(automation.id)}
                      onMouseUp={handleMouseUp}
                      onTouchStart={() => handlePressStart(automation.id)}
                      onTouchEnd={handlePressEnd}
                    >
                      <CardActionArea
                        onClick={() => {
                          if (
                            !isMultiSelectMode &&
                            longPressedId !== automation.id
                          ) {
                            openOverview(automation);
                          }
                        }}
                        style={{
                          backgroundColor: theme.palette.background.card,
                          position: "relative",
                        }}
                      >
                        <CardContent sx={{ padding: "8px" }}>
                          {isMultiSelectMode && (
                            <Checkbox
                              checked={selectedTaskFlows.includes(
                                automation.id
                              )}
                              onChange={(e) => {
                                e.stopPropagation();
                                handleCheckboxChange(e, automation.id);
                              }}
                            />
                          )}
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              gutterBottom
                              variant="body1"
                              component="div"
                              sx={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 3,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxHeight: "4.5em",
                                marginBottom: "0px",
                              }}
                            >
                              {automation.name}
                            </Typography>
                            {longPressedId === automation.id ? (
                              <Box sx={{ display: "flex", gap: 1 }}>
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setIsMultiSelectMode(true);
                                    setSelectedTaskFlows([automation.id]);
                                    setLongPressedId(null);
                                  }}
                                >
                                  <SelectAllIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  color="error"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleteTargetId(automation.id);
                                    setSingleDeleteConfirmOpen(true);
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setLongPressedId(null);
                                  }}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            ) : (
                              <>
                                {automation.runnable && automation.running ? (
                                  <CircularProgress size={24} />
                                ) : automation.runnable ? (
                                  <PlayCircleFilledWhiteIcon
                                    disabled={!automation.runnable}
                                    onClick={(e) => {
                                      handleStartTaskFlow(
                                        e,
                                        true,
                                        automation.id
                                      );
                                      e.stopPropagation();
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      color: theme.palette.background.button,
                                    }}
                                  />
                                ) : (
                                  <ErrorIcon
                                    style={{
                                      color: "red",
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </Box>
                          {automation.timestamp !== 0 && (
                            <Box>
                              <Typography
                                variant="caption"
                                component="div"
                                sx={{
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 3,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  marginTop: "5px",
                                  color: theme.palette.text.gray,
                                }}
                              >
                                {customFormat(
                                  dayjs(automation.timestamp),
                                  currentTime
                                )}
                              </Typography>
                            </Box>
                          )}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Typography variant="h6" color="textSecondary" sx={{ mb: 1 }}>
                No task flows
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{
                  whiteSpace: "pre-line",
                  width: "100%",
                }}
              >
                Use + button above to create new one
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{
                  whiteSpace: "pre-line",
                  width: "100%",
                }}
              >
                Or ask the chatbot
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete selected task flow(s)? This action
            cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button
            onClick={handleDeleteSelected}
            color="error"
            variant="contained"
            disabled={isRunning === "DELETE"}
          >
            Delete
            {isRunning === "DELETE" && (
              <CircularProgress size={16} sx={{ ml: 1 }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={singleDeleteConfirmOpen}
        onClose={() => setSingleDeleteConfirmOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this task flow? This action cannot
            be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSingleDeleteConfirmOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleSingleDelete}
            color="error"
            variant="contained"
            disabled={isRunning === "DELETE"}
          >
            Delete
            {isRunning === "DELETE" && (
              <CircularProgress size={16} sx={{ ml: 1 }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TaskFlowListView;
