import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  getIntegration,
  saveEntityTwin,
} from "../integration/integration-service";
import useApiCall from "../common/api-call";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import LoadingSpinner from "../common/loading";
import { useToast } from "../common/toast";
import CircularProgress from "@mui/material/CircularProgress";
import { useWebSocket } from "../ws/websocket";

const UpsertDeviceDialog = ({
  open,
  onClose,
  onBeforeSave,
  onSave,
  currentCanvasId = null,
}) => {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [devices, setDevices] = useState([]);
  const [twinTags, setTwinTags] = useState([]);

  const { apiCall } = useApiCall();
  const [userInfo] = useRecoilState(userInfoState);
  const [loading, setLoading] = useState(false);
  const { successToast, errorToast } = useToast();
  const [loadingSave, setLoadingSave] = useState(false);
  const { ws, sendAndWaitWs, sendAndForgetWs } = useWebSocket();
  const LAST_CANVAS_KEY = "last_selected_canvas";

  const [meta, setMeta] = useState([]);

  useEffect(() => {
    handleLoadIntegration();
  }, []);

  const handleLoadIntegration = async () => {
    setLoading(true);
    try {
      const resp = await getIntegration(
        apiCall,
        userInfo.activeScope.id,
        "basic.device"
      );
      if (resp && resp.entities) {
        setDevices(resp.entities);
        setMeta(resp.meta);
      } else {
        console.log("Fetch device error!");
      }
    } catch (error) {
      console.log("Error!");
    } finally {
      setLoading(false);
    }
  };

  const getTwinName = (twinTag) => {
    return meta.twinNames[twinTag] || twinTag;
  };

  const handleDeviceChange = (event, newValue) => {
    setSelectedDevice(newValue);

    if (newValue) {
      const selectedDeviceData = devices.find(
        (device) => device.name === newValue.name
      );
      if (selectedDeviceData) {
        setTwinTags(selectedDeviceData.twinTags || []);
        const initialOptions = {};
        selectedDeviceData.twinTags.forEach((tag) => {
          initialOptions[tag] = false;
        });
        setSelectedOptions(initialOptions);
      }
    } else {
      setTwinTags([]);
      setSelectedOptions({});
    }
  };

  const handleCheckboxChange = (event) => {
    setSelectedOptions({
      ...selectedOptions,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClose = () => {
    if (loadingSave) {
      return;
    }
    setSelectedDevice(null);
    setTwinTags([]);
    setSelectedOptions({});
    onClose();
  };

  const handleAdd = async () => {
    if (!selectedDevice) {
      return;
    }

    const hasSelectedTags = Object.values(selectedOptions).some(
      (value) => value === true
    );
    if (!hasSelectedTags) {
      return;
    }
    setLoadingSave(true);
    onBeforeSave();
    const selectedTwinTags = Object.entries(selectedOptions)
      .filter(([_, value]) => value === true)
      .map(([key]) => key);

    const resp = await saveEntityTwin(
      apiCall,
      userInfo.activeScope.id,
      currentCanvasId,
      selectedDevice.integration,
      selectedDevice.id,
      selectedDevice.name,
      selectedTwinTags,
      true
    );
    if (resp) {
      successToast("Success");
    } else {
      errorToast("Error");
    }
    setLoadingSave(false);
    handleClose();
    onSave();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>New Device Card</DialogTitle>
      <DialogContent>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Autocomplete
            sx={{ mt: 2 }}
            options={devices}
            getOptionLabel={(option) => option.name}
            value={selectedDevice}
            onChange={handleDeviceChange}
            renderInput={(params) => (
              <TextField {...params} label="Select Device" />
            )}
            ListboxProps={{
              style: {
                maxHeight: "250px",
                overflowX: "auto",
                "&::-webkit-scrollbar": {
                  height: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "4px",
                },
              },
            }}
            PaperProps={{
              sx: {
                "& .MuiAutocomplete-listbox": {
                  padding: 0,
                  "& .MuiAutocomplete-option": {
                    padding: "8px 10px",
                    whiteSpace: "nowrap",
                    minWidth: "fit-content",
                  },
                },
              },
            }}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{
                  ...props.style,
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #eee",
                }}
              >
                <span style={{ marginRight: "8px" }}>-</span>
                {option.name}
              </li>
            )}
          />
        )}

        {selectedDevice && (
          <FormGroup sx={{ mt: 3 }}>
            {twinTags.length > 0 ? (
              twinTags.map((tag) => (
                <FormControlLabel
                  key={tag}
                  control={
                    <Checkbox
                      checked={selectedOptions[tag] || false}
                      onChange={handleCheckboxChange}
                      name={tag}
                    />
                  }
                  label={getTwinName(tag)}
                />
              ))
            ) : (
              <div
                style={{
                  color: "#666",
                  padding: "8px 0",
                  fontStyle: "italic",
                }}
              >
                No twinTags
              </div>
            )}
          </FormGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          disabled={loadingSave}
        >
          Cancel
        </Button>
        <Button
          onClick={handleAdd}
          disabled={
            loadingSave ||
            !selectedDevice ||
            !Object.values(selectedOptions).some((value) => value === true)
          }
        >
          Add
          {loadingSave && (
            <CircularProgress
              size={20}
              sx={{
                ml: 1,
              }}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpsertDeviceDialog;
