import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  CircularProgress,
  Button,
  IconButton,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import dayjs from "dayjs";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import useApiCall from "../common/api-call";
import theme from "../theme";
import { useToast } from "../common/toast";
import { listSchedules } from "./schedule-service";
import { useHistory } from "react-router-dom";
import { InfoOutlined } from "@mui/icons-material";

const SchedulesListView = () => {
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userInfo] = useRecoilState(userInfoState);
  const { apiCall } = useApiCall();
  const { errorToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    fetchSchedules();
  }, []);

  const fetchSchedules = async () => {
    setLoading(true);
    const response = await listSchedules(apiCall, userInfo.activeScope.id);
    if (response) {
      setSchedules(response);
    }
    setLoading(false);
  };

  const formatDateTime = (timestamp) => {
    return dayjs(timestamp * 1000).format("YYYY-MM-DD HH:mm:ss");
  };

  const getTypeLabel = (type) => {
    return type;
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Button
          startIcon={<RefreshIcon sx={{ fontSize: 20, mr: 0.5 }} />}
          onClick={fetchSchedules}
          disabled={loading}
          sx={{
            textTransform: "none",
            px: 1,
            py: 0.5,
            minWidth: "auto",
          }}
        >
          Refresh
        </Button>
      </Box>

      {loading ? (
        <></>
      ) : (
        <>
          {schedules.length > 0 ? (
            schedules.map((schedule, index) => (
              <Card
                key={index}
                sx={{
                  mb: 1.5,
                  p: 1.5,
                  backgroundColor: theme.palette.background.white,
                  boxShadow: "none",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        mr: 1,
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        lineHeight: 1.2,
                        fontSize: "0.95rem",
                        fontWeight: 500,
                      }}
                    >
                      {schedule.automationName}
                    </Typography>
                    <IconButton
                      size="small"
                      sx={{ mt: -0.3 }}
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          `/automation/overview?id=${schedule.automationId}`
                        );
                      }}
                    >
                      <InfoOutlined fontSize="small" />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {getTypeLabel(schedule.type)}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {formatDateTime(schedule.timestamp)}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            ))
          ) : (
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ textAlign: "center", mt: 3 }}
            >
              No schedules available.
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default SchedulesListView;
