import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const NumberInput = ({ valueSpec, onChange, disabled }) => {
  const [sValue, setSValue] = useState(null);
  const [tValue, setTValue] = useState("");
  const isReadOnly = valueSpec.readOnly;

  useEffect(() => {
    setSValue(Number(valueSpec.value));
    setTValue(Number(valueSpec.value));
  }, [valueSpec]);

  const step = valueSpec.step || 1;

  const handleSliderChange = (e, v) => {
    setSValue(v);
    setTValue(v);
    onChange(v.toString());
  };

  const handleNumberInputChange = (event) => {
    if (disabled) return;
    if (event.target.value === "") {
      setSValue(0);
      setTValue("");
    } else {
      const newValue = Number(event.target.value);
      setSValue(newValue);
      setTValue(newValue);
      onChange(newValue.toString());
    }
  };

  const handleIncrement = () => {
    if (disabled) return;
    const newValue = sValue + step;
    if (
      valueSpec.max === null ||
      valueSpec.max === undefined ||
      newValue <= valueSpec.max
    ) {
      setSValue(newValue);
      setTValue(newValue);
      onChange(newValue.toString());
    }
  };

  const handleDecrement = () => {
    const newValue = sValue - step;
    if (
      valueSpec.min === null ||
      valueSpec.min === undefined ||
      newValue >= valueSpec.min
    ) {
      setSValue(newValue);
      setTValue(newValue);
      onChange(newValue.toString());
    }
  };

  const isOutOfRange =
    valueSpec.min !== null &&
    valueSpec.min !== undefined &&
    valueSpec.max !== null &&
    valueSpec.max !== undefined &&
    (sValue < valueSpec.min || sValue > valueSpec.max);

  const hasSlider =
    valueSpec.max !== null &&
    valueSpec.max !== undefined &&
    valueSpec.min !== null &&
    valueSpec.min !== undefined;

  const compactTextFieldStyles = {
    "& .MuiInputBase-root": {
      minHeight: "28px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "4px 8px",
      textAlign: "center",
      minHeight: "28px",
    },
    width: hasSlider ? "60px" : "120px",
    "& input": {
      textAlign: "center",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: isOutOfRange ? "red" : "default",
      },
    },
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          flex: 1,
          gap: 1,
        }}
      >
        {hasSlider ? (
          <>
            <Box sx={{ flex: 1, minWidth: 0 }}>
              <Slider
                value={sValue}
                onChange={handleSliderChange}
                min={valueSpec.min}
                max={valueSpec.max}
                step={step}
                disabled={disabled || isReadOnly}
                sx={{
                  height: 4,
                  "& .MuiSlider-thumb": {
                    width: 12,
                    height: 12,
                    cursor: isReadOnly ? "default" : "grab",
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: isReadOnly
                        ? "none"
                        : "0px 0px 0px 8px rgba(0,0,0,0.16)",
                    },
                    "&.Mui-active": {
                      boxShadow: isReadOnly
                        ? "none"
                        : "0px 0px 0px 14px rgba(0,0,0,0.16)",
                    },
                  },
                  "& .MuiSlider-track": {
                    height: 4,
                  },
                  "& .MuiSlider-rail": {
                    height: 4,
                  },
                }}
              />
            </Box>
            <TextField
              type="number"
              value={tValue}
              onChange={handleNumberInputChange}
              sx={compactTextFieldStyles}
              inputProps={{
                min: valueSpec.min,
                max: valueSpec.max,
                step: step,
                readOnly: isReadOnly,
              }}
              error={isOutOfRange}
            />
          </>
        ) : (
          <>
            <Button
              onClick={handleDecrement}
              variant="outlined"
              disabled={disabled || isReadOnly}
              sx={{
                minWidth: "36px",
                width: "36px",
                padding: 0,
                minHeight: "28px",
                fontSize: "0.875rem",
                opacity: isReadOnly ? 0.7 : 1,
                cursor: isReadOnly ? "default" : "pointer",
              }}
            >
              -
            </Button>
            <TextField
              type="number"
              value={tValue}
              onChange={handleNumberInputChange}
              sx={compactTextFieldStyles}
              inputProps={{
                min: valueSpec.min,
                max: valueSpec.max,
                step: step,
                readOnly: isReadOnly,
              }}
              error={isOutOfRange}
            />
            <Button
              onClick={handleIncrement}
              disabled={disabled || isReadOnly}
              variant="outlined"
              sx={{
                minWidth: "36px",
                width: "36px",
                padding: 0,
                minHeight: "28px",
                fontSize: "0.875rem",
                opacity: isReadOnly ? 0.7 : 1,
                cursor: isReadOnly ? "default" : "pointer",
              }}
            >
              +
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default NumberInput;
