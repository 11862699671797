import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import TimestampInput from "../../input/timestamp-input";

const DateTime1Widget = ({ widget, onValueUpdate }) => {
  const [v0, setV0] = useState(null);

  useEffect(() => {
    setV0(widget);
    return () => {};
  }, [widget]);

  const handleValueUpdate = (data) => {
    console.log(data);
    const updatedWidget = { ...v0, value: data };
    onValueUpdate(updatedWidget);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "stretch",
        height: "100%",
        width: "100%",
        padding: "2px",
      }}
    >
      {v0 && <TimestampInput valueSpec={v0} onChange={handleValueUpdate} />}
    </Box>
  );
};

export default DateTime1Widget;
