import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddIcon from "@mui/icons-material/Add";
import {
  listCanvas,
  upsertCanvas,
  deleteCanvas,
  reorderCanvas,
} from "./dashboard-service";
import useApiCall from "../common/api-call";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import { useToast } from "../common/toast";
import LoadingSpinner from "../common/loading";

export default function CanvasManage() {
  const [canvasList, setCanvasList] = useState([]);
  const { apiCall } = useApiCall();
  const { successToast, errorToast } = useToast();
  const [userInfo] = useRecoilState(userInfoState);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [selectedCanvas, setSelectedCanvas] = useState(null);
  const [editName, setEditName] = useState("");
  const [newCanvasName, setNewCanvasName] = useState("");
  const [isRunning, setIsRunning] = useState("");
  const [loading, setLoading] = useState("");

  useEffect(() => {
    handleListCanvas();
  }, []);

  const handleListCanvas = async () => {
    setLoading(true);
    try {
      const resp = await listCanvas(apiCall, userInfo.activeScope.id);
      if (resp.canvas) {
        setCanvasList(resp.canvas);
        console.log(resp.canvas);
      } else {
        errorToast("Failed to get canvas list");
      }
    } catch (error) {
      errorToast("Error loading canvas list");
      console.error("List canvas error:", error);
    }
    setLoading(false);
  };

  const handleCreateCanvas = async () => {
    if (!newCanvasName.trim()) {
      errorToast("Canvas name cannot be empty");
      return;
    }
    setIsRunning("create");

    try {
      const response = await upsertCanvas(apiCall, userInfo.activeScope.id, {
        name: newCanvasName.trim(),
      });

      if (response) {
        successToast("Canvas created successfully");
        handleListCanvas();
      } else {
        errorToast("Failed to create canvas");
      }
    } catch (error) {
      errorToast("Error creating canvas");
      console.error("Create canvas error:", error);
    }
    setCreateDialogOpen(false);
    setNewCanvasName("");
    setIsRunning("");
  };

  const handleSave = async () => {
    if (!editName.trim()) {
      errorToast("Canvas name cannot be empty");
      return;
    }
    setIsRunning("save");

    try {
      const response = await upsertCanvas(apiCall, userInfo.activeScope.id, {
        id: selectedCanvas.id,
        name: editName.trim(),
      });

      if (response) {
        successToast("Canvas updated successfully");
        handleListCanvas();
      } else {
        errorToast("Failed to update canvas");
      }
    } catch (error) {
      errorToast("Error updating canvas");
      console.error("Update canvas error:", error);
    }
    setIsRunning("");
    setEditDialogOpen(false);
  };

  const confirmDelete = async () => {
    setIsRunning("delete");
    try {
      const response = await deleteCanvas(
        apiCall,
        userInfo.activeScope.id,
        selectedCanvas.id
      );

      if (response) {
        successToast("Canvas deleted successfully");
        handleListCanvas();
      } else {
        errorToast("Failed to delete canvas");
      }
    } catch (error) {
      errorToast("Error deleting canvas");
      console.error("Delete canvas error:", error);
    }
    setIsRunning("");
    setDeleteDialogOpen(false);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(canvasList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCanvasList(items);

    try {
      const newIndexes = {};
      items.forEach((canvas, index) => {
        newIndexes[canvas.id] = index;
      });

      const response = await reorderCanvas(
        apiCall,
        userInfo.activeScope.id,
        newIndexes
      );

      if (!response) {
        errorToast("Failed to save canvas order");
        handleListCanvas();
      }
    } catch (error) {
      errorToast("Error saving canvas order");
      console.error("Reorder canvas error:", error);
      handleListCanvas();
    }
  };

  const handleEdit = (canvas) => {
    setSelectedCanvas(canvas);
    setEditName(canvas.name);
    setEditDialogOpen(true);
  };

  const handleDelete = (canvas) => {
    setSelectedCanvas(canvas);
    setDeleteDialogOpen(true);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box sx={{ p: 3 }}>
          <Box sx={{ mb: 3 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setCreateDialogOpen(true)}
            >
              New Canvas
            </Button>
          </Box>

          <DragDropContext
            onDragEnd={(result) => {
              onDragEnd(result);
              // Restore scrolling when drag ends
              document.body.style.overflow = "";
            }}
            enableDefaultSensors={true}
            onDragStart={() => {
              // Only prevent scrolling when actually dragging
              document.body.style.overflow = "hidden";
            }}
          >
            <Droppable droppableId="canvas-list" mode="standard">
              {(provided) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                >
                  {canvasList.length > 0 ? (
                    canvasList.map((canvas, index) => (
                      <Draggable
                        key={canvas.id}
                        draggableId={canvas.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            elevation={snapshot.isDragging ? 5 : 1}
                            sx={{
                              p: 2,
                              display: "flex",
                              alignItems: "center",
                              bgcolor: snapshot.isDragging
                                ? "action.hover"
                                : "background.paper",
                              "&:hover": {
                                bgcolor: "action.hover",
                              },
                              cursor: "grab",
                              "&:active": {
                                cursor: "grabbing",
                              },
                            }}
                          >
                            <Typography variant="body1" sx={{ flex: 1 }}>
                              {canvas.name}
                            </Typography>

                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                size="small"
                                onClick={() => handleEdit(canvas)}
                              >
                                <EditIcon />
                              </IconButton>
                              {!canvas.hasCards && (
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(canvas);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                              <DragIndicatorIcon />
                            </Box>
                          </Paper>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <Typography>No canvases found</Typography>
                  )}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>

          <Dialog
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Edit Canvas</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Canvas Name"
                fullWidth
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
                sx={{ mt: 1 }}
              />
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
              <Button variant="contained" onClick={handleSave}>
                Save
                {isRunning === "save" && (
                  <CircularProgress size={20} sx={{ ml: 1 }} />
                )}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Delete Canvas</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete {selectedCanvas?.name}?
              </Typography>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
              <Button color="error" variant="contained" onClick={confirmDelete}>
                Delete
                {isRunning === "delete" && (
                  <CircularProgress size={20} sx={{ ml: 1 }} />
                )}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={createDialogOpen}
            onClose={() => setCreateDialogOpen(false)}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Create New Canvas</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Canvas Name"
                fullWidth
                value={newCanvasName}
                onChange={(e) => setNewCanvasName(e.target.value)}
                sx={{ mt: 1 }}
              />
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button onClick={() => setCreateDialogOpen(false)}>Cancel</Button>
              <Button
                variant="contained"
                onClick={handleCreateCanvas}
                disabled={!newCanvasName.trim()}
              >
                Create
                {isRunning === "create" && (
                  <CircularProgress size={20} sx={{ ml: 1 }} />
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
}
