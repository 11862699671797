import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import DateInput from "../../input/date-input";

const Date1Widget = ({ widget, onValueUpdate, drag, edit = false }) => {
  const [v0, setV0] = useState(null);

  useEffect(() => {
    setV0(widget);
    return () => {};
  }, [widget]);

  const handleValueUpdate = (data) => {
    if (drag || edit) {
      return;
    }
    const updatedWidget = { ...v0, value: data };
    onValueUpdate(updatedWidget);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "stretch",
        height: "100%",
        width: "100%",
      }}
    >
      {v0 && <DateInput valueSpec={v0} onChange={handleValueUpdate} />}
    </Box>
  );
};

export default Date1Widget;
