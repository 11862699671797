import { Box, Typography } from "@mui/material";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useTheme } from "@mui/material/styles";
import debounce from "lodash/debounce";
import { triggerWidgetEvent, updateWidgetData } from "./dashboard-service";
import { useWebSocket } from "../ws/websocket";
import { useToast } from "../common/toast";
import Button1Widget from "./widget/button-1";
import { prepareWidget } from "./widget/widget-util";
import { getDataWidgetComponent } from "./widget-registry";
import Title1Widget from "./widget/title-1";
import AutomationTitle1Widget from "./widget/automation-title1";

const ResponsiveGridLayout = WidthProvider(Responsive);

// Create a memoized widget component
const MemoizedWidget = React.memo(
  ({ widget, onValueUpdate, onStateUpdate, onTriggerEvent }) => {
    const DataWidgetComponent = getDataWidgetComponent(widget.skin);

    switch (widget.skin) {
      case "default.title":
      case "default.title1":
        return <Title1Widget widget={widget} onValueUpdate={onValueUpdate} />;
      case "default.automationtitle":
        return (
          <AutomationTitle1Widget
            widget={widget}
            onStateUpdate={onStateUpdate}
          />
        );
      case "default.button":
        return <Button1Widget widget={widget} onValueUpdate={onTriggerEvent} />;
      default:
        if (DataWidgetComponent) {
          return (
            <DataWidgetComponent
              widget={widget}
              onValueUpdate={onValueUpdate}
            />
          );
        } else {
          return <div>Unknown skin: {widget.skin}</div>;
        }
    }
  },
  (prevProps, nextProps) => {
    // Only compare if the widget object is the same reference
    return prevProps.widget === nextProps.widget;
  }
);

MemoizedWidget.displayName = "MemoizedWidget";

const DashboardCard = React.memo(
  ({ card, drag = false, onLayoutChange, onStateUpdate }) => {
    const { ws, sendAndWaitWs, sendAndForgetWs } = useWebSocket();
    const { successToast, errorToast } = useToast();
    const [width, setWidth] = useState(1);
    const [layouts, setLayouts] = useState(null);

    // Prepare widgets once when card changes
    const preparedWidgets = useMemo(() => {
      return card.widgets.map((widget) => {
        prepareWidget(widget);
        return widget;
      });
    }, [card.widgets]);

    const handleLayoutChange = (layout) => {
      if (drag && onLayoutChange) {
        const updatedLayouts = layout.map((item) => ({
          id: item.i,
          layout: {
            x: item.x,
            y: item.y,
            w: item.w,
            h: item.h,
          },
        }));
        onLayoutChange(updatedLayouts);
      }
    };

    const debouncedUpdate = useCallback(
      debounce(async (widgetItems) => {
        try {
          const resp = await updateWidgetData(sendAndWaitWs, widgetItems);
          console.log("resp", resp);
          if (!resp) {
            errorToast("Error!");
          }
        } catch (error) {
          console.error("Failed to update widget:", error);
          errorToast("Error!");
        }
      }, 100),
      [sendAndWaitWs]
    );

    useEffect(() => {
      return () => {
        debouncedUpdate.cancel();
      };
    }, [debouncedUpdate]);

    const handleUiWidgetValueChange = useCallback(
      async (widgetItem) => {
        const widgetItems = Array.isArray(widgetItem)
          ? widgetItem
          : [widgetItem];
        debouncedUpdate(widgetItems);
      },
      [debouncedUpdate]
    );

    const handleTriggerWidgetEvent = useCallback(
      async (updateData) => {
        const resp = await triggerWidgetEvent(
          sendAndWaitWs,
          updateData.id,
          updateData.value
        );
        if (!resp) {
          errorToast("Error!");
        }
      },
      [sendAndWaitWs]
    );

    useEffect(() => {
      const w = card.layout?.w
        ? card.layout.w * 4
        : Math.max(...card.widgets.map((widget) => widget.layout.w));
      setWidth(w);
      const lo = card.widgets.map((w) => {
        return {
          i: w.id,
          x: w.layout.x,
          y: w.layout.y,
          w: w.layout.w,
          h: w.layout.h,
        };
      });
      setLayouts(lo);
    }, [card]);

    // Memoize the rendered widgets
    const renderedWidgets = useMemo(() => {
      return preparedWidgets.map((widget) => (
        <Box
          key={widget.id}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              width: "100%",
              padding: "2px",
              pointerEvents: drag ? "none" : "inherit",
            }}
          >
            <MemoizedWidget
              widget={widget}
              onValueUpdate={handleUiWidgetValueChange}
              onStateUpdate={onStateUpdate}
              onTriggerEvent={handleTriggerWidgetEvent}
            />
          </Box>
        </Box>
      ));
    }, [
      preparedWidgets,
      handleUiWidgetValueChange,
      handleTriggerWidgetEvent,
      onStateUpdate,
      drag,
    ]);

    return (
      <>
        {layouts && (
          <ResponsiveGridLayout
            className="layout"
            layouts={{ xxs: layouts }}
            breakpoints={{ xxs: 0 }}
            cols={{ xxs: width }}
            rowHeight={8}
            compactType={null}
            preventCollision={true}
            margin={[0, 0]}
            isDraggable={drag}
            isResizable={drag}
            onLayoutChange={handleLayoutChange}
            useCSSTransforms={true}
            style={{
              userSelect: "none",
              WebkitUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
            }}
          >
            {renderedWidgets}
          </ResponsiveGridLayout>
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.card === nextProps.card &&
      prevProps.isEditTitle === nextProps.isEditTitle &&
      prevProps.drag === nextProps.drag
    );
  }
);

export default DashboardCard;

DashboardCard.displayName = "DashboardCard";
