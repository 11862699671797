// theme.js
import { createTheme } from "@mui/material/styles";
import { createContext, useContext, useState, useMemo } from "react";

export const ThemeContext = createContext(null);

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#475B63",
    },
    background: {
      default: "#EAEEF0",
      white: "#FFFFFF",
      disabled: "#BDBDBD",
      button: "#475B63",
      nav: "#D3E1E8",
      card: "#FFFFFF",
      dialog: "#d19191",
      notification: "#EAEEF0",
      input: "#FFFFFF",
      dataGrid: "#FFFFFF",
      log2: "linear-gradient(135deg, rgba(30, 90, 180, 0.6) 0%, rgba(0, 150, 200, 0.6) 100%)",
      logo1: "#475B63",
      logo2: "#8fbdcf",
      l1: "#EAEEF0",
      l2: "#9fe3a8",
      l3: "#e1c9a8",
      l4: "#d19191",
      l5: "#475B63",
      l6: "#8fbdcf",
      l0: "#FFFFFF",
      c1: "#9fe3a8",
      c2: "#e1c9a8",
      c3: "#d19191",
      c0: "#FFFFFF",
    },
    icon: {
      y1: "#FFD700",
      y1_1: "#B0B0B0",
    },
    text: {
      primary: "#333333",
      secondary: "#757575",
      disabled: "#9E9E9E",
      warning: "#FFD700",
      error: "#ed7e7e",
      l1: "#475B63",
      l2: "#e1c9a8",
      l4: "#d19191",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#8fbdcf",
    },
    background: {
      default: "#1a1a1a",
      white: "#2d2d2d",
      disabled: "#404040",
      button: "#8fbdcf",
      nav: "#2d3943",
      card: "#2d2d2d",
      dialog: "#3d2828",
      notification: "#2d2d2d",
      input: "#2d2d2d",
      dataGrid: "#2d2d2d",
      log2: "linear-gradient(135deg, rgba(30, 90, 180, 0.8) 0%, rgba(0, 150, 200, 0.8) 100%)",
      logo1: "#8fbdcf",
      logo2: "#475B63",
      l1: "#EAEEF0",
      l2: "#9fe3a8",
      l3: "#e1c9a8",
      l4: "#d19191",
      l5: "#475B63",
      l6: "#8fbdcf",
      l0: "#FFFFFF",
      c1: "#9fe3a8",
      c2: "#e1c9a8",
      c3: "#d19191",
      c0: "#FFFFFF",
    },
    icon: {
      y1: "#FFD700",
      y1_1: "#B0B0B0",
    },
    text: {
      primary: "#ffffff",
      secondary: "#bdbdbd",
      disabled: "#707070",
      warning: "#FFD700",
      error: "#ed7e7e",
      l1: "#475B63",
      l2: "#e1c9a8",
      l4: "#d19191",
    },
  },
});

export const CustomThemeProvider = ({ children }) => {
  const [mode, setMode] = useState(() => {
    try {
      return localStorage.getItem("themeMode") || "light";
    } catch {
      return "light";
    }
  });

  const theme = useMemo(() => {
    return mode === "dark" ? darkTheme : lightTheme;
  }, [mode]);

  const toggleTheme = () => {
    setMode((prevMode) => {
      const newMode = prevMode === "light" ? "dark" : "light";
      localStorage.setItem("themeMode", newMode);
      return newMode;
    });
  };

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme, theme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useCustomTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useCustomTheme must be used within a CustomThemeProvider");
  }
  return context;
};

export default lightTheme;
