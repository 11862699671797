import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const ColorTemperatureDialog = ({
  open = false,
  setOpen,
  colorTemp,
  onClose,
  onColorTempChange,
  minTemp = 2700,
  maxTemp = 6500,
}) => {
  const [currentTemp, setCurrentTemp] = useState(colorTemp);
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef(null);

  useEffect(() => {
    setCurrentTemp(colorTemp);
  }, [colorTemp]);

  useEffect(() => {
    const sliderElement = sliderRef.current;
    if (!sliderElement || !open) return;

    const touchStartHandler = (e) => handleTouchStart(e);

    sliderElement.addEventListener("touchstart", touchStartHandler, {
      passive: false,
    });

    return () => {
      sliderElement.removeEventListener("touchstart", touchStartHandler);
    };
  }, [open]);

  const handleDialogClose = () => {
    setOpen(false);
  };

  const colorTemperatureToRGB = (kelvin) => {
    const temp = kelvin / 100;
    let red, green, blue;

    if (temp <= 66) {
      red = 255;
      green = temp;
      green = 99.4708025861 * Math.log(green) - 161.1195681661;
      if (temp <= 19) {
        blue = 0;
      } else {
        blue = temp - 10;
        blue = 138.5177312231 * Math.log(blue) - 305.0447927307;
      }
    } else {
      red = temp - 60;
      red = 329.698727446 * Math.pow(red, -0.1332047592);
      green = temp - 60;
      green = 288.1221695283 * Math.pow(green, -0.0755148492);
      blue = 255;
    }

    return {
      r: Math.min(255, Math.max(0, red)),
      g: Math.min(255, Math.max(0, green)),
      b: Math.min(255, Math.max(0, blue)),
    };
  };

  const gradientStops = [];
  const steps = 10;

  for (let i = 0; i <= steps; i++) {
    const temp = minTemp + (i / steps) * (maxTemp - minTemp);
    const rgb = colorTemperatureToRGB(temp);
    gradientStops.push(`rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`);
  }

  const gradientStyle = {
    background: `linear-gradient(to right, ${gradientStops.join(", ")})`,
  };

  const calculateTemperature = (clientX) => {
    const rect = sliderRef.current.getBoundingClientRect();
    const percentage = (clientX - rect.left) / rect.width;
    const clampedPercentage = Math.max(0, Math.min(1, percentage));
    return Math.round(minTemp + clampedPercentage * (maxTemp - minTemp));
  };

  const handleInteraction = (clientX) => {
    const newTemp = calculateTemperature(clientX);
    if (newTemp !== currentTemp) {
      setCurrentTemp(newTemp);
      const syntheticEvent = {
        preventDefault: () => {},
        stopPropagation: () => {},
        target: {
          value: newTemp,
        },
      };
      onColorTempChange && onColorTempChange(syntheticEvent, newTemp);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    handleInteraction(e.clientX);

    const handleMouseMove = (e) => {
      e.preventDefault();
      requestAnimationFrame(() => {
        handleInteraction(e.clientX);
      });
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleTouchStart = (e) => {
    if (e.cancelable) {
      e.preventDefault();
    }
    setIsDragging(true);
    handleInteraction(e.touches[0].clientX);

    const handleTouchMove = (e) => {
      if (e.cancelable) {
        e.preventDefault();
      }
      requestAnimationFrame(() => {
        handleInteraction(e.touches[0].clientX);
      });
    };

    const handleTouchEnd = () => {
      setIsDragging(false);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };

    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    document.addEventListener("touchend", handleTouchEnd);
  };

  const thumbPosition = ((currentTemp - minTemp) / (maxTemp - minTemp)) * 100;

  return (
    <Dialog
      open={open}
      onClose={() => handleDialogClose()}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: "320px",
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            mt: 2,
            mb: 2,
          }}
        >
          {currentTemp}K
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleDialogClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "2",
          userSelect: "none",
          overflow: "hidden",
          overflowY: "hidden",
          overscrollBehavior: "none",
          mb: 2,
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "calc(100% + 16px)",
              left: `${thumbPosition}%`,
              transform: "translateX(-50%)",
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              borderRadius: "4px",
              padding: "4px 8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              color: "black",
              fontSize: "14px",
              fontWeight: "bold",
              minWidth: "60px",
              textAlign: "center",
              opacity: isDragging ? 1 : 0,
              transition: "opacity 0.2s",
              zIndex: 1,
              pointerEvents: "none",
            }}
          >
            {currentTemp}K
          </Box>

          <Box
            ref={sliderRef}
            sx={{
              position: "relative",
              width: "100%",
              height: "50px",
              borderRadius: "10px",
              cursor: "pointer",
              WebkitTapHighlightColor: "transparent",
              WebkitTouchCallout: "none",
              WebkitUserSelect: "none",
              userSelect: "none",
              outline: "none",
              touchAction: "none",
              "&:active": {
                backgroundColor: "transparent",
              },
              ...gradientStyle,
            }}
            onMouseDown={handleMouseDown}
          >
            <Box
              sx={{
                position: "absolute",
                width: "4px",
                height: "30px",
                backgroundColor: "#ffffff",
                boxShadow: "0 0 4px rgba(0, 0, 0, 0.3)",
                borderRadius: "1px",
                top: "50%",
                transform: "translate(-50%, -50%)",
                left: `${thumbPosition}%`,
                pointerEvents: "none",
              }}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ColorTemperatureDialog;
