import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import NumberUnitInput from "../../input/number-unit-input";

const NumberUnit1Widget = ({ widget, onValueUpdate }) => {
  const [v0, setV0] = useState(null);

  useEffect(() => {
    setV0(widget);
    return () => {};
  }, [widget]);

  const handleValueUpdate = (value, unit, max, min) => {
    console.log(value, unit, max, min);
    if (min !== null && value < min) {
      return;
    }
    if (max !== null && value > max) {
      return;
    }
    const updatedWidget = {
      ...v0,
      value: value.toString() + unit,
      unit,
      max,
      min,
    };
    onValueUpdate(updatedWidget);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        width: "100%",
        padding: "2px",
      }}
    >
      {v0 && <NumberUnitInput valueSpec={v0} onChange={handleValueUpdate} />}
    </Box>
  );
};

export default NumberUnit1Widget;
