import React, { useCallback, useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import debounce from "lodash/debounce";
import Grid from "@mui/material/Grid";
import { searchableSelectCheck } from "./searchable-select-check";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { toEntityTitleSubtitle } from "../common/string-helpers";

/**
 * {value: string, enums: Array<string|object>}
 */
const SearchableSelectInput = ({
  valueSpec,
  onChange,
  onError,
  multiSelect = false,
  fetchValueSpec,
  listPosition = "auto",
}) => {
  const [open, setOpen] = useState(false);
  const [specData, setSpecData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState("");
  const [selectedValue, setSelectedValue] = useState(multiSelect ? [] : "");
  const [error, setError] = useState(false);
  const titleBreak = useRef(true);
  const theme = useTheme();
  const isReadOnly = valueSpec.readOnly;

  useEffect(() => {
    console.log(valueSpec);
    if (valueSpec) {
      setLoading(true);
      // Find the corresponding object from enums if value is a string
      let initialValue = valueSpec.value;
      if (valueSpec.titleBreak) {
        initialValue = toEntityTitleSubtitle(
          valueSpec.value,
          valueSpec.titleBreak
        );
      }
      console.log(valueSpec.value, initialValue);
      setSelectedValue(initialValue);
      setSpecData(valueSpec);
      titleBreak.current = valueSpec.titleBreak || false;
      refreshValueSpec(inputText);
      const err = checkValue(valueSpec.value, valueSpec);
      if (err) {
        setError(true);
        onError(true);
      }
    }
    return () => {};
  }, [valueSpec]);

  const debouncedRefreshValueSpec = useCallback(
    debounce((currentInput) => {
      refreshValueSpec(currentInput);
    }, 1000),
    []
  );

  const handleInputChange = (event, newValue) => {
    if (isReadOnly) return;
    setInputText(newValue);
    if (fetchValueSpec) {
      debouncedRefreshValueSpec(newValue);
    }
  };

  const refreshValueSpec = (inputText) => {
    setLoading(true);
    if (fetchValueSpec) {
      console.log(inputText);
      fetchValueSpec(inputText)
        .then((vs) => {
          const input = vs.value;
          if (input === inputText) {
            // Convert enums to objects if they're strings
            if (Array.isArray(vs.valueSpec.enums)) {
              vs.valueSpec.enums = vs.valueSpec.enums.map((e) => {
                return toEntityTitleSubtitle(e, titleBreak.current);
              });
            }
            setSpecData(vs.valueSpec);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching options:", error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const handleSelectChange = (event, newValue) => {
    if (isReadOnly) return;
    const err = checkValue(newValue, specData);
    setSelectedValue(newValue);
    setError(err);
    if (newValue && typeof newValue === "object" && newValue.value) {
      onChange(newValue.value);
    } else {
      onChange(newValue);
    }
    onError(err);
  };

  const handleBlur = (event, newVal) => {
    if (isReadOnly) return;

    // Check if the input text matches any option
    const isValidOption = specData?.enums?.some((option) => {
      if (typeof option === "object") {
        return option.title === inputText || option.value === inputText;
      }
      return option === inputText;
    });

    if (!isValidOption) {
      setError(true);
      onError(true);
    } else {
      setError(false);
      onError(false);
    }
  };

  const checkValue = (value, spec) => {
    if (!value) return false;

    if (multiSelect) {
      for (let i = 0; i < value?.length; i++) {
        const val = typeof value[i] === "object" ? value[i].value : value[i];
        const err = !searchableSelectCheck(val, spec);
        if (err) {
          return err;
        }
      }
      return false;
    } else {
      const val = typeof value === "object" ? value.value : value;
      const err = !searchableSelectCheck(val, spec);
      return err;
    }
  };

  const getOptionLabel = (option) => {
    if (typeof option === "object" && option.title) {
      return option.title;
    }
    return option;
  };

  const renderOption = (props, option) => {
    console.log(option);
    if (typeof option === "object" && option.value) {
      return (
        <Box component="li" {...props} key={`${option.title}-${option.value}`}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1">
              {option.title || option.value}
            </Typography>
            {option.subtitle && (
              <Typography variant="body2" color="text.secondary">
                {option.subtitle}
              </Typography>
            )}
          </Box>
        </Box>
      );
    }
    return (
      <Box component="li" {...props} key={option}>
        {option}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        pt: "8px",
      }}
    >
      {valueSpec && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: 1,
          }}
        >
          {valueSpec.name && (
            <Typography
              variant="h6"
              sx={{
                flexShrink: 0, // Prevent typography from shrinking
                mb: 1,
              }}
            >
              {valueSpec.name}
            </Typography>
          )}
          <Box
            sx={{
              flex: 1, // Take remaining space
              minHeight: 0, // Allow container to shrink
              position: "relative",
            }}
          >
            <Autocomplete
              multiple={multiSelect ?? false}
              open={isReadOnly ? false : open}
              onOpen={() => !isReadOnly && setOpen(true)}
              onClose={() => setOpen(false)}
              options={Array.isArray(specData?.enums) ? specData.enums : []}
              loading={loading}
              inputValue={
                !open
                  ? selectedValue &&
                    typeof selectedValue === "object" &&
                    selectedValue.title
                    ? selectedValue.title
                    : inputText
                  : inputText
              }
              onInputChange={handleInputChange}
              onChange={handleSelectChange}
              onBlur={handleBlur}
              value={selectedValue ?? (multiSelect ? [] : null)}
              readOnly={isReadOnly}
              disableClearable={isReadOnly}
              getOptionLabel={getOptionLabel}
              renderOption={renderOption}
              isOptionEqualToValue={(option, value) => {
                if (typeof option === "object" && typeof value === "object") {
                  return option.value === value.value;
                }
                if (typeof option === "object") {
                  return option.value === value;
                }
                if (typeof value === "object") {
                  return option === value.value;
                }
                return option === value;
              }}
              componentsProps={{
                popper: {
                  placement:
                    listPosition === "top" ? "top-start" : "bottom-start",
                },
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  const label =
                    typeof option === "object" ? option.title : option;
                  return (
                    <Chip
                      {...tagProps}
                      key={key}
                      label={label}
                      onDelete={isReadOnly ? undefined : tagProps.onDelete}
                      style={{
                        backgroundColor: theme.palette.background.l1,
                        color: theme.palette.text.l1,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        opacity: isReadOnly ? 0.7 : 1,
                      }}
                    />
                  );
                })
              }
              sx={{
                height: "100%",
                "& .MuiAutocomplete-root": {
                  height: "100%",
                },
                "& .MuiOutlinedInput-root": {
                  height: "100%",
                  alignItems: "flex-start",
                  padding: "4px 8px",
                  backgroundColor: isReadOnly
                    ? "rgba(0, 0, 0, 0.05)"
                    : "transparent",
                  cursor: isReadOnly ? "default" : "text",
                },
                "& .MuiAutocomplete-endAdornment": {
                  top: "50%",
                  transform: "translateY(-50%)",
                  visibility: isReadOnly ? "hidden" : "visible",
                },
                "& .MuiAutocomplete-inputRoot": {
                  height: "100%",
                  flexWrap: "wrap",
                  overflow: "auto",
                  "& .MuiAutocomplete-input": {
                    height: "auto",
                    cursor: isReadOnly ? "default" : "text",
                  },
                },
              }}
              noOptionsText={loading ? "Loading..." : "No options"}
              loadingText={
                <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                  <CircularProgress size={20} />
                </Box>
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select value"
                  variant="outlined"
                  error={error}
                  InputLabelProps={{
                    shrink: false,
                    sx: { display: "none" },
                  }}
                  sx={{
                    height: "100%",
                    "& .MuiInputBase-root": {
                      height: "100%",
                      alignItems: "flex-start",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 1,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    readOnly: isReadOnly,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SearchableSelectInput;
