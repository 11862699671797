import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";

const Title1Widget = ({ widget, onValueUpdate }) => {
  const [title, setTitle] = useState(widget.title);
  const [subtitle, setSubtitle] = useState(widget.subtitle);

  useEffect(() => {
    setTitle(widget.title);
    setSubtitle(widget.subtitle);
  }, [widget.title, widget.subtitle]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          cursor: "pointer",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "18px",
            lineHeight: 1,
            height: "60%",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            fontSize: "12px",
            lineHeight: 1,
            height: "40%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    </>
  );
};

export default Title1Widget;
