import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Slider,
} from "@mui/material";
import { PowerSettingsNew, ColorLens, Close } from "@mui/icons-material";

const ColorPicker = ({ onColorSelect, initialColor }) => {
  const [hue, setHue] = useState(0);
  const [saturation, setSaturation] = useState(100);
  const [selectedColor, setSelectedColor] = useState(
    initialColor?.startsWith("#")
      ? initialColor
      : `#${initialColor || "ffffff"}`
  );

  useEffect(() => {
    if (initialColor) {
      // Convert hex to HSL
      const hex = initialColor.startsWith("#")
        ? initialColor.substring(1)
        : initialColor;
      const r = parseInt(hex.substring(0, 2), 16) / 255;
      const g = parseInt(hex.substring(2, 4), 16) / 255;
      const b = parseInt(hex.substring(4, 6), 16) / 255;

      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      let h,
        s,
        l = (max + min) / 2;

      if (max === min) {
        h = s = 0;
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
        }
        h /= 6;
      }

      setHue(Math.round(h * 360));
      setSaturation(Math.round(s * 100));
      setSelectedColor(initialColor);
    }
  }, [initialColor]);

  const updateColor = (newHue, newSaturation) => {
    // Convert HSL to Hex
    const h = newHue / 360;
    const s = newSaturation / 100;
    const l = 0.5;

    let r, g, b;

    if (s === 0) {
      r = g = b = l;
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;

      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    const toHex = (x) => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };

    const hexColor = `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    setSelectedColor(hexColor);
    onColorSelect(hexColor);
  };

  const handleHueChange = (event, newValue) => {
    setHue(newValue);
    updateColor(newValue, saturation);
  };

  const handleSaturationChange = (event, newValue) => {
    setSaturation(newValue);
    updateColor(hue, newValue);
  };

  const sliderStyles = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    width: "100%",
    "& .MuiSlider-thumb": {
      width: 4,
      height: 20,
      borderRadius: 2,
      backgroundColor: "#fff",
      boxShadow: "0 1px 3px rgba(0,0,0,0.4)",
      "&:hover, &.Mui-active": {
        boxShadow: "0 1px 4px rgba(0,0,0,0.6)",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-track": {
      height: 40,
      border: "none",
      borderRadius: 1,
      backgroundColor: "transparent",
    },
    "& .MuiSlider-rail": {
      height: 40,
      borderRadius: 1,
      backgroundColor: "transparent",
    },
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: 2,
      }}
    >
      {/* Hue Slider */}
      <Box sx={{ width: "100%" }}>
        <Box sx={{ position: "relative", height: 40 }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background:
                "linear-gradient(to right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%)",
              borderRadius: 1,
              pointerEvents: "none",
            }}
          />
          <Slider
            value={hue}
            onChange={handleHueChange}
            min={0}
            max={360}
            step={1}
            sx={sliderStyles}
          />
        </Box>
      </Box>

      {/* Saturation Slider */}
      <Box sx={{ width: "100%" }}>
        <Box sx={{ position: "relative", height: 40 }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `linear-gradient(to right, hsl(${hue}, 0%, 50%) 0%, hsl(${hue}, 100%, 50%) 100%)`,
              borderRadius: 1,
              pointerEvents: "none",
            }}
          />
          <Slider
            value={saturation}
            onChange={handleSaturationChange}
            min={0}
            max={100}
            step={1}
            sx={sliderStyles}
          />
        </Box>
      </Box>

      {/* Color Preview */}
      <Box
        sx={{
          width: "100%",
          height: 40,
          borderRadius: 1,
          backgroundColor: "#" + selectedColor,
          border: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      />
    </Box>
  );
};

const ColorRgbDialog1 = ({ color, onClose, current }) => {
  const [open, setOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");

  useEffect(() => {
    console.log("color", color);
    setSelectedColor(color?.startsWith("#") ? color : `#${color || "ffffff"}`);
  }, [color]);

  const handleColorSelect = (newColor) => {
    setSelectedColor(newColor);
    onClose(newColor?.startsWith("#") ? newColor.substring(1) : newColor);
  };

  const handleClose = (e, shouldSave = false) => {
    e?.preventDefault();
    e?.stopPropagation();
    setOpen(false);
    if (!shouldSave) {
      onClose(null);
    }
  };

  const hexToRgba = (hex, alpha = 1) => {
    const cleanHex = hex.replace("#", "");
    const r = parseInt(cleanHex.substring(0, 2), 16);
    const g = parseInt(cleanHex.substring(2, 4), 16);
    const b = parseInt(cleanHex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          position: "relative",
          height: "100%",
          aspectRatio: "1/1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          sx={{
            height: "100%",
            aspectRatio: "1/1",
            padding: 0,
            "& .MuiSvgIcon-root": {
              width: "100%",
              height: "100%",
              padding: 0,
              color: current !== "rgb" ? "#B0B0B0" : selectedColor,
            },
            backgroundColor:
              current !== "rgb"
                ? "rgba(176, 176, 176, 0.2)"
                : hexToRgba(selectedColor, 0.2),
            "&:hover": {
              backgroundColor:
                current !== "rgb"
                  ? "rgba(176, 176, 176, 0.3)"
                  : hexToRgba(selectedColor, 0.3),
            },
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(true);
          }}
        >
          <ColorLens />
        </IconButton>
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label="close"
            onClick={(e) => handleClose(e, true)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ColorPicker onColorSelect={handleColorSelect} initialColor={color} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ColorRgbDialog1;
