import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CircleIcon } from "lucide-react";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";

const AutomationErrorCardContent = ({ type, payload }) => {
  const theme = useTheme();

  return (
    <CardContent
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        paddingTop: "16px",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: "16px !important",
        backgroundColor: "background.card",
      }}
    >
      {type === "install" && (
        <>
          <Typography
            variant="subtitle1"
            sx={{
              mb: 2,
              color: "text.primary",
              fontWeight: 500,
            }}
          >
            Following integrations are not installed:
          </Typography>

          <List sx={{ width: "100%", p: 0 }}>
            {payload.map((integration, index) => (
              <ListItem
                key={integration.integrationId}
                sx={{
                  px: 0,
                  py: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  borderBottom:
                    index < payload.length - 1
                      ? `1px solid ${theme.palette.divider}`
                      : "none",
                }}
              >
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <CircleIcon
                    sx={{
                      fontSize: 8,
                      color: theme.palette.text.secondary,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={integration.integrationName}
                  sx={{
                    m: 0,
                    "& .MuiListItemText-primary": {
                      color: theme.palette.text.secondary,
                      fontSize: "0.95rem",
                    },
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  href={`/integration?id=${integration.integrationId}`}
                  size="small"
                  sx={{
                    minWidth: "90px",
                    textTransform: "none",
                    boxShadow: "none",
                    ml: "auto",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  View
                </Button>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </CardContent>
  );
};

export default AutomationErrorCardContent;
