import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const TextSaveInput = ({ valueSpec, onChange, style = "standard" }) => {
  const [value, setValue] = useState("");
  const [editValue, setEditValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const textFieldRef = useRef(null);
  const isReadOnly = valueSpec.readOnly;

  useEffect(() => {
    if (valueSpec.value !== value) {
      setValue(valueSpec.value);
      setEditValue(valueSpec.value);
    }
  }, [valueSpec]);

  useEffect(() => {
    if (!isEditing || isReadOnly) return;

    const handleClickOutside = (event) => {
      if (
        textFieldRef.current &&
        !textFieldRef.current.contains(event.target)
      ) {
        handleSave();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setEditValue(value);
        setIsEditing(false);
      } else if (event.key === "Enter" && !event.shiftKey) {
        handleSave();
      }
    };

    document.addEventListener("pointerdown", handleClickOutside, true);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("pointerdown", handleClickOutside, true);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isEditing, editValue, value, isReadOnly]);

  const handleInputChange = (event) => {
    if (isReadOnly) return;
    setEditValue(event.target.value);
  };

  const handleSave = () => {
    if (isReadOnly) return;
    if (editValue !== value) {
      onChange(editValue);
      setValue(editValue);
    }
    setIsEditing(false);
  };

  const handleTextFieldClick = (event) => {
    event.stopPropagation();
    if (isReadOnly) return;
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  const compactStyles = {
    "& .MuiInputBase-root": {
      height: "100%",
      padding: "4px 8px",
      display: "flex",
      alignItems: "center",
      backgroundColor: isReadOnly
        ? "rgba(0, 0, 0, 0.05)"
        : isEditing
        ? "transparent"
        : "action.hover",
      cursor: isReadOnly ? "default" : isEditing ? "text" : "pointer",
    },
    "& .MuiInputBase-input": {
      height: "100% !important",
      overflow: "auto !important",
      padding: "0 !important",
      cursor: isReadOnly ? "default" : isEditing ? "text" : "pointer",
      WebkitTextFillColor: isReadOnly ? "rgba(0, 0, 0, 0.38)" : "inherit",
      "&::selection": {
        backgroundColor: isReadOnly ? "transparent" : "inherit",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 1,
      borderColor: isReadOnly ? "rgba(0, 0, 0, 0.15)" : "rgba(0, 0, 0, 0.23)",
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1rem",
      transform: "translate(8px, 4px) scale(0.75)",
      color: isReadOnly ? "rgba(0, 0, 0, 0.38)" : "inherit",
      "&.MuiFormLabel-filled, &.Mui-focused": {
        transform: "translate(8px, -9px) scale(0.75)",
      },
    },
  };

  const standardStyles = {
    "& .MuiInputBase-root": {
      height: "100%",
      minHeight: "56px",
      backgroundColor: isReadOnly
        ? "rgba(0, 0, 0, 0.05)"
        : isEditing
        ? "transparent"
        : "action.hover",
      cursor: isReadOnly ? "default" : isEditing ? "text" : "pointer",
    },
    "& .MuiInputBase-input": {
      padding: "4px 4px",
      height: "100%",
      cursor: isReadOnly ? "default" : isEditing ? "text" : "pointer",
      WebkitTextFillColor: isReadOnly ? "rgba(0, 0, 0, 0.38)" : "inherit",
      "&::selection": {
        backgroundColor: isReadOnly ? "transparent" : "inherit",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: isReadOnly ? "rgba(0, 0, 0, 0.15)" : "rgba(0, 0, 0, 0.23)",
    },
    "& .MuiFormLabel-root": {
      color: isReadOnly ? "rgba(0, 0, 0, 0.38)" : "inherit",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {valueSpec.name && (
        <Grid item xs>
          <Typography
            variant="h6"
            sx={{
              color: isReadOnly ? "rgba(0, 0, 0, 0.38)" : "inherit",
            }}
          >
            {valueSpec.name}
          </Typography>
        </Grid>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          width: "100%",
          height: "100%",
          flex: 1,
          position: "relative",
        }}
      >
        <TextField
          ref={textFieldRef}
          label={valueSpec.name || ""}
          value={isEditing ? editValue : value}
          onChange={handleInputChange}
          onClick={handleTextFieldClick}
          fullWidth
          multiline
          size="small"
          sx={{
            height: "100%",
            ...(style === "compact" ? compactStyles : standardStyles),
          }}
          InputProps={{
            sx: {
              height: "100%",
              cursor: isReadOnly ? "default" : isEditing ? "text" : "pointer",
              transition: "background-color 0.2s",
            },
            readOnly: isReadOnly || !isEditing,
          }}
          inputProps={{
            style: {
              height: "100%",
              overflow: "auto",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default TextSaveInput;
