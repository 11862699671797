import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  useMediaQuery,
  useTheme,
  Box,
  Typography,
  Divider,
  Chip,
  Stack,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { saveEntityTwin } from "./integration-service";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import useApiCall from "../common/api-call";
import { useToast } from "../common/toast";
import { listCanvas } from "../dashboard/dashboard-service";

const CanvasSelectionDialog = ({ open, onClose, onConfirm, canvasList }) => {
  const [selectedCanvas, setSelectedCanvas] = useState("");
  const [isRunning, setIsRunning] = useState(false);

  const { apiCall } = useApiCall();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { successToast, errorToast } = useToast();

  const handleConfirm = async () => {
    setIsRunning(true);
    await onConfirm(selectedCanvas);
    setIsRunning(false);
    setSelectedCanvas("");
  };

  const handleCancel = () => {
    setSelectedCanvas("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="xs" fullWidth>
      <DialogTitle>Select Canvas</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel id="canvas-select-label">Canvas</InputLabel>
          <Select
            labelId="canvas-select-label"
            id="canvas-select"
            value={selectedCanvas}
            label="Canvas"
            onChange={(e) => setSelectedCanvas(e.target.value)}
          >
            {canvasList.map((canvas) => (
              <MenuItem key={canvas.id} value={canvas.id}>
                {canvas.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={!selectedCanvas}
        >
          Confirm
          {isRunning && <CircularProgress size={20} sx={{ ml: 1 }} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EntityDetailsDialog = ({ open, meta, entity, onClose }) => {
  const [selectedTwinTags, setSelectedTwinTags] = useState([]);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { successToast, errorToast } = useToast();
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [canvasDialogOpen, setCanvasDialogOpen] = useState(false);
  const [canvasList, setCanvasList] = useState([]);

  useEffect(() => {
    handleListCanvas();
  }, []);

  const handleListCanvas = async () => {
    const resp = await listCanvas(apiCall, userInfo.activeScope.id);
    if (resp.canvas) {
      setCanvasList(resp.canvas);
    } else {
      errorToast("Get Canvas Error");
    }
  };

  const formatCapability = (capability) => {
    return meta.capabilityNames[capability] || capability;
  };

  const getIntegrationName = (integration) => {
    return meta.integrationNames[integration] || integration;
  };

  const getTwinName = (twinTag) => {
    return meta.twinNames[twinTag] || twinTag;
  };

  const groupedCapabilities = entity?.capabilities?.reduce(
    (acc, capability) => {
      let type = capability.split(".")[0];
      if (type !== "command") {
        type = "data";
      }
      if (!acc[type]) acc[type] = [];
      acc[type].push(capability);
      return acc;
    },
    {}
  );

  const handleTwinTagToggle = (tag) => {
    setSelectedTwinTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  const handleAddTwinClick = () => {
    setCanvasDialogOpen(true);
  };

  const handleCanvasSelect = async (canvasId) => {
    await handleAddTwin(canvasId);
    setCanvasDialogOpen(false);
  };

  const handleAddTwin = async (id) => {
    const resp = await saveEntityTwin(
      apiCall,
      userInfo.activeScope.id,
      id,
      entity.integration,
      entity.id,
      entity.name,
      selectedTwinTags,
      true
    );
    setSelectedTwinTags([]);
    if (resp) {
      successToast("Success");
    } else {
      errorToast("Error");
    }
  };

  const handleClose = () => {
    setSelectedTwinTags([]);
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        fullScreen={isMobile}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.default,
            height: isMobile ? "100%" : "auto",
            maxHeight: "100%",
            borderRadius: isMobile ? 0 : 1,
          },
        }}
      >
        <DialogTitle
          sx={{
            p: 1.5,
            display: "flex",
            alignItems: "center",
            gap: 1,
            minHeight: 48,
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="small"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="subtitle1" component="div" sx={{ flex: 1 }}>
            Device Details
          </Typography>
        </DialogTitle>
        <Divider />

        <DialogContent sx={{ p: 2 }}>
          {/* Device Name */}
          <Typography variant="h6" gutterBottom>
            {entity?.name}
          </Typography>

          {/* Integration Type */}
          <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 2 }}>
            {getIntegrationName(entity?.integration)}
          </Typography>

          {/* Capabilities Section */}
          {/* State Capabilities */}
          {groupedCapabilities?.data && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                Data
              </Typography>
              <Stack direction="row" spacing={0.5} flexWrap="wrap">
                {groupedCapabilities.data.map((capability) => (
                  <Chip
                    key={capability}
                    label={formatCapability(capability)}
                    size="small"
                    sx={{
                      mb: 0.5,
                      backgroundColor: theme.palette.primary.main + "1A",
                      color: theme.palette.primary.main,
                    }}
                  />
                ))}
              </Stack>
            </Box>
          )}

          {/* Command Capabilities */}
          {groupedCapabilities?.command && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                Operations
              </Typography>
              <Stack direction="row" spacing={0.5} flexWrap="wrap">
                {groupedCapabilities.command.map((capability) => (
                  <Chip
                    key={capability}
                    label={formatCapability(capability)}
                    size="small"
                    sx={{
                      mb: 0.5,
                      backgroundColor: theme.palette.success.main + "1A",
                      color: theme.palette.success.main,
                    }}
                  />
                ))}
              </Stack>
            </Box>
          )}

          {/* Twin Tags Section */}
          {process.env.NODE_ENV === "development" &&
            entity?.twinTags &&
            entity.twinTags.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography variant="subtitle2">Add to Dashboard</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleAddTwinClick}
                    disabled={selectedTwinTags.length === 0}
                  >
                    Add
                  </Button>
                </Box>
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    p: 1,
                  }}
                >
                  {entity.twinTags.map((tag) => (
                    <Box
                      key={tag}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        "&:last-child": {
                          borderBottom: "none",
                        },
                      }}
                    >
                      <Checkbox
                        checked={selectedTwinTags.includes(tag)}
                        onChange={() => handleTwinTagToggle(tag)}
                        size="small"
                      />
                      <Typography variant="body2">
                        {getTwinName(tag)}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
        </DialogContent>
      </Dialog>
      <CanvasSelectionDialog
        open={canvasDialogOpen}
        onClose={() => setCanvasDialogOpen(false)}
        onConfirm={handleCanvasSelect}
        canvasList={canvasList}
      />
    </>
  );
};

export default EntityDetailsDialog;
