export const listIntegrations = async (apiCall, scopeId, parentId) => {
  try {
    if (parentId) {
      const response = await apiCall(
        `/integration/list?scope=${scopeId}&abstract=${parentId}`,
        "GET"
      );
      return response;
    } else {
      const response = await apiCall(
        `/integration/list?scope=${scopeId}`,
        "GET"
      );
      return response;
    }
  } catch (error) {
    return null;
  }
};

export const listEntities = async (apiCall, scopeId, integrationId) => {
  try {
    const response = await apiCall(
      `/integration/entity/list?scope=${scopeId}&integration=${integrationId}`,
      "GET"
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const getIntegration = async (apiCall, scopeId, integrationId) => {
  try {
    const response = await apiCall(
      `/integration/get?scope=${scopeId}&integration=${integrationId}`,
      "GET"
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const listGroups = async (apiCall, scopeId, integrationId) => {
  try {
    const response = await apiCall(
      `/integration/group/list?scope=${scopeId}&integration=${integrationId}`,
      "GET"
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const listProviders = async (apiCall, scopeId, integrationId) => {
  try {
    const response = await apiCall(
      `/integration/provider/list?scope=${scopeId}&integration=${integrationId}`,
      "GET"
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const saveEntityTwin = async (
  apiCall,
  scopeId,
  canvasId,
  integration,
  entityId,
  entityName,
  twinTags,
  add
) => {
  const req = {
    scopeId,
    canvasId,
    integration,
    entityId,
    entityName,
    twinTags,
    add,
  };
  try {
    console.log(req);
    const response = await apiCall("/ui/twin/save", "POST", req);
    console.log(response);
    return response && response.success;
  } catch (error) {
    return null;
  }
};

export const installIntegration = async (apiCall, scope, integration) => {
  const req = { scope, integration };
  try {
    const response = await apiCall(`/integration/install`, "POST", req);
    return response && response.success;
  } catch (error) {
    return null;
  }
};

export const uninstallIntegration = async (apiCall, scope, integration) => {
  const req = { scope, integration };
  try {
    const response = await apiCall(`/integration/uninstall`, "POST", req);
    return response && response.success;
  } catch (error) {
    return null;
  }
};

export const saveGroup = async (apiCall, group) => {
  try {
    return await apiCall(`/integration/group/save`, "POST", group);
  } catch (error) {
    return null;
  }
};

export const deleteGroup = async (apiCall, req) => {
  try {
    return await apiCall(`/integration/group/delete`, "POST", req);
  } catch (error) {
    return null;
  }
};

export const startConnectIntegration = async (
  apiCall,
  scopeId,
  integration
) => {
  const request = { scopeId, integration };
  try {
    return await apiCall("/integration/connect", "POST", request);
  } catch (error) {
    return null;
  }
};

export const completeConnectIntegration = async (
  apiCall,
  scopeId,
  integration,
  customConnect
) => {
  const request = { scopeId, integration, customConnect };
  try {
    return await apiCall("/integration/connection/complete", "POST", request);
  } catch (error) {
    return null;
  }
};

export const disconnectIntegration = async (
  apiCall,
  scopeId,
  integration,
  connectId
) => {
  const request = { scopeId, integration, connectId };
  try {
    const resp = await apiCall("/integration/disconnect", "POST", request);
    if (!resp.error) {
      return resp;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
