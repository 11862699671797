/**
 * Converts a string with title and subtitle format into an object with title, subtitle, and value properties
 * @param {string} str - The input string to convert
 * @param {boolean} isEntityType - Whether to process the string as an entity type
 * @returns {Object|string} - Returns an object with title, subtitle, and value if processed, or the original string if not
 */
export const toEntityTitleSubtitle = (str, isEntityType) => {
  if (typeof str === "string" && isEntityType) {
    // Check if the string ends with a number in parentheses
    const match = str.match(/^(.*?)\s*\((.*?)\)(?:\s*(\(\d+\)))?$/);
    if (match) {
      // If there's a number in parentheses at the end
      if (match[3]) {
        return {
          title: `${match[1].trim()} ${match[3]}`,
          subtitle: match[2].trim(),
          value: str,
        };
        // Original case without number parentheses at the end
      } else {
        return {
          title: match[1].trim(),
          subtitle: match[2].trim(),
          value: str,
        };
      }
    }
  }
  return str;
};

export const toEntityTitle = (str, isEntityType) => {
  const result = toEntityTitleSubtitle(str, isEntityType);
  if (typeof result === "object" && result.title) {
    return result.title;
  }
  return result;
};
