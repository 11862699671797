export const prepareWidget = (widget) => {
  if (widget.prepared) {
    return;
  }
  widget.enums = widget.options;
  widget.units = widget.stepUnits;
  if (widget.dataType === "Number") {
    widget.number = widget.value ? Number(widget.value) : 0;
  } else if (widget.dataType === "Bool") {
    widget.value = widget.value === "true";
  } else if (widget.dataType === "Date") {
    // pass
  } else if (widget.dataType === "Time") {
    // pass
  } else if (widget.dataType === "Timestamp") {
    // pass
  } else if (widget.dataType === "String") {
    // pass
  } else if (
    widget.dataType === "Temperature" ||
    widget.dataType === "TemperatureDiff" ||
    widget.dataType === "Duration"
  ) {
    // pass
  }
  widget.name = null;
  widget.prepared = true;
};
