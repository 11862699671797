import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MSwitch from "../common/switch";

/**
 * {value: boolean}
 */
const BoolInput = ({ valueSpec, onChange }) => {
  const [value, setValue] = useState(false);
  const isReadOnly = valueSpec.readOnly;

  useEffect(() => {
    setValue(valueSpec.value || false);
    return () => {};
  }, [valueSpec]);

  const handleChange = (event) => {
    if (!event || isReadOnly) return;
    setValue(event.target.checked);
    onChange(event.target.checked.toString());
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      {valueSpec.name && (
        <Typography variant="h6" sx={{ position: "absolute", left: 0 }}>
          {valueSpec.name}
        </Typography>
      )}
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <MSwitch
          checked={value}
          onChange={handleChange}
          disabled={isReadOnly}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Box>
    </Box>
  );
};

export default BoolInput;
