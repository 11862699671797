import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

const ColorTempNumber1Widget = ({
  widget,
  onValueUpdate,
  drag = false,
  edit = false,
}) => {
  const [colorTemp, setColorTemp] = useState(2700);
  const [widgetData, setWidgetData] = useState(null);
  const [tempMinMax, setTempMinMax] = useState({ min: 2700, max: 6500 });
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = React.useRef(null);

  useEffect(() => {
    if (!widget) return;
    setWidgetData(widget);

    if (widget.value) {
      setColorTemp(widget.value || 2700);
    }
  }, [widget]);

  const handleValueUpdate = (data) => {
    if (drag || edit || !widgetData) return;

    const updatedWidget = {
      ...widgetData,
      value: data,
    };

    onValueUpdate(updatedWidget);
  };

  const colorTemperatureToRGB = (kelvin) => {
    const temp = kelvin / 100;
    let red, green, blue;

    if (temp <= 66) {
      red = 255;
      green = temp;
      green = 99.4708025861 * Math.log(green) - 161.1195681661;
      if (temp <= 19) {
        blue = 0;
      } else {
        blue = temp - 10;
        blue = 138.5177312231 * Math.log(blue) - 305.0447927307;
      }
    } else {
      red = temp - 60;
      red = 329.698727446 * Math.pow(red, -0.1332047592);
      green = temp - 60;
      green = 288.1221695283 * Math.pow(green, -0.0755148492);
      blue = 255;
    }

    return {
      r: Math.min(255, Math.max(0, red)),
      g: Math.min(255, Math.max(0, green)),
      b: Math.min(255, Math.max(0, blue)),
    };
  };

  const gradientStops = [];
  const steps = 10;

  for (let i = 0; i <= steps; i++) {
    const temp =
      tempMinMax.min + (i / steps) * (tempMinMax.max - tempMinMax.min);
    const rgb = colorTemperatureToRGB(temp);
    gradientStops.push(`rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`);
  }

  const gradientStyle = {
    background: `linear-gradient(to right, ${gradientStops.join(", ")})`,
  };

  const calculateTemperature = (clientX) => {
    const rect = sliderRef.current.getBoundingClientRect();
    const percentage = (clientX - rect.left) / rect.width;
    const clampedPercentage = Math.max(0, Math.min(1, percentage));
    return Math.round(
      tempMinMax.min + clampedPercentage * (tempMinMax.max - tempMinMax.min)
    );
  };

  const handleInteraction = (clientX) => {
    if (drag || edit) return;
    const newTemp = calculateTemperature(clientX);
    if (newTemp !== colorTemp) {
      setColorTemp(newTemp);
      handleValueUpdate(newTemp);
    }
  };

  const handleMouseDown = (e) => {
    if (drag || edit) return;
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
    handleInteraction(e.clientX);

    const handleMouseMove = (e) => {
      e.preventDefault();
      e.stopPropagation();
      requestAnimationFrame(() => {
        handleInteraction(e.clientX);
      });
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleTouchStart = (e) => {
    if (drag || edit) return;
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
    handleInteraction(e.touches[0].clientX);

    const handleTouchMove = (e) => {
      e.preventDefault();
      e.stopPropagation();
      requestAnimationFrame(() => {
        handleInteraction(e.touches[0].clientX);
      });
    };

    const handleTouchEnd = () => {
      setIsDragging(false);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };

    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    document.addEventListener("touchend", handleTouchEnd);
  };

  const thumbPosition =
    ((colorTemp - tempMinMax.min) / (tempMinMax.max - tempMinMax.min)) * 100;

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {isDragging && (
        <Box
          sx={{
            position: "absolute",
            left: `${thumbPosition}%`,
            top: "-25px",
            transform: "translateX(-50%)",
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            borderRadius: 2,
            padding: "4px 8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            color: "black",
            fontSize: "14px",
            fontWeight: "bold",
            zIndex: 1,
            pointerEvents: "none",
          }}
        >
          {colorTemp}K
        </Box>
      )}
      <Box
        ref={sliderRef}
        sx={{
          position: "relative",
          width: "100%",
          height: "30px",
          borderRadius: 2,
          cursor: drag || edit ? "default" : "pointer",
          WebkitTapHighlightColor: "transparent",
          WebkitTouchCallout: "none",
          WebkitUserSelect: "none",
          userSelect: "none",
          outline: "none",
          "&:active": {
            backgroundColor: "transparent",
          },
          ...gradientStyle,
        }}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <Box
          sx={{
            position: "absolute",
            width: 4,
            height: 20,
            backgroundColor: "#ffffff",
            borderRadius: 1,
            boxShadow: "0 0 4px rgba(0, 0, 0, 0.3)",
            top: "50%",
            transform: "translateY(-50%)",
            left: `${thumbPosition}%`,
            pointerEvents: "none",
          }}
        />
      </Box>
    </Box>
  );
};

export default ColorTempNumber1Widget;
