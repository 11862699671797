import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import LoadingSpinner from "../common/loading";
import UpsertWidgetDialog from "./upsert-widget-dialog";
import UpsertDeviceDialog from "./upsert-device-dialog";
import { useWebSocket } from "../ws/websocket";
import { useEventEmitter } from "../ws/event-context";
import {
  deleteCard,
  updateCanvasCards,
  updateCardLayout,
  updateWidgetLayout,
  upsertWidget,
  copyCardToCanvas,
} from "./dashboard-service";
import { useToast } from "../common/toast";
import { useBackdrop } from "../common/backdrop";
import AddWidgetCard from "./add-widget-card";
import useApiCall from "../common/api-call";
import {
  Paper,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import DashboardCard from "./dashboard-card";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import AddIcon from "@mui/icons-material/Add";
import EditCardDialog from "./edit-card-dialog";
import DeleteCardDialog from "./delete-card-dialog";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
// import TwinTagDialog1 from "./twin-widget/twinTag-dialog-1";
import AddOptionDialog from "./add-option-dialog";
import { updateWidgetData } from "./dashboard-service";
import { listCanvas } from "./dashboard-service";
import { copyCard } from "./dashboard-service";
import EditWidgetModal from "./edit-widget-modal";
import { ContentCopy } from "@mui/icons-material";
import GridOnIcon from "@mui/icons-material/GridOn";
import { registerDataWidgets } from "./widget-registry";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import debounce from "lodash/debounce";
import CardContentEditDialog from "./card-content-edit-dialog";
import { isEqual } from "lodash";
import {
  disableAutomation,
  enableAutomation,
  runningAutomation,
} from "../automation/automation-service";
import { useDragDrop } from "./drag-context";
import { Typography } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";

const ResponsiveGridLayout = WidthProvider(Responsive);

const generateLayouts = (cardData, cols) => {
  let nextY = 0;
  let nextX = 0;

  return cardData.map((card) => {
    let { w, h, x, y } = card.layout;

    if (x === null || y === null || x === undefined || y === undefined) {
      // Auto-calculate x and y for missing ones
      if (nextX + w > cols) {
        nextX = 0;
        nextY += h; // Move to next row if space is not enough
      }
      x = nextX;
      y = nextY;
      nextX += w;
    } else {
      // If x and y are provided, move nextX and nextY accordingly
      nextX = Math.max(nextX, x + w);
      nextY = Math.max(nextY, y);
    }

    return { i: card.id, x, y, w, h };
  });
};

const DashboardView = forwardRef(({ drag = false }, ref) => {
  const isXxs = useMediaQuery("(max-width:480px)");
  const isXs = useMediaQuery("(min-width:480px) and (max-width:768px)");
  const isSm = useMediaQuery("(min-width:768px) and (max-width:996px)");
  const isMd = useMediaQuery("(min-width:996px) and (max-width:1200px)");
  const isLg = useMediaQuery("(min-width:1200px)");

  const { openBackdrop, closeBackdrop } = useBackdrop();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { ws, sendAndWaitWs, sendAndForgetWs } = useWebSocket();
  const { subscribe } = useEventEmitter();
  const [currentWidgets, setCurrentWidgets] = useState([]);
  const [currentCard, setCurrentCard] = useState(null);
  const [currentEditCard, setCurrentEditCard] = useState(null);
  const { successToast, errorToast } = useToast();
  const [layouts, setLayouts] = useState(null);
  const [edit, setEdit] = useState(null);

  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const widget2CardRef = useRef(new Map());
  const cardMapRef = useRef(new Map());
  const widgetMapRef = useRef(new Map());
  const [cardList, setCardList] = useState([]);
  const revisionRef = useRef(-1);
  const curCanvasRef = useRef("");
  const skipEvent = useRef(false);
  const [layoutChanged, setLayoutChanged] = useState(false);
  const [openOptionDialog, setOpenOptionDialog] = useState(false);
  const [openWidgetDialog, setOpenWidgetDialog] = useState(false);
  const [openDeviceDialog, setOpenDeviceDialog] = useState(false);
  const [openCardDialog, setOpenCardDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [widgetNames, setWidgetNames] = useState([]);
  const [isUpsertingWidget, setIsUpsertingWidget] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const { apiCall } = useApiCall();
  const [canvasList, setCanvasList] = useState([]);

  const [originalLayouts, setOriginalLayouts] = useState([]);
  // const [openTwinTagDialog, setOpenTwinTagDialog] = useState(false);
  const [activeWidgets, setActiveWidgets] = useState([]);

  const [pressingCardId, setPressingCardId] = useState(null);
  const [isLongPress, setIsLongPress] = useState(false);
  const longPressTimerRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [editCard, setEditCard] = useState(null);
  const [isEditTitle, setIsEditTitle] = useState(null);

  const [selectedCanvas, setSelectedCanvas] = useState("");
  const [canvasToDelete, setCanvasToDelete] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [shouldShowMenu, setShouldShowMenu] = useState(false);
  const cardActionsRef = useRef(null);
  const buttonsRef = useRef(null);
  const isInitialized = useRef(false);
  const latestWidgetValues = useRef({});
  const [isCanvasChanging, setIsCanvasChanging] = useState(false);
  const { draggedCard, setDraggedCard } = useDragDrop();

  useEffect(() => {
    // Register data widgets once
    registerDataWidgets();
  }, []);

  useEffect(() => {
    if (!draggedCard) return;

    const handleMouseUp = (e) => {
      let droppedOnCanvas = false;
      const canvasTabs = document.querySelectorAll(".canvas-tab");

      for (const tab of canvasTabs) {
        const rect = tab.getBoundingClientRect();
        if (
          e.clientX >= rect.left &&
          e.clientX <= rect.right &&
          e.clientY >= rect.top &&
          e.clientY <= rect.bottom
        ) {
          const targetCanvasId = tab.getAttribute("data-canvas-id");
          const targetCanvasName = tab.getAttribute("data-canvas-name");

          if (targetCanvasId && targetCanvasId !== curCanvasRef.current) {
            moveCardToCanvas(
              draggedCard.card,
              targetCanvasId,
              targetCanvasName
            );
            droppedOnCanvas = true;
          }
          break;
        }
      }

      document.querySelectorAll(".canvas-tab").forEach((tab) => {
        tab.classList.remove("drag-hover");
      });

      if (!droppedOnCanvas) {
        setDraggedCard(null);
      }

      const cards = document.querySelectorAll(".react-grid-item");
      cards.forEach((card) => {
        card.style.opacity = "";
        card.style.boxShadow = "";
        card.style.zIndex = "";
      });
    };

    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [draggedCard, curCanvasRef.current]);

  useEffect(() => {
    if (!layouts?.length) return;

    if (edit !== null) {
      setOriginalLayouts(layouts);
      // Remove height modification when entering edit mode
      setLayouts(layouts);
      setCardList(cardList);
    } else {
      const newLayouts = layouts.map((layout) => {
        const originalLayout = originalLayouts.find((l) => l.i === layout.i);
        if (originalLayout && layout.h > originalLayout.h) {
          return {
            ...layout,
            h: layout.h - 1,
          };
        }
        return layout;
      });
      setLayouts(newLayouts);

      const updatedCardList = cardList.map((card) => {
        if (card.layout.h > card.layout.h - 2) {
          return {
            ...card,
            layout: {
              ...card.layout,
              h: card.layout.h - 2,
            },
          };
        }
        return card;
      });
      setCardList(updatedCardList);
    }
  }, [edit]);

  useEffect(() => {
    if (!dashboardLoading) {
      const timer = setTimeout(() => {
        setIsLayoutReady(true);
      }, 600);
      return () => clearTimeout(timer);
    }
  }, [dashboardLoading]);

  useEffect(() => {
    const { unsubscribe } = subscribe((target, data) => {
      if (target === "_wsclosed_") {
        openBackdrop("Connecting ...");
      } else if (target === "_dashboard_") {
        if (data.command === "add") {
          handleOpenOptionDialog();
        }
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      // clearInterval(pollInterval);
      closeBackdrop();
      loadDashboard();
    }
  }, [ws, ws?.readyState]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const canvasId = searchParams.get("canvas");
    if (canvasId) {
      curCanvasRef.current = canvasId;
    }
    const { unsubscribe } = subscribe((target, data) => {
      if (skipEvent.current) {
        return;
      }
      if (target === "/ui/widget/update") {
        const payload = JSON.parse(data.payload);

        const widgets = [];
        for (let change of payload.changes) {
          if (change.type === "UPSERT") {
            widgets.push(change.widget);
          }
        }
        if (widgets.length > 0) {
          console.log("widgets", widgets);
          handleWidgetUpdateEvent(widgets);
        }
      } else if (target === "/ui/canvas/update") {
        const change = JSON.parse(data.payload);
        console.log(change);
        if (
          change.type === "UPDATE_CANVAS" &&
          change.canvasId === curCanvasRef.current
        ) {
          handleCanvasUpdateEvent(change.revision);
        }
      } else if (target === "/ui/card/update") {
        const change = JSON.parse(data.payload);

        const payload = JSON.parse(data.payload);
        console.log(payload);

        for (let change of payload.changes) {
          if (change.type === "UPSERT") {
            handleCardUpdateEvent(change.card);
          }
        }
      } else if (target === "/automation/runningStatus/update") {
        const payload = JSON.parse(data.payload);
        if (payload) {
          handleAutomationRunningEvent(payload);
        }
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (layoutChanged && !drag) {
      handleUpdateCardLayout();
      setLayoutChanged(false);
    }
  }, [drag]);

  const handleCanvasUpdateEvent = (lr) => {
    if (revisionRef.current === -1) {
      setTimeout(() => {
        handleCanvasUpdateEvent(lr);
      }, 500);
    } else {
      if (revisionRef.current !== lr) {
        setTimeout(() => {
          if (revisionRef.current !== lr) {
            openBackdrop("Canvas updated, Refresh");
          }
        }, 500);
      }
    }
  };

  const handleWidgetUpdateEvent = (widgets) => {
    if (!isInitialized.current) {
      console.log("Dashboard not initialized yet, ignoring widget update");
      return;
    }

    const widgetsArray = Array.isArray(widgets) ? widgets : [widgets];
    let hasUpdates = false;

    for (const widget of widgetsArray) {
      let wid = widget.id;

      if (!widget2CardRef.current.get(wid)) {
        // not related widget, ignore
        continue;
      }

      let nw = widget;
      if (wid.startsWith("P-")) {
        const pw = widgetMapRef.current.get(wid);
        if (pw && pw.items) {
          const nItems = Object.fromEntries(
            Object.entries(pw.items).map(([key, item]) => {
              const updatedItem = widget.items[key];
              return [key, updatedItem || item];
            })
          );
          nw = { ...pw, items: nItems };
        }
      }
      widgetMapRef.current.set(wid, nw);

      for (const cardId of widget2CardRef.current.get(wid)) {
        const card = cardMapRef.current.get(cardId);
        if (card) {
          const newWidgets = card.widgets.map((w) =>
            w.id === wid ? { ...w, ...nw, prepared: false } : w
          );
          const newCard = { ...card, widgets: newWidgets };
          cardMapRef.current.set(newCard.id, newCard);
          hasUpdates = true;
        }
      }
    }

    if (hasUpdates) {
      setCardList(Array.from(cardMapRef.current.values()));
      setWidgetNames(
        Array.from(widgetMapRef.current.values()).map((w) => w.name)
      );
    }
  };

  const handleCardUpdateEvent = (card) => {
    if (!isInitialized.current) {
      console.log("Dashboard not initialized yet, ignoring card update");
      return;
    }

    const existingCard = cardMapRef.current.get(card.cardId);
    if (!existingCard) {
      return;
    }

    const updatedCard = {
      ...existingCard,
      setting: card.setting || existingCard.setting,
      widgets: existingCard.widgets.map((widget) => {
        const updatedWidget = card.widgets.find((w) => w.id === widget.id);
        if (updatedWidget) {
          return {
            ...widget,
            ...updatedWidget,
            prepared: widget.prepared,
          };
        }
        return widget;
      }),
    };

    cardMapRef.current.set(card.cardId, updatedCard);

    updatedCard.widgets.forEach((widget) => {
      const widgetId = widget.id;

      let cardSet = widget2CardRef.current.get(widgetId);
      if (!cardSet || !(cardSet instanceof Set)) {
        cardSet = Array.isArray(cardSet) ? new Set(cardSet) : new Set();
      }
      cardSet.add(card.cardId);

      if (!widgetMapRef.current.has(widgetId)) {
        widgetMapRef.current.set(widgetId, widget);
      } else {
        const existingWidget = widgetMapRef.current.get(widgetId);
        widgetMapRef.current.set(widgetId, {
          ...existingWidget,
          ...widget,
        });
      }
    });

    setCardList(Array.from(cardMapRef.current.values()));
    setWidgetNames(
      Array.from(widgetMapRef.current.values()).map((w) => w.name)
    );

    if (card.layout && !isEqual(existingCard.layout, card.layout)) {
      const newLayouts = generateLayouts([...cardList], getCols());
      setLayouts(newLayouts);
    }
  };

  const handleAutomationRunningEvent = (payload) => {
    const { automationId, running } = payload;

    for (const [widgetId, widget] of widgetMapRef.current.entries()) {
      if (widget.automationId === automationId) {
        const updatedWidget = {
          ...widget,
          running,
        };
        widgetMapRef.current.set(widgetId, updatedWidget);

        const cardIds = widget2CardRef.current.get(widgetId);
        if (cardIds) {
          cardIds.forEach((cardId) => {
            const card = cardMapRef.current.get(cardId);

            if (card) {
              const newWidgets = card.widgets.map((w) => {
                if (w.id === widgetId) {
                  return {
                    ...w,
                    running,
                  };
                }
                return w;
              });

              const newCard = { ...card, widgets: newWidgets };
              cardMapRef.current.set(cardId, newCard);
            }
          });
        }
        setCardList(Array.from(cardMapRef.current.values()));
      }
    }
  };

  const moveCardToCanvas = async (card, targetCanvasId, targetCanvasName) => {
    try {
      openBackdrop("Moving card...");

      const copyResponse = await copyCardToCanvas(
        apiCall,
        userInfo.activeScope.id,
        targetCanvasId,
        card.id,
        curCanvasRef.current
      );

      if (!copyResponse.success) {
        throw new Error("Failed to copy card to target canvas");
      }

      successToast(`Card moved to ${targetCanvasName}`);

      loadDashboard(targetCanvasId);
    } catch (error) {
      console.error("Error moving card between canvases:", error);
      errorToast("Failed to move card");
    } finally {
      closeBackdrop();
    }
  };

  const currentLayout = () => {
    if (isXs) return "xs";
    if (isSm) return "sm";
    if (isMd) return "md";
    if (isLg) return "lg";
    return "xs";
  };

  const prepareCards = (cardList, widgetList, automations) => {
    const layoutSize = currentLayout();

    widgetMapRef.current.clear();
    cardMapRef.current.clear();

    const processedWidgets = widgetList.map((widget) => {
      if (widget.source === "automation") {
        const matchedAutomation = automations.find(
          (auto) => auto.automationId === widget.automationId
        );

        if (matchedAutomation) {
          return {
            ...widget,
            enabled: matchedAutomation.enabled,
            running: matchedAutomation.running,
          };
        }
      }
      return widget;
    });

    for (let i = 0; i < processedWidgets.length; i++) {
      widgetMapRef.current.set(processedWidgets[i].id, processedWidgets[i]);
    }
    setWidgetNames(
      Array.from(widgetMapRef.current.values()).map((w) => w.name)
    );

    const cards = cardList
      .map((cd) => {
        const layout = cd.layouts[layoutSize] || {};
        const detailedWidgets = cd.widgets
          .filter((widget) => widgetMapRef.current.has(widget.id))
          .map((widget) => {
            const fw = widgetMapRef.current.get(widget.id);
            const updated = { ...widget, ...fw };
            return updated;
          })
          .filter((widget) => widget !== undefined && widget !== null);

        detailedWidgets.forEach((widget) => {
          if (!widget2CardRef.current.has(widget.id)) {
            widget2CardRef.current.set(widget.id, [cd.cardId]);
          } else {
            const currentArray = widget2CardRef.current.get(widget.id);
            widget2CardRef.current.set(widget.id, [...currentArray, cd.cardId]);
          }
        });

        return detailedWidgets.length > 0
          ? {
              id: cd.cardId,
              layout: layout,
              setting: cd.setting,
              widgets: detailedWidgets,
            }
          : null;
      })
      .filter((group) => group !== null);

    cards.sort((a, b) => {
      // If either card's layout is null, move it to the end
      if (!a.layout.y) return 1;
      if (!b.layout.y) return -1;

      // Compare y values first
      if (a.layout.y !== b.layout.y) {
        return a.layout.y - b.layout.y;
      }

      // If y values are equal, compare x values
      return a.layout.x - b.layout.x;
    });

    for (let i = 0; i < cards.length; i++) {
      cardMapRef.current.set(cards[i].id, cards[i]);
    }

    setCardList(cards);

    const lo = generateLayouts(cards, getCols());
    setLayouts(lo);
    isInitialized.current = true;
  };

  const loadDashboard = async (newCanvasId = null) => {
    try {
      setDashboardLoading(true);

      let targetCanvasId = newCanvasId;

      if (!targetCanvasId) {
        const searchParams = new URLSearchParams(window.location.search);
        const urlCanvasId = searchParams.get("canvas");

        targetCanvasId = urlCanvasId || targetCanvasId;
      }

      if (newCanvasId && newCanvasId === curCanvasRef.current) {
        return;
      }

      setLayouts(null);
      setCardList([]);
      setWidgetNames([]);
      isInitialized.current = false;
      skipEvent.current = true;

      widget2CardRef.current.clear();
      cardMapRef.current.clear();
      widgetMapRef.current.clear();
      revisionRef.current = -1;

      const payload = targetCanvasId ? { canvasId: targetCanvasId } : {};
      const response = await sendAndWaitWs(
        "/ui/canvas/get",
        JSON.stringify(payload)
      );

      if (response.error) {
        throw new Error(response.error);
      }

      const jsonData = JSON.parse(response.payload);

      const currentCanvasId = jsonData.canvas.id;

      history.replace(`/dashboard?canvas=${currentCanvasId}`);
      curCanvasRef.current = currentCanvasId;

      // setLastSelectedCanvas(currentCanvasId);

      revisionRef.current = jsonData.canvas.revision;
      prepareCards(
        jsonData.canvas.cards,
        jsonData.widgets,
        jsonData.automations
      );

      closeBackdrop();
      skipEvent.current = false;
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      isInitialized.current = false;
      errorToast("Failed to load dashboard");

      if (newCanvasId) {
        history.replace(`/dashboard?canvas=${curCanvasRef.current}`);
      }
    } finally {
      setDashboardLoading(false);
    }
  };

  useEffect(() => {
    handleListCanvas();
  }, []);

  const handleListCanvas = async () => {
    const resp = await listCanvas(apiCall, userInfo.activeScope.id);
    if (resp.canvas) {
      setCanvasList(resp.canvas);
    } else {
      errorToast("Get Canvas Error");
    }
  };

  const handleOpenOptionDialog = () => {
    setOpenOptionDialog(true);
  };

  const handleNewWidget = () => {
    setCurrentWidgets([]);
    setOpenWidgetDialog(true);
    setOpenOptionDialog(false);
  };

  const handleNewDeviceTwin = () => {
    setCurrentWidgets([]);
    setOpenDeviceDialog(true);
    setOpenOptionDialog(false);
  };

  // useImperativeHandle(ref, () => ({
  //   handleNewWidgetConfig
  // }));

  const handleCloseWidgetDialog = () => {
    setOpenWidgetDialog(false);
  };

  const handleCloseDeviceDialog = () => {
    setOpenDeviceDialog(false);
  };

  const handleCloseOptionDialog = () => {
    setOpenOptionDialog(false);
  };

  const handleCloseCardDialog = () => {
    setOpenCardDialog(false);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleUpsertWidgets = async (widgetItems) => {
    if (widgetItems.length <= 0) {
      return;
    }
    const isNew = widgetItems[0].id === null || widgetItems[0].id === undefined;
    setIsUpsertingWidget(true);
    skipEvent.current = true;
    try {
      const success = await upsertWidget(
        apiCall,
        userInfo.activeScope.id,
        curCanvasRef.current,
        widgetItems
      );
      if (success) {
        successToast("Saved");
        await loadDashboard();
      } else {
        errorToast("Error! Check the name and config.");
      }
    } catch (error) {
      errorToast("Error! Check the name and config.");
    } finally {
      setIsUpsertingWidget(false);
      skipEvent.current = false;
    }
  };

  const handleUpsertDeviceTwin = () => {
    loadDashboard();
  };

  const handleBeforeUpsertDeviceTwin = () => {
    skipEvent.current = true;
  };

  const handleUpdateCard = async (card) => {
    const newCard = {
      id: card.id,
      name: card.name,
      widgetIds: card.widgets.map((w) => w.id),
    };
    skipEvent.current = true;
    const success = await updateCanvasCards(
      apiCall,
      userInfo.activeScope.id,
      curCanvasRef.current,
      [newCard]
    );
    if (success) {
      successToast("Saved");
      loadDashboard();
      setOpenCardDialog(false);
    } else {
      errorToast("Error!");
    }
  };

  const handleDeleteCard = async (cardId) => {
    revisionRef.current = -1;
    const success = await deleteCard(
      apiCall,
      userInfo.activeScope.id,
      curCanvasRef.current,
      cardId
    );
    if (success) {
      successToast("Deleted");
      setOpenCardDialog(false);
      loadDashboard();
    } else {
      errorToast("Remove card error!");
    }
  };

  const handleUpdateCardLayout = async () => {
    const cl = currentLayout();
    const layouts = cardList.map((c) => {
      return { id: c.id, layout: c.layout, layoutSize: cl };
    });
    skipEvent.current = true;
    const success = await updateCardLayout(
      apiCall,
      userInfo.activeScope.id,
      curCanvasRef.current,
      layouts
    );
    if (success) {
      successToast("Saved");
      loadDashboard();
    } else {
      errorToast("Error!");
    }
  };

  const handleUpdateWidgetLayout = async (widgets) => {
    if (editCard) {
      const resp = await updateWidgetLayout(
        apiCall,
        userInfo.activeScope.id,
        editCard.id,
        widgets,
        null
      );
      if (resp) {
        successToast("Saved");
        handleWidgetLayoutChange(widgets);
      } else {
        errorToast("Error!");
      }
    } else {
      errorToast("Error!");
    }
  };

  const handleEditWidgets = async (widgetIds) => {
    console.log(widgetIds);
    const widgets = widgetIds.map((wid) => widgetMapRef.current.get(wid));
    setCurrentWidgets(widgets);
    setOpenWidgetDialog(true);
  };

  const handleOpenDeleteCard = async (card) => {
    if (longPressTimerRef.current) {
      clearTimeout(longPressTimerRef.current);
      longPressTimerRef.current = null;
    }
    mouseDownRef.current = false;
    setIsLongPress(false);
    setPressingCardId(null);
    setEdit(null);
    setCurrentCard(card);
    setOpenDeleteDialog(true);
  };

  const handleCopyCard = async (card) => {
    revisionRef.current = -1;
    const resp = await copyCard(
      apiCall,
      userInfo.activeScope.id,
      curCanvasRef.current,
      card.id
    );
    if (resp.success) {
      successToast("Copy Successful");
      loadDashboard();
    } else {
      errorToast("Copy card error!");
    }
  };

  const debouncedUpdate = useCallback(
    debounce(async (widgetItems) => {
      try {
        const resp = await updateWidgetData(sendAndWaitWs, widgetItems);
        if (!resp) {
          errorToast("Widget Update Error!");
        }
      } catch (error) {
        console.error("Failed to update widget:", error);
        errorToast("Error!");
      }
    }, 100),
    [sendAndWaitWs]
  );

  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  const handleUiWidgetValueChange = async (widgetItem) => {
    const widgetItems = Array.isArray(widgetItem) ? widgetItem : [widgetItem];

    widgetItems.forEach((item) => {
      latestWidgetValues.current[item.id] = item;
    });

    debouncedUpdate(widgetItems);
  };

  const handleUpdateEditCard = async (card) => {
    if (card?.widget.length > 0) {
      const widgetItems = Array.isArray(card.widget)
        ? card.widget
        : [card.widget];

      widgetItems.forEach((item) => {
        latestWidgetValues.current[item.id] = item;
      });
      handleUiWidgetValueChange(widgetItems);
    }
    if (card?.setting) {
      const result = await updateWidgetLayout(
        apiCall,
        userInfo.activeScope.id,
        currentEditCard.id,
        currentEditCard.widgets,
        card.setting
      );
      if (result) {
        successToast("Set Color Success");
      } else {
        errorToast("Color Error");
      }
    }
  };

  useEffect(() => {
    const handleGlobalMouseUp = (e) => {
      if (mouseDownRef.current) {
        mouseDownRef.current = false;
        if (longPressTimerRef.current) {
          clearTimeout(longPressTimerRef.current);
          longPressTimerRef.current = null;
        }
        setIsLongPress(false);
        if (!isLongPress) {
          setEdit(null);
        }
        setPressingCardId(null);
      }
    };

    window.addEventListener("mouseup", handleGlobalMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleGlobalMouseUp);
      if (longPressTimerRef.current) {
        clearTimeout(longPressTimerRef.current);
      }
    };
  }, [isLongPress]);

  const handleLongPressStart = (card, id, e) => {
    if (!e) return;
    if (drag) return;
    if (e.type === "mousedown" && e.button !== 0) return;

    if (longPressTimerRef.current) {
      clearTimeout(longPressTimerRef.current);
      longPressTimerRef.current = null;
    }

    mouseDownRef.current = true;
    setPressingCardId(id);
    setIsLongPress(false);

    longPressTimerRef.current = setTimeout(() => {
      if (mouseDownRef.current) {
        setIsLongPress(true);
        setEdit(id);
        setCurrentEditCard(card);
        setPressingCardId(null);
      }
    }, 500);
  };

  const handleLongPressCancel = (e) => {
    if (mouseDownRef.current) {
      mouseDownRef.current = false;
      if (longPressTimerRef.current) {
        clearTimeout(longPressTimerRef.current);
        longPressTimerRef.current = null;
      }
      setIsLongPress(false);
      if (!isLongPress) {
        setEdit(null);
      }
      setPressingCardId(null);
    }
  };

  const handleCardLayoutChange = (currentLayout) => {
    if (edit === null) {
      const updatedCardList = cardList.map((card) => {
        const layoutItem = currentLayout.find((layout) => layout.i === card.id);
        if (layoutItem) {
          card.layout = {
            ...card.layout,
            x: layoutItem.x,
            y: layoutItem.y,
            w: layoutItem.w,
            h: layoutItem.h,
          };
          return card;
        }
        return card;
      });

      setCardList(updatedCardList);
      setLayouts(currentLayout);
      setLayoutChanged(true);
    } else {
      const updatedLayout = currentLayout.map((layout) => {
        if (layout.i === edit) {
          return {
            ...layout,
            h: layout.h,
          };
        }
        return layout;
      });
      setLayouts(updatedLayout);

      const updatedCardList = cardList.map((card) => {
        const layoutItem = updatedLayout.find((layout) => layout.i === card.id);
        if (layoutItem) {
          card.layout = {
            ...card.layout,
            x: layoutItem.x,
            y: layoutItem.y,
            w: layoutItem.w,
            h: layoutItem.h,
          };
          return card;
        }
        return card;
      });
      setCardList(updatedCardList);
      setLayoutChanged(true);
    }
  };

  const handleWidgetLayoutChange = (currentLayout) => {
    if (editCard) {
      const updatedCardList = cardList.map((card) => {
        if (card.id === editCard.id) {
          const updatedWidgets = card.widgets.map((widget) => {
            const layoutItem = currentLayout.find(
              (layout) => layout.id === widget.id
            );

            if (layoutItem) {
              return {
                ...widget,
                layout: {
                  ...widget.layout,
                  x: Number(layoutItem.layout.x),
                  y: Number(layoutItem.layout.y),
                  w: Number(layoutItem.layout.w),
                  h: Number(layoutItem.layout.h),
                },
              };
            }
            return widget;
          });

          return {
            ...card,
            widgets: updatedWidgets,
          };
        }
        return card;
      });

      setCardList(updatedCardList);
      setLayoutChanged(true);
    }
  };

  const getCols = () => {
    if (isXxs) return 6;
    if (isXs) return 8;
    if (isSm) return 12;
    if (isMd) return 16;
    if (isLg) return 20;
    return 6;
  };

  const getCardWidth = (cardId) => {
    const layout = layouts.find((item) => item.i === cardId);
    return layout ? layout.w : 0;
  };

  return (
    <>
      <Box
        sx={{
          height: "100%",
          visibility: isLayoutReady ? "visible" : "hidden",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "relative",
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                flex: 1,
                overflow: "hidden",
                position: "relative",
              }}
            >
              <TableContainer
                sx={{
                  overflowX: "auto",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                  borderBottom: "1px solid",
                  borderColor: "divider",
                }}
              >
                <Table
                  size="small"
                  sx={{
                    width: "auto",
                    "& .MuiTableCell-root": {
                      borderBottom: "none",
                      py: 1,
                      px: 2,
                      whiteSpace: "nowrap",
                      height: "40px",
                    },
                  }}
                >
                  <TableBody>
                    <TableRow sx={{ display: "flex" }}>
                      {canvasList.map((canvas) => (
                        <TableCell
                          key={canvas.id}
                          className="canvas-tab"
                          data-canvas-id={canvas.id}
                          data-canvas-name={canvas.name}
                          onDragOver={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          onDragEnter={(e) => {
                            e.currentTarget.classList.add("drag-hover");
                          }}
                          onDragLeave={(e) => {
                            e.currentTarget.classList.remove("drag-hover");
                          }}
                          onDrop={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.currentTarget.classList.remove("drag-hover");

                            const targetCanvasId = canvas.id;

                            if (
                              draggedCard &&
                              draggedCard.sourceCanvasId !== targetCanvasId
                            ) {
                              moveCardToCanvas(
                                draggedCard.card,
                                targetCanvasId,
                                canvas.name
                              );
                            }
                          }}
                          sx={{
                            cursor: isCanvasChanging ? "wait" : "pointer",
                            textAlign: "left",
                            position: "relative",
                            color:
                              canvas.id === curCanvasRef.current
                                ? "primary.main"
                                : "text.secondary",
                            fontWeight:
                              canvas.id === curCanvasRef.current
                                ? "600"
                                : "400",
                            fontSize: "0.95rem",
                            letterSpacing: "0.01em",
                            "&:hover": {
                              color: "primary.main",
                              backgroundColor: "action.hover",
                            },
                            transition: "all 0.3s ease",
                            userSelect: "none",
                            width: "fit-content",
                            flex: "0 0 auto",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            opacity: isCanvasChanging ? 0.5 : 1,
                            py: 2,
                            px: 3,
                            "&::after": {
                              content: '""',
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              width: "100%",
                              height: "2px",
                              backgroundColor:
                                canvas.id === curCanvasRef.current
                                  ? "primary.main"
                                  : "transparent",
                              transform:
                                canvas.id === curCanvasRef.current
                                  ? "scaleX(1)"
                                  : "scaleX(0)",
                              transformOrigin: "left",
                              transition:
                                "transform 0.3s ease, background-color 0.3s ease",
                            },
                            "&:hover::after": {
                              backgroundColor: "primary.main",
                              transform: "scaleX(1)",
                            },
                            "&.drag-hover": {
                              backgroundColor: "action.hover",
                              color: "primary.main",
                              fontWeight: "600",
                              outline: `2px dashed ${theme.palette.primary.main}`,
                              outlineOffset: "-2px",
                            },
                          }}
                          onClick={() => {
                            if (!isCanvasChanging) {
                              loadDashboard(canvas.id);
                            }
                          }}
                        >
                          <span
                            onClick={() => {
                              if (!isCanvasChanging) {
                                loadDashboard(canvas.id);
                              }
                            }}
                          >
                            <Box
                              sx={{
                                position: "relative",
                                display: "inline-block",
                                transform:
                                  canvas.id === curCanvasRef.current
                                    ? "translateY(-1px)"
                                    : "translateY(0)",
                                transition: "transform 0.3s ease",
                                fontWeight:
                                  canvas.id === curCanvasRef.current
                                    ? "600"
                                    : "400",
                              }}
                            >
                              {canvas.name}
                            </Box>
                          </span>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            flex: 1,
            overflow: "auto",
            position: "relative",
            height: "calc(100% - 48px)",
            paddingBottom: 2,
          }}
        >
          {dashboardLoading ? (
            <></>
          ) : (
            <ResponsiveGridLayout
              className="layout"
              layouts={{
                lg: layouts,
                md: layouts,
                sm: layouts,
                xs: layouts,
                xxs: layouts,
              }}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 20, md: 16, sm: 12, xs: 8, xxs: 6 }}
              rowHeight={8}
              onLayoutChange={handleCardLayoutChange}
              compactType="vertical"
              preventCollision={false}
              isDraggable={drag}
              isResizable={drag}
              margin={[8, 8]}
              useCSSTransforms={true}
              draggableCancel=".non-draggable"
              // onDragStop={(
              //   layout,
              //   oldItem,
              //   newItem,
              //   placeholder,
              //   e,
              //   element
              // ) => {
              //   // Your drag stop handler
              // }}
              style={{
                userSelect: "none",
                WebkitUserSelect: "none",
                MozUserSelect: "none",
                msUserSelect: "none",
                WebkitTapHighlightColor: "transparent",
                touchAction: "manipulation",
              }}
              onDragStart={(
                layout,
                oldItem,
                newItem,
                placeholder,
                e,
                element
              ) => {
                if (!drag) return;

                const cardId = newItem.i;
                const card = cardList.find((c) => c.id === cardId);

                if (card) {
                  // 设置拖拽状态
                  setDraggedCard({
                    card,
                    sourceCanvasId: curCanvasRef.current,
                  });
                  element.style.opacity = "0.7";
                  element.style.boxShadow = "0 4px 12px rgba(0, 0, 0, 0.2)";
                  element.style.zIndex = "10";
                }
              }}
              onDragStop={(
                layout,
                oldItem,
                newItem,
                placeholder,
                e,
                element
              ) => {
                if (!drag) return;

                element.style.opacity = "";
                element.style.boxShadow = "";
                element.style.zIndex = "";
              }}
            >
              {cardList.map((card) => (
                <div
                  key={card.id}
                  style={{
                    position: "relative",
                    height: "100%",
                    transition: "all 0.3s ease",
                    cursor: drag ? "move" : "pointer",
                  }}
                >
                  {edit === card.id && (
                    <Box
                      sx={{
                        height: "40px",
                        backgroundColor: "background.card" || "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0 8px",
                        borderRadius: "16px 16px 0 0",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        zIndex: 2,
                      }}
                    >
                      {getCardWidth(card.id) < 3 ? (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <IconButton
                            onMouseDown={(e) => e.stopPropagation()}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setAnchorEl(e.currentTarget);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <MenuItem
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleCopyCard(card);
                                setAnchorEl(null);
                              }}
                            >
                              <ListItemIcon>
                                <ContentCopy />
                              </ListItemIcon>
                              <ListItemText>Copy</ListItemText>
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setEditCard(card);
                                setAnchorEl(null);
                              }}
                            >
                              <ListItemIcon>
                                <GridOnIcon />
                              </ListItemIcon>
                              <ListItemText>Layout</ListItemText>
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                isEditTitle !== card.id
                                  ? setIsEditTitle(card.id)
                                  : setIsEditTitle(null);
                                setAnchorEl(null);
                              }}
                            >
                              <ListItemIcon>
                                {isEditTitle === card.id ? (
                                  <EditOffIcon />
                                ) : (
                                  <EditIcon />
                                )}
                              </ListItemIcon>
                              <ListItemText>
                                {isEditTitle === card.id ? "EditOff" : "Edit"}
                              </ListItemText>
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleOpenDeleteCard(card);
                                setAnchorEl(null);
                              }}
                            >
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText>Delete</ListItemText>
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                if (isEditTitle) {
                                  return;
                                }
                                e.preventDefault();
                                e.stopPropagation();
                                if (longPressTimerRef.current) {
                                  clearTimeout(longPressTimerRef.current);
                                }
                                setPressingCardId(null);
                                setEdit(null);
                                setAnchorEl(null);
                              }}
                            >
                              <ListItemIcon>
                                <CloseIcon />
                              </ListItemIcon>
                              <ListItemText>Close</ListItemText>
                            </MenuItem>
                          </Menu>
                        </Box>
                      ) : getCardWidth(card.id) < 4 ? (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            onMouseDown={(e) => e.stopPropagation()}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setAnchorEl(e.currentTarget);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <MenuItem
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleCopyCard(card);
                                setAnchorEl(null);
                              }}
                            >
                              <ListItemIcon>
                                <ContentCopy />
                              </ListItemIcon>
                              <ListItemText>Copy</ListItemText>
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setEditCard(card);
                                setAnchorEl(null);
                              }}
                            >
                              <ListItemIcon>
                                <GridOnIcon />
                              </ListItemIcon>
                              <ListItemText>Layout</ListItemText>
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                isEditTitle !== card.id
                                  ? setIsEditTitle(card.id)
                                  : setIsEditTitle(null);
                                setAnchorEl(null);
                              }}
                            >
                              <ListItemIcon>
                                {isEditTitle === card.id ? (
                                  <EditOffIcon />
                                ) : (
                                  <EditIcon />
                                )}
                              </ListItemIcon>
                              <ListItemText>
                                {isEditTitle === card.id ? "EditOff" : "Edit"}
                              </ListItemText>
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleOpenDeleteCard(card);
                                setAnchorEl(null);
                              }}
                            >
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText>Delete</ListItemText>
                            </MenuItem>
                          </Menu>
                          <IconButton
                            aria-label="close"
                            onMouseDown={(e) => e.stopPropagation()}
                            onClick={(e) => {
                              if (isEditTitle) {
                                return;
                              }
                              e.preventDefault();
                              e.stopPropagation();
                              if (longPressTimerRef.current) {
                                clearTimeout(longPressTimerRef.current);
                              }
                              setPressingCardId(null);
                              setEdit(null);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton
                              aria-label="copy"
                              onMouseDown={(e) => e.stopPropagation()}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleCopyCard(card);
                              }}
                            >
                              <ContentCopy />
                            </IconButton>

                            <IconButton
                              onMouseDown={(e) => e.stopPropagation()}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setEditCard(card);
                              }}
                            >
                              <GridOnIcon />
                            </IconButton>

                            {isEditTitle !== card.id && (
                              <IconButton
                                onMouseDown={(e) => e.stopPropagation()}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setIsEditTitle(card.id);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                            {isEditTitle === card.id && (
                              <IconButton
                                onMouseDown={(e) => e.stopPropagation()}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setIsEditTitle(null);
                                }}
                              >
                                <EditOffIcon />
                              </IconButton>
                            )}

                            <IconButton
                              aria-label="delete"
                              onMouseDown={(e) => e.stopPropagation()}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleOpenDeleteCard(card);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                          <IconButton
                            aria-label="close"
                            onMouseDown={(e) => e.stopPropagation()}
                            onClick={(e) => {
                              if (isEditTitle) {
                                return;
                              }
                              e.preventDefault();
                              e.stopPropagation();
                              if (longPressTimerRef.current) {
                                clearTimeout(longPressTimerRef.current);
                              }
                              setPressingCardId(null);
                              setEdit(null);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  )}

                  <Paper
                    elevation={0}
                    onClick={(e) => {
                      if (isEditTitle === card.id) {
                        e.stopPropagation();
                        return;
                      }
                    }}
                    onMouseDown={(e) => {
                      if (e.button === 0) {
                        e.stopPropagation();
                        handleLongPressStart(card, card.id, e);
                      }
                    }}
                    onMouseLeave={handleLongPressCancel}
                    onTouchStart={(e) => {
                      e.stopPropagation();
                      handleLongPressStart(card, card.id, e);
                    }}
                    onTouchEnd={(e) => {
                      e.stopPropagation();
                      handleLongPressCancel(e);
                    }}
                    sx={{
                      height: edit === card.id ? "calc(100% - 40px)" : "100%",
                      width: "100%",
                      padding: "8px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      borderRadius: edit === card.id ? "0 0 16px 16px" : "16px",
                      bgcolor:
                        card?.setting?.backgroundColor || "background.white",
                      overflow: "hidden",
                      boxSizing: "border-box",
                      maxHeight: "100%",
                      maxWidth: "100%",
                      opacity: drag || edit === card.id ? 0.8 : 1,
                      filter:
                        drag || edit === card.id ? "grayscale(100%)" : "none",
                      pointerEvents: drag || edit === card.id ? "none" : "auto",
                      boxShadow: "none",
                      transform:
                        pressingCardId === card.id ? "scale(0.98)" : "scale(1)",
                      transition: "box-shadow 0.3s ease",
                    }}
                  >
                    <DashboardCard
                      card={card}
                      onStateUpdate={handleWidgetUpdateEvent}
                    ></DashboardCard>
                  </Paper>
                </div>
              ))}
            </ResponsiveGridLayout>
          )}
        </Box>
        <EditWidgetModal
          isOpen={!!editCard}
          onClose={() => {
            setEditCard(null);
          }}
          onSave={() => {
            setEditCard(null);
          }}
          widgetCard={editCard}
          layouts={layouts}
          cardList={cardList}
          handleLayoutChange={handleUpdateWidgetLayout}
        />
        {/* <TwinTagDialog1
          open={openTwinTagDialog}
          onClose={handleDialogClose}
          widgets={activeWidgets}
          onValueChange={handleUiWidgetValueChange}
        /> */}
        <AddOptionDialog
          open={openOptionDialog}
          onClose={handleCloseOptionDialog}
          onDeviceSelect={handleNewDeviceTwin}
          onWidgetSelect={handleNewWidget}
        />
        <UpsertWidgetDialog
          open={openWidgetDialog}
          currentWidgets={currentWidgets}
          existingNames={widgetNames}
          onClose={handleCloseWidgetDialog}
          onSave={handleUpsertWidgets}
          isUpserting={isUpsertingWidget}
        />
        <UpsertDeviceDialog
          open={openDeviceDialog}
          onClose={handleCloseDeviceDialog}
          onBeforeSave={handleBeforeUpsertDeviceTwin}
          onSave={handleUpsertDeviceTwin}
          currentCanvasId={curCanvasRef.current}
        />
        <DeleteCardDialog
          open={openDeleteDialog}
          card={currentCard}
          onClose={handleCloseDeleteDialog}
          onDelete={handleDeleteCard}
        />
        <EditCardDialog
          open={openCardDialog}
          card={currentCard}
          onEditWidgets={handleEditWidgets}
          onClose={handleCloseCardDialog}
          onSave={handleUpdateCard}
          onDelete={handleDeleteCard}
        />
        <CardContentEditDialog
          open={isEditTitle === currentEditCard?.id}
          card={currentEditCard}
          onClose={() => {
            setIsEditTitle(false);
          }}
          onSave={handleUpdateEditCard}
        />
      </Box>
    </>
  );
});
DashboardView.displayName = "DashboardView";
export default DashboardView;
