import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const TextInput = ({ valueSpec, onChange, style = "standard" }) => {
  const [value, setValue] = useState("");
  const isReadOnly = valueSpec.readOnly;

  useEffect(() => {
    setValue(valueSpec.value);
    return () => {};
  }, [valueSpec]);

  const handleInputChange = (event) => {
    if (isReadOnly) return;
    setValue(event.target.value);
    onChange(event.target.value);
  };

  const compactStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    "& .MuiInputBase-root": {
      height: "100%",
      padding: "4px 8px",
      display: "flex",
      backgroundColor: isReadOnly ? "rgba(0, 0, 0, 0.05)" : "transparent",
      cursor: isReadOnly ? "default" : "text",
    },
    "& .MuiInputBase-input": {
      padding: 0,
      lineHeight: 1.2,
      height: "100% !important",
      overflow: "auto !important",
      cursor: isReadOnly ? "default" : "text",
      WebkitTextFillColor: isReadOnly ? "rgba(0, 0, 0, 0.38)" : "inherit",
      "&::selection": {
        backgroundColor: isReadOnly ? "transparent" : "inherit",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 1,
      borderColor: isReadOnly ? "rgba(0, 0, 0, 0.15)" : "rgba(0, 0, 0, 0.23)",
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1rem",
      transform: "translate(8px, 4px) scale(0.75)",
      color: isReadOnly ? "rgba(0, 0, 0, 0.38)" : "inherit",
      "&.MuiFormLabel-filled, &.Mui-focused": {
        transform: "translate(8px, -9px) scale(0.75)",
      },
    },
  };

  const standardStyles = {
    "& .MuiInputBase-root": {
      minHeight: "56px",
      backgroundColor: isReadOnly ? "rgba(0, 0, 0, 0.05)" : "transparent",
      cursor: isReadOnly ? "default" : "text",
    },
    "& .MuiInputBase-input": {
      padding: "4px 4px",
      cursor: isReadOnly ? "default" : "text",
      WebkitTextFillColor: isReadOnly ? "rgba(0, 0, 0, 0.38)" : "inherit",
      "&::selection": {
        backgroundColor: isReadOnly ? "transparent" : "inherit",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: isReadOnly ? "rgba(0, 0, 0, 0.15)" : "rgba(0, 0, 0, 0.23)",
    },
    "& .MuiFormLabel-root": {
      color: isReadOnly ? "rgba(0, 0, 0, 0.38)" : "inherit",
    },
    "& .Mui-disabled": {
      cursor: "default",
      pointerEvents: "none",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        alignItems: "stretch",
        justifyContent: "center",
      }}
    >
      {valueSpec.name && (
        <Grid item xs>
          <Typography
            variant="h6"
            sx={{
              color: isReadOnly ? "rgba(0, 0, 0, 0.38)" : "inherit",
            }}
          >
            {valueSpec.name}
          </Typography>
        </Grid>
      )}
      <Box
        sx={{
          flex: 1,
          position: "relative",
          minHeight: 0, // Important for proper flex behavior
        }}
      >
        <TextField
          label={valueSpec.name || ""}
          value={value}
          onChange={handleInputChange}
          fullWidth
          multiline
          size="small"
          InputProps={{
            readOnly: isReadOnly,
            inputProps: {
              style: {
                cursor: isReadOnly ? "default" : "text",
              },
            },
          }}
          sx={style === "compact" ? compactStyles : standardStyles}
        />
      </Box>
    </Box>
  );
};

export default TextInput;
