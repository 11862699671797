import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import DashboardCard from "./dashboard-card";
import CircularProgress from "@mui/material/CircularProgress";

const ResponsiveGridLayout = WidthProvider(Responsive);

const EditWidgetModal = ({
  isOpen,
  onClose,
  onSave,
  widgetCard,
  layouts,
  handleLayoutChange,
}) => {
  const [tempLayout, setTempLayout] = useState(null);
  const [loadingSave, setLoadingSave] = useState(false);

  const handleTempLayoutChange = (newLayout) => {
    setTempLayout(newLayout);
  };

  const handleSave = async () => {
    if (tempLayout) {
      setLoadingSave(true);
      await handleLayoutChange(tempLayout);
      setLoadingSave(false);
    }
    onSave();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          height: "90vh",
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" component="div">
          Edit Widget
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{
          p: 2,
          bgcolor: "background.default",
          overflow: "visible",
        }}
      >
        <ResponsiveGridLayout
          className="layout"
          layouts={{
            lg: layouts,
            md: layouts,
            sm: layouts,
            xs: layouts,
            xxs: layouts,
          }}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 24, md: 20, sm: 12, xs: 8, xxs: 6 }}
          rowHeight={20}
          compactType="vertical"
          preventCollision={false}
          isDraggable={false}
          isResizable={false}
          margin={[10, 10]}
          useCSSTransforms={true}
        >
          <div
            key={widgetCard?.id}
            style={{
              position: "relative",
              height: "100%",
              transition: "all 0.3s ease",
            }}
          >
            <Paper
              elevation={0}
              sx={{
                height: "100%",
                width: "100%",
                padding: "4px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                borderRadius: "16px",
                bgColor: "background.card" || "white",
                boxSizing: "border-box",
                overflow: "visible",
                zIndex: 1000,
                "& > *": {
                  overflow: "visible",
                },
              }}
            >
              <DashboardCard
                card={widgetCard}
                drag={true}
                onLayoutChange={handleTempLayoutChange}
              />
            </Paper>
          </div>
        </ResponsiveGridLayout>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
          {loadingSave && (
            <CircularProgress
              size={20}
              sx={{
                ml: 1,
              }}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditWidgetModal;
