// TextInput.jsx
import React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export const TextInput = React.memo(
  ({ var: varName, value, onInputChange, readonly }) => {
    // Local state to manage the input value
    const [localValue, setLocalValue] = React.useState(value || "");

    const handleChange = React.useCallback(
      (e) => {
        const newValue = e.target.value;
        setLocalValue(newValue); // Update local state immediately
        if (onInputChange && !readonly) {
          onInputChange(varName, newValue);
        }
      },
      [varName, onInputChange]
    );

    return (
      <Box sx={{ my: 2 }}>
        <TextField
          fullWidth
          label={varName?.replace("$", "") || "Enter value"}
          variant="outlined"
          value={localValue}
          onChange={handleChange}
          placeholder="Type here..."
          size="medium"
          disabled={readonly}
          InputProps={{
            readOnly: readonly,
          }}
          inputProps={{
            "aria-label": varName,
          }}
        />
      </Box>
    );
  },
  (prevProps, nextProps) => {
    console.log(prevProps);
    console.log(nextProps);
    return (
      prevProps.var === nextProps.var &&
      prevProps.onInputChange === nextProps.onInputChange &&
      prevProps.readonly === nextProps.readonly
    );
  }
);

TextInput.displayName = "TextInput";

export const BoolInput = React.memo(
  ({ label, var: varName, value, onInputChange, readonly }) => {
    const [localValue, setLocalValue] = React.useState(value || false);

    const handleChange = React.useCallback(
      (e) => {
        const newValue = e.target.checked;
        setLocalValue(newValue);
        if (onInputChange && !readonly) {
          onInputChange(varName, newValue);
        }
      },
      [varName, onInputChange]
    );

    return (
      <Box sx={{ my: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={localValue}
              onChange={handleChange}
              disabled={readonly}
              inputProps={{
                "aria-label": varName,
              }}
            />
          }
          label={label || varName?.replace("$", "") || "Value"}
        />
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.var === nextProps.var &&
      prevProps.onInputChange === nextProps.onInputChange &&
      prevProps.readonly === nextProps.readonly
    );
  }
);

BoolInput.displayName = "BoolInput";
