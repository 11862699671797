// widget-registry.js
import NumberUnit1Widget from "./widget/number-uint-1";
import Toggle1Widget from "./widget/toggle-1";
import Select1Widget from "./widget/select-1";
import MultiSelect1Widget from "./widget/multi-select-1";
import Text1Widget from "./widget/text-1";
import Time1Widget from "./widget/time-1";
import Date1Widget from "./widget/date-1";
import DateTime1Widget from "./widget/date-time-1";
import Brightness1Widget from "./twin-widget/brightness-1";
import Color1Widget from "./twin-widget/color-1";
import ColorTemp1Widget from "./twin-widget/color-temp-1";
import ColorRgb1Widget from "./twin-widget/color-rgb-1";
import OnOff1Widget from "./twin-widget/onOff-1";
import Motion1Widget from "./twin-widget/motion-1";
import Present1Widget from "./twin-widget/present-1";
import Number1Widget from "./widget/number-1";
import BrightnessNumber1Widget from "./tag/brightness-number-1";
import ColorTempNumber1Widget from "./tag/colorTemp-number-1";

const dataWidgetMap = new Map();

// Register data widgets without handler
export const registerDataWidgets = () => {
  dataWidgetMap.set("default.toggle", Toggle1Widget);
  dataWidgetMap.set("default.bool", Toggle1Widget);
  dataWidgetMap.set("default.number", Number1Widget);
  dataWidgetMap.set("default.select", Select1Widget);
  dataWidgetMap.set("default.multiSelect", MultiSelect1Widget);
  dataWidgetMap.set("default.select[]", MultiSelect1Widget);
  dataWidgetMap.set("default.text", Text1Widget);
  dataWidgetMap.set("default.string", Text1Widget);
  dataWidgetMap.set("default.time", Time1Widget);
  dataWidgetMap.set("default.date", Date1Widget);
  dataWidgetMap.set("default.timestamp", DateTime1Widget);

  // twin
  dataWidgetMap.set("default.basic.device.brightness", Brightness1Widget);
  dataWidgetMap.set("default.basic.device.color", Color1Widget);
  dataWidgetMap.set("default.basic.device.colorRgb", ColorRgb1Widget);
  dataWidgetMap.set("default.basic.device.colortemperature", ColorTemp1Widget);
  dataWidgetMap.set("default.basic.device.onoff", OnOff1Widget);
  dataWidgetMap.set("default.basic.device.motion", Motion1Widget);
  dataWidgetMap.set("default.basic.device.presence", Present1Widget);
  dataWidgetMap.set("default.numberUnit", NumberUnit1Widget);
  dataWidgetMap.set("default.duration", NumberUnit1Widget);
  dataWidgetMap.set("default.temperature", NumberUnit1Widget);
  dataWidgetMap.set("default.temperaturediff", NumberUnit1Widget);

  // tag
  dataWidgetMap.set("default.brightness:number", BrightnessNumber1Widget);
  dataWidgetMap.set("default.colorTemperature:number", ColorTempNumber1Widget);
};

// Function to register custom data widgets
export const registerDataWidget = (skinName, WidgetComponent) => {
  dataWidgetMap.set(skinName, WidgetComponent);
};

// Function to get widget component
export const getDataWidgetComponent = (skin) => {
  return dataWidgetMap.get(skin);
};
