import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Card,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  IconButton,
  Divider,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  FormControlLabel,
  Switch,
  Radio,
  RadioGroup,
  Autocomplete,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorIcon from "@mui/icons-material/Error";
import { InfoOutlined } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import dayjs from "dayjs";
import { customFormat } from "../common/custom-format";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { getHistory } from "./automation-history-service";
import { getHistoryList } from "./automation-history-service";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import useApiCall from "../common/api-call";
import { useLocation } from "react-router-dom";
import theme from "../theme";
import LoadingSpinner from "../common/loading";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useToast } from "../common/toast";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { useHistory } from "react-router-dom";

import { listAutomations } from "./automation-service";
import { loadAll } from "js-yaml";

const AutomationHistoryList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [data, setData] = useState([]);
  const [groupedHistories, setGroupedHistories] = useState({});
  const navigate = useHistory();

  const [expandedHistories, setExpandedHistories] = useState([]);
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [loading, setLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const observerRef = useRef(null);
  const hasMore = useRef(true);
  const nextToken = useRef("");
  const minSize = useRef(0);

  const filterDate = useRef("");
  const lastFilterTimestampRef = useRef(null);
  const currentRequestRef = useRef(null);
  const { apiCall } = useApiCall();
  const { successToast, warningToast, errorToast } = useToast();

  const selectedLifeCycle = useRef("All");
  const selectedAutomation = useRef(null);

  // const [showErrorOnly, setShowErrorOnly] = useState(false);
  const showErrorOnly = useRef(false);
  const [switchState, setSwitchState] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const [searchInput, setSearchInput] = useState(urlParams.get("filter") || "");
  const [automationOptions, setAutomationOptions] = useState([]);
  const [loadAll, setLoadAll] = useState(true);

  useEffect(() => {
    handleListAutomations();
  }, [id]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting && !loading && hasMore.current) {
        fetchData(false);
      }
    }, options);

    observerRef.current = observer;

    const observerTarget = document.querySelector(".observer-target");
    if (observerTarget) {
      observer.observe(observerTarget);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!data || data.length === 0) return;
    const grouped = data.reduce((acc, History) => {
      const day = dayjs(History.timestamp).format("YYYY-MM-DD");
      if (!acc[day]) acc[day] = [];
      acc[day].push(History);
      return acc;
    }, {});
    setGroupedHistories(grouped);
    console.log(grouped);
  }, [data]);

  const handleFilterDateChange = () => {
    if (!filterDate.current) {
      console.log("Invalid filterDate or filterType, skipping fetch.");
      return;
    }
    setGroupedHistories({});
    setData([]);
    nextToken.current = "";
    fetchData(true);
  };

  const handleListAutomations = async () => {
    setLoadAll(true);
    try {
      const resp = await listAutomations(
        apiCall,
        userInfo.activeScope.id,
        "",
        ""
      );

      if (resp && Array.isArray(resp.automations)) {
        const automations = [
          { id: "", name: "All" },
          ...resp["automations"].map(({ id, name }) => ({
            id,
            name,
          })),
        ];

        setAutomationOptions(automations);

        if (id) {
          const defaultAutomation = automations.find((option) => {
            return option.id === id;
          });

          if (defaultAutomation) {
            selectedAutomation.current = defaultAutomation;
          }
        }

        setLoadAll(false);
      } else {
        setAutomationOptions([]);
      }
    } catch (error) {
      console.error("Failed to fetch automations:", error);
      setAutomationOptions([]);
    }
    setLoadAll(false);
    fetchData(true);
  };

  const fetchData = async (isReset = false) => {
    if (loading || (!hasMore.current && !isReset)) return;

    const filterTimestamp = filterDate.current
      ? dayjs(filterDate.current).endOf("day").valueOf()
      : null;

    if (
      filterTimestamp &&
      filterTimestamp === lastFilterTimestampRef.current &&
      isReset
    ) {
      console.log("Filter timestamp has not changed, skipping fetch.");
      return;
    }

    if (isReset) {
      minSize.current = 0;
      setGroupedHistories({});
      setData([]);
      nextToken.current = "";
      setHistoryLoading(true);
      hasMore.current = true;
    } else {
      setLoading(true);
    }

    const requestId = Symbol();
    currentRequestRef.current = requestId;

    try {
      const token = isReset ? "" : nextToken.current;

      let result = selectedAutomation.current?.id
        ? await getHistory(
            apiCall,
            userInfo.activeScope.id,
            selectedAutomation.current.id,
            20,
            token,
            filterTimestamp === lastFilterTimestampRef.current
              ? ""
              : filterTimestamp
          )
        : await getHistoryList(
            apiCall,
            userInfo.activeScope.id,
            20,
            token,
            filterTimestamp === lastFilterTimestampRef.current
              ? ""
              : filterTimestamp
          );

      if (currentRequestRef.current !== requestId) {
        console.log("return");
        return;
      }

      lastFilterTimestampRef.current = filterTimestamp;

      if (lastFilterTimestampRef.current !== filterTimestamp) {
        console.log("Filter timestamp has been updated, ignoring result.");
        return;
      }

      let histories = [];

      if (result.error) {
        hasMore.current = false;
        errorToast("Fetch Data Error");
        return;
      }

      if (result.automationHistories) {
        histories = result.automationHistories
          .map((history) => {
            history.name = result.name;
            history.lifeCycle = result.lifeCycle || "Standard";
            return history;
          })
          .filter((history) => {
            const matchesAutomation =
              !selectedAutomation.current?.name ||
              history.name.toLowerCase().trim() ===
                selectedAutomation.current.name.toLowerCase().trim();

            const matchesLifeCycle =
              !selectedLifeCycle.current?.toLowerCase() ||
              selectedLifeCycle.current.toLowerCase() === "all" ||
              history.lifeCycle?.toUpperCase().trim() ===
                selectedLifeCycle.current.toUpperCase().trim();

            const matchesErrorOnly =
              !showErrorOnly.current || history.error === showErrorOnly.current;

            return matchesAutomation && matchesLifeCycle && matchesErrorOnly;
          });
      } else {
        histories = result.automationHistoryEntryList?.flatMap((list) => {
          return list.automationHistories
            .map((history) => {
              history.name = list.name;
              history.lifeCycle = list.lifeCycle;
              return history;
            })
            .filter((history) => {
              const matchesAutomation =
                !selectedAutomation.current?.name ||
                history.name.toLowerCase().trim() ===
                  selectedAutomation.current.name.toLowerCase().trim();

              const matchesLifeCycle =
                !selectedLifeCycle.current.toLowerCase() ||
                selectedLifeCycle.current.toLowerCase() === "all" ||
                history.lifeCycle.toUpperCase().trim() ===
                  selectedLifeCycle.current.toUpperCase().trim();

              const matchesErrorOnly =
                !showErrorOnly.current ||
                history.error === showErrorOnly.current;

              return matchesAutomation && matchesLifeCycle && matchesErrorOnly;
            });
        });
      }

      nextToken.current = result.page.nextToken;
      hasMore.current = result.page.hasMore;

      const sortedHistories = (histories || []).sort(
        (a, b) => b.timestamp - a.timestamp
      );

      setData((prev) =>
        isReset ? sortedHistories : [...prev, ...sortedHistories]
      );

      if (result.page.hasMore && minSize.current < 10) {
        minSize.current += histories.length;
        if (minSize.current < 10 && result.page.hasMore) {
          await fetchData(false);
        }
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      if (currentRequestRef.current === requestId) {
        setLoading(false);
        setHistoryLoading(false);
      }
    }
  };

  const handleToggleHistory = (index) => {
    setExpandedHistories((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const handleAutomationChange = (newValue) => {
    selectedAutomation.current = newValue;
    fetchData(true);
  };

  const handleSearchInputChange = (newInputValue) => {
    setSearchInput(newInputValue);
  };

  return (
    <>
      {loadAll ? (
        <></>
      ) : (
        <Box sx={{ padding: 2 }}>
          <Card
            sx={{
              mb: 1,
              backgroundColor: "background.paper",
            }}
            elevation={0}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                p: 2,
                gap: 1.5,
              }}
            >
              <Box
                sx={{
                  minWidth: "200px",
                  maxWidth: "400px",
                  height: "40px",
                }}
              >
                <Autocomplete
                  options={automationOptions}
                  getOptionLabel={(option) =>
                    option && option.name ? option.name : ""
                  }
                  value={selectedAutomation.current}
                  onChange={(event, newValue) => {
                    handleAutomationChange(newValue);
                    fetchData(true);
                  }}
                  inputValue={searchInput}
                  onInputChange={(event, newInputValue) => {
                    handleSearchInputChange(newInputValue);
                    fetchData(true);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Automation "
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          backgroundColor: "background.input",
                          borderRadius: "8px",
                        },
                      }}
                      sx={{
                        width: "100%",
                        height: "100%",
                        "& .MuiOutlinedInput-root": {
                          height: "100%",
                        },
                      }}
                    />
                  )}
                />
              </Box>

              <Box
                sx={{
                  borderRadius: "8px",
                  flex: "1",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={
                      filterDate.current ? dayjs(filterDate.current) : null
                    }
                    onChange={(newValue) => {
                      filterDate.current = newValue
                        ? newValue.format("YYYY-MM-DD")
                        : "";
                      handleFilterDateChange();
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: "outlined",
                        InputLabelProps: {
                          shrink: true,
                        },
                        sx: {
                          "& .MuiOutlinedInput-root": {
                            width: "100%",
                            maxWidth: "400px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "background.white",
                          },
                          "& .MuiInputBase-input": {
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                          },
                        },
                      },
                    }}
                    sx={{ width: "100%", maxWidth: "400px" }}
                  />
                </LocalizationProvider>
              </Box>

              <Box>
                <FormControl>
                  <RadioGroup
                    row
                    value={selectedLifeCycle.current}
                    onChange={(e) => {
                      selectedLifeCycle.current = e.target.value;
                      fetchData(true);
                    }}
                    sx={{
                      "& .MuiFormControlLabel-root": {
                        marginRight: 1,
                        marginLeft: 0,
                      },
                      "& .MuiRadio-root": {
                        padding: "4px",
                      },
                    }}
                  >
                    <FormControlLabel
                      value="All"
                      control={<Radio size="small" />}
                      label="All"
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.875rem",
                        },
                      }}
                    />
                    <FormControlLabel
                      value="Standard"
                      control={<Radio size="small" />}
                      label="Standard"
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.875rem",
                        },
                      }}
                    />
                    <FormControlLabel
                      value="Temp"
                      control={<Radio size="small" />}
                      label="Temporary"
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.875rem",
                        },
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ ml: 0.5 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showErrorOnly.current}
                      onChange={(e) => {
                        // setShowErrorOnly(e.target.checked);
                        showErrorOnly.current = !showErrorOnly.current;
                        setSwitchState(showErrorOnly.current);
                        fetchData(true);
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      <ErrorIcon
                        sx={{
                          color: showErrorOnly.current
                            ? "error.main"
                            : "text.secondary",
                          fontSize: 20,
                        }}
                      />
                      <Typography
                        variant="body2"
                        color={
                          showErrorOnly.current
                            ? "error.main"
                            : "text.secondary"
                        }
                      >
                        Error Only
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </Box>
          </Card>

          <Box>
            <Button
              startIcon={<RefreshIcon sx={{ fontSize: 20, mr: 0.5 }} />}
              onClick={() => {
                fetchData(true);
              }}
              disabled={loading}
              sx={{
                textTransform: "none",
                mb: 2,
                px: 1,
                py: 0.5,
                minWidth: "auto",
              }}
            ></Button>
          </Box>

          {historyLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                py: 4,
              }}
            >
              <CircularProgress size={24} />
            </Box>
          )}
          {!historyLoading && (
            <>
              {Object.keys(groupedHistories).length > 0 ? (
                Object.keys(groupedHistories).map((day, idx) => (
                  <Box key={idx} sx={{ mb: 4 }}>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      {day}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />

                    {groupedHistories[day].map((history, index) => (
                      <Accordion
                        key={index}
                        expanded={expandedHistories.includes(`${day}-${index}`)}
                        onChange={() => handleToggleHistory(`${day}-${index}`)}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItem: "center",

                                width: "100%",
                              }}
                            >
                              <Box
                                sx={{
                                  width: 8,
                                  height: 8,
                                  borderRadius: "50%",
                                  backgroundColor:
                                    history.lifeCycle?.toLowerCase() ===
                                    "standard"
                                      ? theme.palette.background.l2
                                      : theme.palette.background.l6,
                                  mr: 1,
                                  flexShrink: 0,
                                  marginTop: "8px",
                                }}
                              />
                              <Typography variant="body1" fontWeight="bold">
                                {history.name}
                              </Typography>

                              {history.logs.some(
                                (log) =>
                                  log.level.trim().toLowerCase() === "error" ||
                                  (log.level.trim().toLowerCase() === "flag" &&
                                    log.message.toLowerCase() === "error")
                              ) && (
                                <ErrorIcon
                                  sx={{ color: "red", ml: 2 }}
                                  fontSize="small"
                                  titleAccess="Error"
                                />
                              )}
                              <IconButton
                                size="small"
                                sx={{ ml: 1, mt: -0.3 }}
                                onClick={(e) => {
                                  navigate.push(
                                    `/automation/overview?id=${history.automationId}`
                                  );
                                  e.preventDefault();
                                }}
                              >
                                <InfoOutlined fontSize="small" />
                              </IconButton>
                            </Box>

                            <Typography variant="caption" color="textSecondary">
                              {customFormat(
                                dayjs(history.timestamp),
                                currentTime
                              )}
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          {history.logs
                            .filter(
                              (log) => log.level.trim().toLowerCase() !== "flag"
                            )
                            .map((log, index) => (
                              <React.Fragment key={log.id || index}>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {customFormat(
                                    dayjs(log.timestamp),
                                    currentTime
                                  )}
                                </Typography>
                                <Divider />
                                <Typography
                                  key={index}
                                  sx={{
                                    mb: 1,
                                    mt: -1,
                                    wordWrap: "break-word",
                                    whiteSpace: "pre-wrap",
                                  }}
                                  noWrap={false}
                                  component="div"
                                >
                                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                                    {log.message}
                                  </ReactMarkdown>
                                </Typography>
                              </React.Fragment>
                            ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                ))
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No histories available.
                </Typography>
              )}
            </>
          )}
          <Box
            className="observer-target"
            ref={(el) => {
              if (el && observerRef.current) {
                observerRef.current.observe(el);
              }
            }}
            sx={{
              height: "20px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading && !historyLoading && <LoadingSpinner />}
          </Box>
          {!loading &&
            !hasMore.current &&
            Object.keys(groupedHistories).length > 0 && (
              <Typography
                variant="body2"
                sx={{ textAlign: "center", p: 2, color: "text.secondary" }}
              >
                No more activities
              </Typography>
            )}
        </Box>
      )}
    </>
  );
};

export default AutomationHistoryList;
