import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Command, Lightbulb, Plus } from "lucide-react";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";

const AddOptionDialog = ({ open, onClose, onWidgetSelect, onDeviceSelect }) => {
  const handleWidgetSelect = () => {
    onWidgetSelect();
    onClose();
  };

  const handleDeviceSelect = () => {
    onDeviceSelect();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={6}>
          <Card
            elevation={0}
            sx={{
              cursor: "pointer",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #e0e0e0",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                bgcolor: "action.hover",
                borderColor: "#bdbdbd",
              },
            }}
            onClick={handleWidgetSelect}
          >
            <Command
              size={48}
              style={{
                color: "primary.main",
                marginBottom: "16px",
              }}
            />
            <Typography variant="h6">New Widget Card</Typography>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card
            elevation={0}
            sx={{
              cursor: "pointer",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #e0e0e0",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                bgcolor: "action.hover",
                borderColor: "#bdbdbd",
              },
            }}
            onClick={handleDeviceSelect}
          >
            <Lightbulb
              size={48}
              style={{
                color: "primary.main",
                marginBottom: "16px",
              }}
            />
            <Typography variant="h6">New Device Card</Typography>
          </Card>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default AddOptionDialog;
