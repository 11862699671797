import React, { useEffect, useState } from "react";
import {
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Avatar,
  Grid,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Container,
  MenuItem,
  Select,
  DialogTitle,
  useMediaQuery,
  Card,
  Tooltip,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  originalPathState,
  userInfoState,
  developerEmailState,
  platformState,
} from "../global-state";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import { RemoveCircle as RemoveCircleIcon } from "@mui/icons-material";
import useApiCall from "../common/api-call";
import {
  saveScope,
  deleteScope,
  deleteScopeMembership,
  inviteMember,
  updateMember,
  getScopeInfo,
  exitScope,
  switchScope,
  saveScopeExecutionEnv,
  checkInvitation,
  getScopePreference,
  saveScopePereference,
} from "../menu/scope-service";
import { useTheme } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import { getUserInfo } from "../setting/setting-service";
import { useHistory, useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useToast } from "../common/toast";

const Scope = () => {
  const [selectedScope, setSelectedScope] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isScopeEditing, setIsScopeEditing] = useState(false);
  const [newScopeName, setNewScopeName] = useState("");
  const [newMembers, setNewMembers] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [selectedEnv, setSelectedEnv] = useState(null);
  const [editedName, setEditedName] = useState("");
  const [scopes, setScopes] = useState([]);
  const [allScopes, setAllScopes] = useState([]);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isOwner, setIsOwner] = useState(false);
  const [developerEmail, setDeveloperEmail] =
    useRecoilState(developerEmailState);
  const { successToast, warningToast, errorToast } = useToast();
  const [error, setError] = useState("");
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const [currentOperation, setCurrentOperation] = useState(null); // type: 'EDIT_MEMBERS', 'EDIT_SCOPE', 'ADD_SCOPE', null
  const [isRunning, setIsRunning] = useState(null); // type: 'ADD_MEMBER', 'DELE_SCOPE', 'ADD_SCOPE', null
  const [scopePreference, setScopePreference] = useState(null);
  const [invitationLoading, setInvitationLoading] = useState(false);
  const [membersLoading, setMembersLoading] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [loadingInvitationId, setLoadingInvitationId] = useState(null);
  const [loadingInvitationIds, setLoadingInvitationIds] = useState({});
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [platform] = useRecoilState(platformState);
  const [emailErrors, setEmailErrors] = useState(
    Array(newMembers.length).fill("")
  );

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    setEditedName(selectedScope.name);
  }, [scopes, selectedScope]);

  const fetchAll = async () => {
    const userInfo = await fetchUserInfo();
    if (userInfo) {
      await fetchInvitations();
      await fetchScopePreference(userInfo.activeScope.id);
    }
  };

  const fetchScopePreference = async (scopeId) => {
    const resp = await getScopePreference(apiCall, scopeId);
    if (resp) {
      setScopePreference(resp);
    }
  };

  const fetchUserInfo = async () => {
    setLoading(true);
    const resp = await getUserInfo(apiCall, userInfo);
    if (resp) {
      setUserInfo(resp);
      console.log(resp);
      if (resp.scopes === null) {
        history.push("/init-scope");
        return;
      }
      setAllScopes(resp.scopes);
      const filterScopes = resp.scopes.filter((s) => !s.pending);
      setScopes(filterScopes);

      if (resp.activeScope === null) {
        history.push("/init-scope");
        return;
      }
      setSelectedScope(resp.activeScope);
      await handleScopeInfo(resp.activeScope.id);
      if (platform.source === "app") {
        if (window.Android) {
          window.Android.updateUserInfo(JSON.stringify(resp));
        }
      }
    } else {
      errorToast("Error!");
    }
    setLoading(false);
    return resp;
  };

  const fetchScopeMembers = async (scopeId) => {
    try {
      const resp = await getScopeInfo(apiCall, scopeId);
      if (resp && resp.members) {
        return resp.members;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching scope info:", error);
    }
  };

  const handleScopeInfo = async (scopeId) => {
    try {
      setMembersLoading(true);
      const resp = await getScopeInfo(apiCall, scopeId);
      console.log(resp);
      if (resp && resp.members) {
        setFamilyMembers(resp.members);
        const o = resp.members.some(
          (member) => member.email === userInfo.name && member.role === "OWNER"
        );
        if (o) {
          setIsOwner(true);
        } else {
          setIsOwner(false);
        }
        let env = resp.executionEnv;
        if (env === "LOCAL-" + developerEmail) {
          env = "LOCAL";
        }
        setSelectedEnv(env);
      }
    } catch (error) {
      console.error("Error fetching scope info:", error);
    } finally {
      setMembersLoading(false);
    }
  };

  const fetchInvitations = async () => {
    setInvitationLoading(true);
    try {
      const resp = await checkInvitation(apiCall);
      if (Array.isArray(resp) && resp.length > 0) {
        setInvitations(resp);
      } else {
        setInvitations([]);
      }
    } catch (error) {
      console.error("Error fetching invitations:", error);
      errorToast("Failed to load invitations");
    } finally {
      setInvitationLoading(false);
    }
  };

  const handleRejectInvitation = async (invitationScopeId) => {
    setLoadingInvitationId(invitationScopeId);
    setIsRunning("REJECT");
    setLoadingInvitationIds((prev) => ({ ...prev, [invitationScopeId]: true }));
    try {
      let scopeId = null;
      invitations
        .filter((inv) => inv.scopeId === invitationScopeId)
        .map((inv) => {
          scopeId = inv.scopeId;
        });

      if (!scopeId) {
        errorToast("The space does not exist.Please refresh");
        return;
      }
      const resp = await updateMember(apiCall, scopeId, false);
      if (resp) {
        successToast("Successfully reject the invitation");
        setInvitations(
          invitations.filter((inv) => inv.scopeId !== invitationScopeId)
        );
        await fetchInvitations();
        await fetchUserInfo();
      } else {
        errorToast("Failed to reject invitation!");
      }
    } catch (error) {
      console.error("Error rejecting invitation:", error);
      errorToast("Failed to reject invitation");
    } finally {
      setIsRunning(null);
      setLoadingInvitationId(null);
      setLoadingInvitationIds((prev) => ({
        ...prev,
        [invitationScopeId]: false,
      }));
    }
  };

  const handleAcceptInvitation = async (invitationScopeId) => {
    console.log(invitationScopeId);
    setLoadingInvitationId(invitationScopeId);
    setIsRunning("ACCEPT");
    setLoadingInvitationIds((prev) => ({ ...prev, [invitationScopeId]: true }));

    try {
      let scopeId = null;
      invitations
        .filter((inv) => inv.scopeId === invitationScopeId)
        .map((inv) => {
          scopeId = inv.scopeId;
        });
      if (!scopeId) {
        errorToast("The space does not exist.Please refresh");
        return;
      }
      const resp = await updateMember(apiCall, scopeId, true, true);
      if (resp) {
        successToast("Successfully joined the space");
        setInvitations(
          invitations.filter((inv) => inv.scopeId !== invitationScopeId)
        );
        await fetchInvitations();
        await fetchUserInfo();
      } else {
        errorToast("Failed to accept invitation!");
      }
    } catch (error) {
      console.error("Error accepting invitation:", error);
      errorToast("Failed to accept invitation");
    } finally {
      setIsRunning(null);
      setLoadingInvitationId(null);
      setLoadingInvitationIds((prev) => ({
        ...prev,
        [invitationScopeId]: false,
      }));
    }
  };

  const startOperation = (operation) => {
    if (currentOperation) {
      warningToast("Please complete current operation first");
      return false;
    }
    setCurrentOperation(operation);
    return true;
  };

  const endOperation = () => {
    setCurrentOperation(null);
  };

  const handleEditScope = () => {
    if (startOperation("EDIT_SCOPE")) {
      setIsScopeEditing(true);
      setEditedName(selectedScope.name);
    }
  };

  const handleExitScope = async () => {
    const resp = await exitScope(
      apiCall,
      selectedScope.id,
      selectedScope.pending,
      userInfo.email
    );
    if (resp) {
      successToast("Exited");
    } else {
      errorToast("Error!");
    }
    fetchUserInfo();
  };

  const handleSaveActiveScope = async (oldId, scopeId) => {
    if (oldId === scopeId) {
      return;
    }
    const resp = await switchScope(apiCall, oldId, scopeId);

    if (resp) {
      await fetchAll();
    } else {
      errorToast("Error!");
    }
  };

  const handleSaveEditScope = async () => {
    await saveScope(apiCall, selectedScope.id, editedName);
    fetchUserInfo();
    setIsScopeEditing(false);
    endOperation();
  };

  const handleCancelEditScope = () => {
    setIsScopeEditing(false);
    setEditedName(selectedScope.name);
    endOperation();
  };

  const handleDeleteScope = async () => {
    setIsRunning("DELE_SCOPE");
    const resp = await deleteScope(apiCall, selectedScope.id);
    if (resp) {
      successToast("Deleted");
    } else {
      errorToast("Error!");
    }
    fetchUserInfo();
    setIsRunning(null);
    endOperation();
    setConfirmDeleteOpen(false);
  };

  const isDuplicateName = (name) => {
    return scopes.some(
      (scope) => scope.name.toLowerCase() === name.toLowerCase().trim()
    );
  };

  const checkDuplicateName = (name) => {
    const trimmedName = name.toLowerCase().trim();
    if (!trimmedName) {
      setError("name cannot be empty");
      return true;
    } else if (isDuplicateName(trimmedName)) {
      setError("name already exists");
      return true;
    } else {
      setError("");
      return false;
    }
  };

  const handleAddScope = async () => {
    setIsRunning("ADD_SCOPE");
    setError("");
    const trimmedName = newScopeName.toLowerCase().trim();
    if (!trimmedName) {
      setError("name cannot be empty");
      setIsRunning(false);
      return;
    } else if (checkDuplicateName(trimmedName)) {
      setIsRunning(false);
      return;
    }

    const scopeId = await saveScope(apiCall, null, newScopeName);
    handleSaveActiveScope(selectedScope.id, scopeId);
    setOpenDialog(false);
    setNewScopeName("");
    setError("");
    endOperation();
    setIsRunning(false);
  };

  const handleScopeChange = (event) => {
    const scope = scopes.find((s) => s.name === event.target.value);
    const oldId = selectedScope.id;
    setSelectedScope(scope);
    handleSaveActiveScope(oldId, scope.id);
    localStorage.removeItem("filterParams-taskflow");
    localStorage.removeItem("filterParams-recurring");
    localStorage.removeItem("notifications");
  };

  const handleNewScope = () => {
    if (startOperation("ADD_SCOPE")) {
      setOpenDialog(true);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setNewScopeName("");
    setError("");
    endOperation();
  };

  const handleEditToggle = () => {
    if (isEditing) {
      setIsEditing(false);
      endOperation();
    } else {
      if (startOperation("EDIT_MEMBERS")) {
        setIsEditing(true);
      }
    }
  };

  const handleAddMemberOpen = () => {
    setNewMembers([""]);
    setOpenAddDialog(true);
  };

  const handleAddMemberClose = () => {
    setNewMembers([]);
    setOpenAddDialog(false);
  };

  const handleMemberChange = (index, value) => {
    const updatedMembers = [...newMembers];
    updatedMembers[index] = value;
    setNewMembers(updatedMembers);

    const errors = [...emailErrors];
    errors[index] = validateEmail(value, updatedMembers);
    setEmailErrors(errors);
  };

  const handleRemoveMember = (index) => {
    const updatedMembers = [newMembers.filter((_, i) => i !== index)];
    setNewMembers(updatedMembers);

    setEmailErrors(
      updatedMembers.map((member) => validateEmail(member, updatedMembers))
    );
  };

  const validateEmail = (email, members) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) return "Email cannot be empty";
    if (!emailPattern.test(email)) return "Invalid email format";
    if (members.filter((member) => member === email).length > 1)
      return "Duplicate email";
    if (familyMembers.some((member) => member.email === email))
      return "User already invited";
    return "";
  };

  const handleAddMembers = async () => {
    setIsRunning("ADD_MEMBER");
    console.log("New members to add:", newMembers);

    const errors = newMembers.map((member) =>
      validateEmail(member, newMembers)
    );
    if (errors.some((error) => error)) {
      setEmailErrors(errors);
      setIsRunning(null);
      return;
    }

    setNewMembers([...newMembers]);

    const resp = await inviteMember(apiCall, selectedScope.id, newMembers);
    console.log(resp);

    if (resp.failed && resp.failed.length > 0) {
      errorToast(`Failed to invite: ${resp.failed.join(", ")}`);
    } else if (resp.invited) {
      successToast(`Invited ${resp.invited.length} users`);
    } else {
      errorToast("Error!");
    }
    setOpenAddDialog(false);
    setIsRunning(null);
    handleScopeInfo(selectedScope.id);
  };

  const handleDeleteMember = async (email) => {
    const resp = await deleteScopeMembership(apiCall, selectedScope.id, email);
    if (resp) {
      successToast("Deleted");
    } else {
      errorToast("Error!");
    }
    handleScopeInfo(selectedScope.id);
  };

  const handleExecutionEnvChange = async (newEnv) => {
    if (!developerEmail) {
      errorToast("Error!");
    }
    let env = newEnv;
    if (env === "LOCAL") {
      env = env + "-" + developerEmail;
    }
    const resp = await saveScopeExecutionEnv(apiCall, selectedScope.id, env);
    if (resp) {
      successToast("Updated!");
    } else {
      errorToast("Error!");
    }
    handleScopeInfo(selectedScope.id);
  };

  const handleTemperatureUnitChange = async (unit) => {
    const newSp = { ...scopePreference, temperatureUnit: unit };
    const resp = await saveScopePereference(apiCall, newSp);
    if (!resp) {
      errorToast("Error!");
    } else {
      successToast("Saved");
    }
    await fetchScopePreference(userInfo.activeScope.id);
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Box sx={{ flexGrow: 1, height: "100%", pb: "80px" }}>
          <Box
            display="flex"
            justifyContent="start"
            alignItems="start"
            flexDirection="column"
            minHeight="100%"
            p={2}
            width="100%"
          >
            <Box
              position="static"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  maxWidth: "70%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "100%",
                  }}
                >
                  {selectedScope && (
                    <Select
                      value={selectedScope}
                      onChange={handleScopeChange}
                      variant="standard"
                      disableUnderline
                      size="small"
                      sx={{
                        color: "inherit",
                        fontSize: "1.25rem",
                        textAlign: "center",
                        padding: 0,
                        minWidth: 0,
                        ".MuiSelect-select": {
                          padding: 0,
                        },
                      }}
                      renderValue={(selected) => `${selected.name}`}
                    >
                      {scopes.map((scope) => (
                        <MenuItem
                          key={scope.id}
                          value={scope.name}
                          sx={{
                            fontSize: "1.25rem",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "90%",
                          }}
                        >
                          <Typography
                            noWrap
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "100%",
                            }}
                          >
                            {scope.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Box>
              </Box>

              <Box>
                <IconButton color="inherit" onClick={handleNewScope}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>

            <Dialog open={openDialog} onClose={handleDialogClose}>
              <DialogContent>
                <DialogTitle sx={{ p: 0, mb: 4 }}>New Space</DialogTitle>
                <TextField
                  label="Space Name"
                  fullWidth
                  variant="outlined"
                  value={newScopeName}
                  onChange={(e) => {
                    setNewScopeName(e.target.value);
                    checkDuplicateName(e.target.value);
                  }}
                  error={!!error}
                  helperText={error}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>cancel</Button>
                <Button
                  onClick={handleAddScope}
                  disabled={isRunning === "ADD_SCOPE"}
                >
                  Add{" "}
                  {isRunning === "ADD_SCOPE" && (
                    <CircularProgress size={16} sx={{ ml: 1 }} />
                  )}
                </Button>
              </DialogActions>
            </Dialog>

            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Card
                  sx={{
                    p: 2,
                    width: "100%",
                    backgroundColor: "background.card",
                    fontFamily: "Roboto, sans-serif",
                    borderRadius: "12px",
                    border: "1px solid #EAEEF0",
                    boxShadow: "none",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>Members</Typography>

                    <Box>
                      {isOwner && (
                        <IconButton
                          sx={{
                            color: "text.primary",
                            opacity:
                              currentOperation &&
                              currentOperation !== "EDIT_MEMBERS"
                                ? 0.5
                                : 1,
                            pointerEvents:
                              currentOperation &&
                              currentOperation !== "EDIT_MEMBERS"
                                ? "none"
                                : "auto",
                          }}
                          onClick={handleEditToggle}
                          disabled={
                            currentOperation &&
                            currentOperation !== "EDIT_MEMBERS"
                          }
                        >
                          {isEditing ? <EditOffIcon /> : <EditIcon />}
                        </IconButton>
                      )}
                      <Button
                        startIcon={<RefreshIcon />}
                        onClick={() => {
                          handleScopeInfo(selectedScope.id);
                        }}
                        disabled={membersLoading}
                        sx={{ textTransform: "none" }}
                      ></Button>
                    </Box>
                  </Box>
                  <Divider />
                  {membersLoading && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        py: 4,
                      }}
                    >
                      <CircularProgress size={24} />
                    </Box>
                  )}
                  {!membersLoading && (
                    <>
                      <List>
                        {familyMembers.map((member) => (
                          <ListItem
                            key={member.id}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar>{member.email.charAt(0)}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  noWrap
                                  sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "250px",
                                  }}
                                >
                                  {member.email}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  component="span"
                                  noWrap
                                  sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "250px",
                                  }}
                                >
                                  {member.role.toLowerCase()}
                                  {!member.pending ? null : (
                                    <Typography
                                      color="text.secondary"
                                      component="span"
                                    >
                                      &nbsp;(Pending...)
                                    </Typography>
                                  )}
                                </Typography>
                              }
                            />

                            {isOwner && isEditing && (
                              <>
                                {member.role !== "OWNER" && (
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => {
                                      handleDeleteMember(member.email);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                              </>
                            )}
                          </ListItem>
                        ))}
                      </List>
                      {isEditing && isOwner && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 2,
                          }}
                        >
                          <IconButton
                            onClick={handleAddMemberOpen}
                            color="primary"
                            sx={{
                              borderRadius: "50%",
                              padding: 2,
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                          <Typography
                            onClick={handleAddMemberOpen}
                            color="primary"
                          >
                            invite members
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                </Card>
              </Grid>

              <Grid item xs={12} md={12}>
                <Card
                  sx={{
                    p: 2,
                    width: "100%",
                    backgroundColor: "background.card",
                    fontFamily: "Roboto, sans-serif",
                    borderRadius: "12px",
                    border: "1px solid #EAEEF0",
                    boxShadow: "none",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>Invitions</Typography>
                    <Box>
                      <Button
                        startIcon={<RefreshIcon />}
                        onClick={fetchInvitations}
                        disabled={invitationLoading}
                        sx={{ textTransform: "none" }}
                      ></Button>
                    </Box>
                  </Box>
                  <Divider />

                  {invitationLoading && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        py: 4,
                      }}
                    >
                      <CircularProgress size={24} />
                    </Box>
                  )}
                  {!invitationLoading &&
                    invitations.length > 0 &&
                    invitations.map((invitation, index) => (
                      <Box key={`${invitation.scopeId}-${index}`} sx={{ p: 2 }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Box>
                            <Typography sx={{ mr: 1 }}>
                              {invitation.inviterName} invite you to join&nbsp;
                              {invitation.scopeName}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() =>
                                handleAcceptInvitation(invitation.scopeId)
                              }
                              disabled={
                                (loadingInvitationIds[invitation.scopeId] &&
                                  isRunning === "ACCEPT") ||
                                invitationLoading
                              }
                            >
                              Accept ✔{" "}
                              {loadingInvitationIds[invitation.scopeId] &&
                                isRunning === "ACCEPT" && (
                                  <CircularProgress size={16} sx={{ ml: 1 }} />
                                )}
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              sx={{ ml: 1 }}
                              onClick={() =>
                                handleRejectInvitation(invitation.scopeId)
                              }
                              disabled={
                                (loadingInvitationIds[invitation.scopeId] &&
                                  isRunning === "REJECT") ||
                                invitationLoading
                              }
                            >
                              Reject ✘{" "}
                              {loadingInvitationIds[invitation.scopeId] &&
                                isRunning === "REJECT" && (
                                  <CircularProgress size={16} sx={{ ml: 1 }} />
                                )}
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  {!invitationLoading && invitations.length === 0 && (
                    <Box
                      sx={{
                        textAlign: "center",
                        m: 2,
                        color: theme.palette.text.secondary,
                      }}
                    >
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        No invitations
                      </Typography>
                    </Box>
                  )}
                </Card>
              </Grid>

              {developerEmail && (
                <Grid item xs={12} md={12}>
                  <Card
                    sx={{
                      p: 2,
                      width: "100%",
                      backgroundColor: "background.card",
                      fontFamily: "Roboto, sans-serif",
                      borderRadius: "12px",
                      border: "1px solid #EAEEF0",
                      boxShadow: "none",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection={isSmallScreen ? "column" : "row"}
                      alignItems={isSmallScreen ? "" : "center"}
                      justifyContent={
                        isSmallScreen ? "center" : "space-between"
                      }
                    >
                      <Box display="flex" alignItems="center">
                        <Typography variant="body1">
                          Execution Environment
                        </Typography>
                        <Tooltip title="Select execution environment">
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <ToggleButtonGroup
                        value={selectedEnv}
                        exclusive
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            console.log(newValue);
                            handleExecutionEnvChange(newValue);
                          }
                        }}
                        size="small"
                      >
                        <ToggleButton value="PROD">Prod</ToggleButton>
                        <ToggleButton value="STAGING">Staging</ToggleButton>
                        <ToggleButton value="LOCAL">Local</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Card>
                </Grid>
              )}

              <Grid item xs={12} md={12}>
                <Card
                  sx={{
                    p: 2,
                    width: "100%",
                    backgroundColor: "background.card",
                    fontFamily: "Roboto, sans-serif",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "12px",
                    border: "1px solid #EAEEF0",
                    boxShadow: "none",
                  }}
                >
                  <Box sx={{ maxWidth: "100%", mb: 1 }}>
                    {isScopeEditing ? (
                      <TextField
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                        size="small"
                        variant="outlined"
                        sx={{ width: "100%" }}
                      />
                    ) : (
                      <Typography
                        noWrap
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "100%",
                          marginRight: 1,
                        }}
                      >
                        {selectedScope.name}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    {isScopeEditing ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexGrow: 1,
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={handleSaveEditScope}
                          sx={{ mr: 1 }}
                        >
                          <Typography>Save</Typography>
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={handleCancelEditScope}
                        >
                          <Typography>Cancel</Typography>
                        </Button>
                      </Box>
                    ) : (
                      <>
                        {isOwner ? (
                          <>
                            <Button
                              variant="contained"
                              onClick={handleEditScope}
                              sx={{
                                mr: 1,
                                opacity: currentOperation ? 0.5 : 1,
                                pointerEvents: currentOperation
                                  ? "none"
                                  : "auto",
                              }}
                              disabled={!!currentOperation}
                            >
                              <Typography>Edit</Typography>
                            </Button>

                            <Button
                              variant="contained"
                              onClick={() => {
                                setConfirmDeleteOpen(true);
                              }}
                              sx={{
                                opacity: currentOperation ? 0.5 : 1,
                                pointerEvents: currentOperation
                                  ? "none"
                                  : "auto",
                              }}
                              disabled={
                                !!currentOperation || isRunning === "DELE_SCOPE"
                              }
                            >
                              <Typography>Delete</Typography>
                            </Button>
                          </>
                        ) : (
                          <Button
                            variant="contained"
                            onClick={handleExitScope}
                            sx={{
                              mr: 1,
                              opacity: currentOperation ? 0.5 : 1,
                              pointerEvents: currentOperation ? "none" : "auto",
                            }}
                            disabled={!!currentOperation}
                          >
                            <Typography>Exit</Typography>
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} md={12}>
                <Card
                  sx={{
                    p: 2,
                    width: "100%",
                    backgroundColor: "background.card",
                    fontFamily: "Roboto, sans-serif",
                    borderRadius: "12px",
                    border: "1px solid #EAEEF0",
                    boxShadow: "none",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body1">Temperature Unit</Typography>
                    <ToggleButtonGroup
                      value={
                        scopePreference ? scopePreference.temperatureUnit : null
                      }
                      exclusive
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          handleTemperatureUnitChange(newValue);
                        }
                      }}
                      size="small"
                    >
                      <ToggleButton value="F">°F</ToggleButton>
                      <ToggleButton value="C">°C</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Card>
              </Grid>

              <Dialog open={openAddDialog} onClose={handleAddMemberClose}>
                <DialogTitle sx={{ mb: 1 }}>Invite Members</DialogTitle>
                <DialogContent>
                  {newMembers.map((member, index) => (
                    <Box key={index} sx={{ mb: 3 }}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <Typography variant="subtitle1" sx={{ mr: 2 }}>
                          Member {index + 1}
                        </Typography>
                        <IconButton
                          onClick={() => handleRemoveMember(index)}
                          color="error"
                        >
                          <RemoveCircleIcon />
                        </IconButton>
                      </Box>
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        required
                        value={member}
                        onChange={(e) =>
                          handleMemberChange(index, e.target.value)
                        }
                        error={!!emailErrors[index]}
                        helperText={emailErrors[index]}
                        sx={{ mb: 2 }}
                      />
                    </Box>
                  ))}
                  <Button
                    onClick={() => setNewMembers([...newMembers, ""])}
                    fullWidth
                    variant="outlined"
                    sx={{ width: "100%" }}
                  >
                    Add More
                  </Button>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAddMemberClose}>Cancel</Button>
                  <Button
                    onClick={handleAddMembers}
                    color="primary"
                    disabled={
                      newMembers.some((member) => !member) ||
                      emailErrors.some((error) => error) ||
                      isRunning === "ADD_MEMBER"
                    }
                  >
                    Invite{" "}
                    {isRunning === "ADD_SCOPE" && (
                      <CircularProgress size={16} sx={{ ml: 1 }} />
                    )}
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={confirmDeleteOpen}
                onClose={() => setConfirmDeleteOpen(false)}
                fullWidth
                maxWidth="xs"
              >
                <DialogTitle>Warning</DialogTitle>
                <DialogContent>
                  <Box>Are you sure you want to delete this space?</Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setConfirmDeleteOpen(false)}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleDeleteScope}
                    color="error"
                    variant="contained"
                  >
                    Confirm
                    {isRunning === "DELE_SCOPE" && (
                      <CircularProgress size={16} sx={{ ml: 1 }} />
                    )}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Scope;
