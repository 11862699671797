import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

const DateInput = ({ valueSpec, onChange }) => {
  const [value, setValue] = useState(null);
  const isReadOnly = valueSpec.readOnly;

  useEffect(() => {
    setValue(dayjs(valueSpec.value));
    return () => {};
  }, [valueSpec]);

  const handleValueChange = (data) => {
    if (isReadOnly) return;
    const y = data.$y;
    const m = data.$M + 1;
    const d = data.$D;
    const date = `${y}-${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d}`;
    setValue(data);
    onChange(date);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        alignItems: "stretch",
        justifyContent: "center",
      }}
    >
      {valueSpec.name && (
        <Typography variant="h6" noWrap>
          {valueSpec.name}
        </Typography>
      )}
      <Box
        sx={{
          flex: "1 1 auto",
          display: "flex",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            value={value}
            onAccept={handleValueChange}
            readOnly={isReadOnly}
            disabled={isReadOnly}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
                sx: {
                  flex: 1,
                  "& .MuiInputBase-root": {
                    height: "100%",
                  },
                  "& .MuiInputBase-input": {
                    height: "100%",
                    padding: "0 8px",
                    display: "flex",
                    alignItems: "center",
                    cursor: isReadOnly ? "default" : "pointer",
                  },
                },
              },
            }}
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              "& .MuiTextField-root": {
                flex: 1,
                display: "flex",
              },
              "& .MuiPickersLayout-root": {
                minHeight: "auto",
              },
            }}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default DateInput;
